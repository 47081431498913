export const SET_BOOKMARK = "SET_BOOKMARK";
export const setBookmark = (payload) => ({
  type: SET_BOOKMARK,
  payload: payload,
});

export const SET_USER_BOOKMARKS = "SET_USER_BOOKMARKS";
export const setUserBookmarks = (payload) => ({
  type: SET_USER_BOOKMARKS,
  payload: payload,
});
