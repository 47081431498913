import numbro from "numbro";
import { format } from "date-fns";

const formatToPrettyDate = (date) => {
  return format(new Date(date), "dd-MMM-yyyy");
};

const formatNumberToCurrency = (number) => {
  return numbro(number).format({
    thousandSeparated: true,
    average: true,
    mantissa: 2,
  });
};

const formatNumberToPercentage = (number) => {
  if (number > 0.01) {
    return numbro(number).format({
      output: "percent",
      mantissa: 2,
    });
  }
  return numbro(number).format({
    output: "percent",
    mantissa: 4,
  });
};

const formatNumberToInteger = (number) => {
  return numbro(number).format({
    mantissa: 0,
  });
};

const toTitleCase = (str) => {
  return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
};

const getInitials = (str) => {
  return str
    .split(" ")
    .map((x) => x.charAt(0))
    .join("")
    .substr(0, 2)
    .toUpperCase();
};

const getCsvFileName = (moduleName) => {
  const dateFormat = format(new Date(), "yyyyMMddHHmmss");
  return `${moduleName}-${dateFormat}.csv`;
};

const getDownloadFileName = (moduleName, extension) => {
  const dateFormat = format(new Date(), "yyyyMMddHHmmss");
  return `${moduleName}-${dateFormat}.${extension}`;
};

const formatToUniversalTime = (date) => {
  var now = new Date(date);
  var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  return utc;
};

const formatToISODateString = (date) => {
  var dateString = date.getFullYear() + "-" + (date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)) + "-" + date.getDate()
  return dateString;
};

const FormatUtils = {
  FormatNumberToCurrency: formatNumberToCurrency,
  FormatNumberToPercentage: formatNumberToPercentage,
  FormatNumberToInteger: formatNumberToInteger,
  ToTitleCase: toTitleCase,
  FormatToPrettyDate: formatToPrettyDate,
  GetInitials: getInitials,
  GetCSVFileName: getCsvFileName,
  GetDownloadFileName: getDownloadFileName,
  FormatToUniversalTime: formatToUniversalTime,
  FormatToISODateString: formatToISODateString
};

export default FormatUtils;
