import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import UIUtils from "../../utils/uiUtils";

let styles = (theme) => ({
  root: {},
  red: {
    fontWeight: `bolder`,
    color: UIUtils.GetSelectedColor("red"),
  },
  amber: {
    fontWeight: `bolder`,
    color: UIUtils.GetSelectedColor("amber"),
  },
  green: {
    fontWeight: `bolder`,
    color: UIUtils.GetSelectedColor("green"),
  },
  logo: {
    fontWeight: `bolder`,
  },
});

class NavBar extends Component {
  classes = this.props.classes;

  state = {
    toggled: false,
  };

  toggleMenu = () => {
    const toggled = !this.state.toggled;
    this.setState({ toggled });
  };

  isAdmin = () => {
    return this.props.roles?.some((i) => i.OrganizationRoleId === 1) || this.props.user?.UserRoles?.some((i) => i.OrganizationRoleId === 1);
  };

  render() {
    const show = this.state.toggled ? "show" : "";

    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-white mb-4 shadow static-top">
        <div className="navbar-header">
          <Link className={`navbar-brand ${this.classes.logo}`} to="/">
            CRED<span className={this.classes.red}>E</span>
            <span className={this.classes.amber}>W</span>
            <span className={this.classes.green}>S</span>
          </Link>
        </div>

        <button className="navbar-toggler" type="button" onClick={this.toggleMenu}>
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={"collapse navbar-collapse " + show}>
          {this.props.user ? (
            <ul className="navbar-nav ml-auto">
              <li>
                <NavLink activeClassName="active" className="nav-item nav-link" to="/dashboard" onClick={this.toggleMenu}>
                  Dashboard
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" className="nav-item nav-link" to="/analytics" onClick={this.toggleMenu}>
                  Analytics
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" className="nav-item nav-link" to="/review" onClick={this.toggleMenu}>
                  Review
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" className="nav-item nav-link" to="/rfa" onClick={this.toggleMenu}>
                  RFA
                </NavLink>
              </li>
              {this.isAdmin() && (
                <li>
                  <NavLink activeClassName="active" className="nav-item nav-link" to="/configuration" onClick={this.toggleMenu}>
                    Configuration
                  </NavLink>
                </li>
              )}
              {this.isAdmin() && (
                <li>
                  <NavLink activeClassName="active" className="nav-item nav-link" to="/admin" onClick={this.toggleMenu}>
                    Admin
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink activeClassName="active" className="nav-item nav-link" to="/escalations" onClick={this.toggleMenu}>
                  Escalations
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" className="nav-item nav-link" to="/misreports" onClick={this.toggleMenu}>
                  MIS Reports
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" className="nav-item nav-link" to="/settings" onClick={this.toggleMenu}>
                  Settings
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" className="nav-item nav-link" to="/logout" onClick={this.toggleMenu}>
                  Logout
                </NavLink>
              </li>
            </ul>
          ) : (
            <ul className="navbar-nav ml-auto">
              <li>
                <NavLink activeClassName="active" className="nav-item nav-link" to="/login" onClick={this.toggleMenu}>
                  Login
                </NavLink>
              </li>
            </ul>
          )}
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    roles: state.authReducer.roles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NavBar));
