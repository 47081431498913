export const SET_USER_ROLES = "SET_USER_ROLES";
export const setUserRoles = (payload) => ({
  type: SET_USER_ROLES,
  payload: payload,
});

export const SET_LOAN_ACCOUNTS = "SET_LOAN_ACCOUNTS";
export const setLoanAccounts = (payload) => ({
  type: SET_LOAN_ACCOUNTS,
  payload: payload,
});

export const SET_USERS = "SET_USERS";
export const setUsers = (payload) => ({
  type: SET_USERS,
  payload: payload,
});

export const SET_ORGANIZATION_ROLES = "SET_ORGANIZATION_ROLES";
export const setOrganizationRoles = (payload) => ({
  type: SET_ORGANIZATION_ROLES,
  payload: payload,
});

export const SET_RELATIONSHIP_MANAGERS = "SET_RELATIONSHIP_MANAGERS";
export const setRelationshipManagers = (payload) => ({
  type: SET_RELATIONSHIP_MANAGERS,
  payload: payload,
});

export const ROW_CLONE_MODE = "ROW_CLONE_MODE";
export const setRowCloneMode = (payload) => ({
  type: ROW_CLONE_MODE,
  payload: payload,
});

export const SET_ALL_USER_ROLES = "SET_ALL_USER_ROLES";
export const setAllUserRoles = (payload) => ({
  type: SET_ALL_USER_ROLES,
  payload: payload,
});

export const SET_UNIT_CONTROLLERS = "SET_UNIT_CONTROLLERS";
export const setUnitControllers = (payload) => ({
  type: SET_UNIT_CONTROLLERS,
  payload: payload,
});
