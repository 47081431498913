import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, CardContent, CardHeader, Grid, withStyles } from "@material-ui/core";
import ISelect from "../common/ISelect";
import HelperUtils from "./../../utils/helperUtils";
import DataUtils from "../../utils/dataUtils";
import IPieChart from "../common/IPieChart";
import ColorToggle from "../analytics/ColorToggle";
import AnalyticsUtils from "../../utils/analyticsUtils";
import { setAnalytics, setDimensionLoans } from "../../actions/analyticsActions";
import LoanAccountBreakdown from "../analytics/LoanAccountBreakdown";
import ITooltip from "../common/ITooltip";

let styles = (theme) => ({
  root: {},
  cardHeader: {
    textAlign: "left",
    borderBottom: `1px solid #ccc`,
    background: "#f7f7f7",
    "& span": {
      fontWeight: "bold",
      fontSize: theme.spacing(2),
    },
  },
  alignLeft: {
    textAlign: "initial",
  },
  section: {
    // maxHeight: theme.spacing(52),
  },
});

const Charts = (props) => {
  const { classes } = props;
  const excludedFilters = ["Account"];
  const filterOptions = props.filterHierarchy?.filter((item) => !excludedFilters.includes(item.name));

  const defaultDimension = "Network";
  const getDefaultDimension = () => {
    return filterOptions?.find((item) => item.apiKey === defaultDimension);
  };

  const defaultDimensionColor = "red";
  const [selectedDimension, setSelectedDimension] = useState(getDefaultDimension());

  const [dimensionColor, setDimensionColor] = useState("");

  const handleAddFilterChange = (event, value) => {
    setSelectedDimension(value);
  };

  const setDefaultLoans = () => {
    const data = AnalyticsUtils.GetLoansByDimension(
      selectedDimension.value || filterOptions?.[0].value,
      null,
      props.analytics,
      dimensionColor || defaultDimensionColor,
      props.filterHierarchy
    );
    props.setDimensionLoans(data);
  };

  const setDefaultDimensionColor = () => {
    setDimensionColor(defaultDimensionColor);
  };

  const formatDataForTriggers = (data) => {
    return HelperUtils.FormatDataToTriggerSeries(selectedDimension.value, data, dimensionColor, props.filterHierarchy);
  };

  const onToggleChange = (value) => {
    setDimensionColor(value || defaultDimensionColor);
  };

  useEffect(setDefaultLoans, [dimensionColor, selectedDimension]);
  useEffect(setDefaultDimensionColor, []);

  const handlePointSelect = (e) => {
    const data = AnalyticsUtils.GetLoansByDimension(e.target.filterId, e.target.name, props.analytics, dimensionColor, props.filterHierarchy);
    props.setDimensionLoans(data);
  };

  const handlePointRelease = (e) => {
    setDefaultLoans();
  };

  return (
    <Grid container spacing={3}>
      <Grid item lg={6} md={6} sm={12} xs={12} className={classes.section}>
        <Card>
          <CardHeader
            title={"Triggers Breakdown"}
            className={classes.cardHeader}
            action={
              <ITooltip
                title={
                  <div>
                    <p>This donut represents the breakdown of triggers based on color and selected page filters.</p>
                    <ul>
                      <li>Click on the traffic signals to select color.</li>
                      <li>Select a dimension for breaking down number of triggers in each division of selected dimension.</li>
                    </ul>
                    <p>
                      Additionally, clicking on a sector will filter the loan accounts that the division comprises of and the breakdown of accounts is
                      displayed in 'Loan Account Breakdown' grid. Clicking again on an expanded sector will reset the grid to original state.
                    </p>
                  </div>
                }
              />
            }
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item lg={9} md={8} sm={8} xs={6} className={classes.alignLeft}>
                <ColorToggle onChange={onToggleChange} />
              </Grid>
              <Grid item lg={3} md={4} sm={4} xs={6}>
                <ISelect
                  options={HelperUtils.FindDifferenceBy(filterOptions, props.selectedFilters, "value")}
                  label={"Select Dimension"}
                  onChange={handleAddFilterChange}
                  defaultValue={selectedDimension}
                  disableClear={true}
                ></ISelect>
              </Grid>
            </Grid>
            <IPieChart data={formatDataForTriggers(props.analytics)} onPointSelect={handlePointSelect} onPointRelease={handlePointRelease} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <LoanAccountBreakdown />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    analytics: state.analyticsReducer.analytics,
    selectedFilterValues: state.dashboardReducer.selectedFilterValues,
    currentDate: state.dashboardReducer.currentDate,
    selectedFilterItems: state.dashboardReducer.selectedFilterItems,
    selectedDimensionColor: state.analyticsReducer.selectedDimensionColor,
    filterHierarchy: state.dashboardReducer.filterHierarchy,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAnalytics: (payload) => dispatch(setAnalytics(payload)),
    setDimensionLoans: (payload) => dispatch(setDimensionLoans(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Charts));
