import { SET_SELECTED_ACCOUNT, SET_ACCOUNT_DATA, SET_ACCOUNT_TRIGGERS, SET_ACCOUNT_CONTROLLERS, SET_PENDING_ALERTS } from "../actions/accountActions";

const initialState = {
  selectedAccount: "",
  accountData: "",
  accountTriggers: [],
  accountControllers: [],
  pendingAlerts: [],
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_ACCOUNT:
      return {
        ...state,
        selectedAccount: { ...action.payload },
      };
    case SET_ACCOUNT_DATA:
      return {
        ...state,
        accountData: { ...action.payload },
      };
    case SET_ACCOUNT_TRIGGERS:
      return {
        ...state,
        accountTriggers: [...action.payload],
      };
    case SET_ACCOUNT_CONTROLLERS:
      return {
        ...state,
        accountControllers: [...action.payload],
      };
    case SET_PENDING_ALERTS:
      return {
        ...state,
        pendingAlerts: [...action.payload],
      };
    default:
      return state;
  }
};

export default accountReducer;
