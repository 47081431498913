import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setUser } from "../actions/authActions";
import AuthUtils from "../utils/authUtils";

const Login = (props) => {
  const logout = () => {
    AuthUtils.Logout();
    props.setUser(null);
    window.location.reload();
  };
  useEffect(logout, []);

  return <h7>Logging you out..</h7>;
};

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (payload) => dispatch(setUser(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
