import { Badge, Tooltip, withStyles } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import React from "react";
import { connect } from "react-redux";
import { setSelectedAccount } from "../../actions/accountActions";
import { setSnackBar } from "../../actions/commonActions";
import { setAlertClosureHistory, setSelectedAlertRework } from "../../actions/reviewActions";
import FormatUtils from "../../utils/formatUtils";
import ITooltip from "../common/ITooltip";
import DataUtils from "../../utils/dataUtils";

let styles = (theme) => ({
  root: {},
  tableHeading: {
    fontSize: theme.spacing(3),
    float: `left`,
  },
  borrowerName: {
    fontWeight: theme.spacing(80),
    textDecorationColor: `gray`,
    cursor: `pointer`,
  },
  requireAttention: {
    textAlign: "center",
    cursor: "pointer",
  },
});

const PendingAlerts = (props) => {
  const { classes } = props;

  const options = {
    selectableRows: "none",
    responsive: "vertical",
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 15, 100, 1000, 10000],
    downloadOptions: {
      filename: FormatUtils.GetCSVFileName("Alert-Closure-History"),
      separator: ",",
    },
    onRowClick: (rowData, rowState) => {
      props.setSelectedAccount({
        value: rowData[1],
      });
    },
  };

  const columns = [
    {
      name: "BorrowerId",
      label: "BorrowerId",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "AccountNumber",
      label: "AccountNumber",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "BorrowerName",
      label: "Borrower Name",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return <span className={classes.borrowerName}>{value}</span>;
        },
      },
    },
    {
      name: "Branch",
      label: "Branch Name",
      options: {
        filter: true,
        sort: false,
        display: true,
      },
    },
    {
      name: "BranchCode",
      label: "Branch Code",
      options: {
        filter: true,
        sort: false,
        display: true,
      },
    },
    {
      name: "CIF",
      label: "CIF/CIN",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: "AmountDisbursed",
      label: "Exposure",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: "IRACStatus",
      label: "IRAC Status",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: "LoanType",
      label: "LoanType",
      options: {
        filter: true,
        sort: false,
        display: true,
      },
    },
    {
      name: "PendingAlerts",
      label: (
        <React.Fragment>
          <span>Pending Alerts</span>
          <ITooltip
            title={
              <div>
                <p>
                  Number of alerts requiring attention for triggers generated until{" "}
                  {FormatUtils.FormatToPrettyDate(props.triggerDate ?? DataUtils.GetDefaultTriggerEndDate())}
                </p>
              </div>
            }
          />
        </React.Fragment>
      ),
      options: {
        filter: true,
        sort: false,
        display: true,

        customBodyRender: (value) => {
          return (
            <div className={classes.requireAttention}>
              <Badge color="secondary" badgeContent={value}></Badge>
            </div>
          );
        },
      },
    },
  ];

  return (
    <React.Fragment>
      <MUIDataTable
        columns={columns}
        title={<span className={classes.tableHeading}>{`Alerts pending`}</span>}
        data={props.accountsWithPendingAlerts ?? []}
        options={options}
        className={classes.root}
      ></MUIDataTable>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    accountsWithPendingAlerts: state.reviewReducer.accountsWithPendingAlerts,
    selectedAccount: state.accountReducer.selectedAccount,
    alertClosureHistory: state.reviewReducer.alertClosureHistory,
    accountTriggers: state.accountReducer.accountTriggers,
    triggerDate: state.reviewReducer.triggerDate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedAlertRework: (payload) => dispatch(setSelectedAlertRework(payload)),
    setSnackBar: (payload) => dispatch(setSnackBar(payload)),
    setAlertClosureHistory: (payload) => dispatch(setAlertClosureHistory(payload)),
    setSelectedAccount: (payload) => dispatch(setSelectedAccount(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PendingAlerts));
