import MUIDataTable from "mui-datatables";
import React from "react";
import FormatUtils from "./../../utils/formatUtils";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { setCurrentDate } from "../../actions/dashboardActions";
import { setTriggerDate } from "../../actions/reviewActions";
import HelperUtils from "../../utils/helperUtils";

let styles = (theme) => ({
  bookmark: {
    float: "left",
    margin: `0px ${theme.spacing(2)}px 0px ${theme.spacing(2)}px`,
  },
  npaType: {
    cursor: "pointer",
  },
  npaTable: {
    ".MuiTableRow-root": {
      cursor: "pointer",
    },
  },
  actionIcon: {
    padding: 0,
  },
  triggerDate: {
    fontWeight: theme.spacing(80),
    textDecorationColor: `gray`,
    cursor: `pointer`,
  },
});

const AccountPendingAlerts = (props) => {
  const { classes, onClose } = props;

  const options = {
    selectableRows: "none",
    responsive: "vertical",
    search: true,
    viewColumns: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 15, 100, 1000, 10000],
    onRowClick: (rowData) => {
      console.log(rowData);
      props.setTriggerDate(new Date(rowData[1]));
      if (onClose) {
        onClose();
      }
    },
    downloadOptions: {
      filename: FormatUtils.GetCSVFileName(`AccountPendingAlerts`),
      separator: ",",
    },
  };

  const columns = [
    {
      name: "BorrowerId",
      label: "BorrowerId",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "TriggerDate",
      label: "Pending From",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return FormatUtils.FormatToPrettyDate(value);
        },
      },
    },
  ];

  const sortAccountPendingAlerts = () => {
    return HelperUtils.SortArrayBy(props.accountPendingAlerts, "TriggerDate", "desc");
  };

  return <MUIDataTable columns={columns} data={sortAccountPendingAlerts()} options={options} className={classes.npaTable}></MUIDataTable>;
};

const mapStateToProps = (state) => {
  return {
    accountPendingAlerts: state.reviewReducer.accountPendingAlerts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentDate: (payload) => dispatch(setCurrentDate(payload)),
    setTriggerDate: (payload) => dispatch(setTriggerDate(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(AccountPendingAlerts)));
