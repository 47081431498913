let { hostname } = window.location;
const environment = hostname.includes("localhost") ? "localhost" : hostname.substring(0, hostname.indexOf("."));

const getApiUrl = (environment) => {
  if (environment === "localhost") {
    return "https://localhost:5001/api";
  }

  if (environment === "test") {
    return "https://test.api.credews.com/api";
  }

  return "https://api.credews.com/api";
};

const apiUrl = getApiUrl(environment);
const config = {
  apiUrl: apiUrl,
};

export default config;
