import {
  CLEAR_SELECTED_TRIGGERS,
  SET_SELECTED_TRIGGER_COLOR,
  SET_SELECTED_TRIGGER_TYPE,
  SET_SELECTED_TRIGGER_VALUE,
  SET_TRIGGERED_LOANS,
} from "../actions/triggerActions";
import DataUtils from "../utils/dataUtils";
import { SET_SELECTED_TRIGGER } from "./../actions/triggerActions";

const initialState = {
  selectedTriggerType: DataUtils.GetTrigger(1),
  triggeredLoans: [],
  selectedTrigger: "",
  selectedTriggerColor: "",
  selectedTriggerValue: "",
};

const triggerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_TRIGGER_TYPE:
      return {
        ...state,
        selectedTriggerType: action.payload,
      };
    case SET_TRIGGERED_LOANS:
      return {
        ...state,
        triggeredLoans: [...action.payload],
      };
    case SET_SELECTED_TRIGGER_COLOR:
      return {
        ...state,
        selectedTriggerColor: action.payload,
      };
    case SET_SELECTED_TRIGGER:
      return {
        ...state,
        selectedTrigger: { ...action.payload },
      };
    case CLEAR_SELECTED_TRIGGERS:
      return {
        ...state,
        triggeredLoans: [],
        selectedTrigger: "",
        selectedTriggerColor: "",
        selectedTriggerValue: "",
      };
    case SET_SELECTED_TRIGGER_VALUE:
      return {
        ...state,
        selectedTriggerValue: action.payload,
      };

    default:
      return state;
  }
};

export default triggerReducer;
