export const SET_MIS_REPORTS = "SET_MIS_REPORTS";
export const setMISReports = (payload) => ({
  type: SET_MIS_REPORTS,
  payload: payload,
});

export const SET_ALERT_STATUS_SUMMARY = "SET_ALERT_STATUS_SUMMARY";
export const setAlertStatusSummary = (payload) => ({
  type: SET_ALERT_STATUS_SUMMARY,
  payload: payload,
});

export const SET_IS_DATA_LOADED = "SET_ALERT_STATUS_SUMMARY";
export const setIsDataLoaded = (payload) => ({
  type: SET_IS_DATA_LOADED,
  payload: payload,
});

export const SET_PIVOTTABLE_FILTERS = "SET_PIVOTTABLE_FILTERS";
export const setPivottableFilters = (payload) => ({
  type: SET_PIVOTTABLE_FILTERS,
  payload: payload,
});