export const SET_REVIEW_ACTIONS = "SET_REVIEW_ACTIONS";
export const setReviewActions = (payload) => ({
  type: SET_REVIEW_ACTIONS,
  payload: payload,
});

export const SET_REVIEW_HISTORY = "SET_REVIEW_HISTORY";
export const setReviewHistory = (payload) => ({
  type: SET_REVIEW_HISTORY,
  payload: payload,
});

export const SET_REVIEWREMARKS_HISTORY = "SET_REVIEWREMARKS_HISTORY";
export const setReviewRemarksHistory = (payload) => ({
  type: SET_REVIEWREMARKS_HISTORY,
  payload: payload,
});

export const SET_SUGGESTIVE_ACTIONS = "SET_SUGGESTIVE_ACTIONS";
export const setSuggestiveActions = (payload) => ({
  type: SET_SUGGESTIVE_ACTIONS,
  payload: payload,
});

export const SET_FINISH_ENABLED = "SET_FINISH_ENABLED";
export const setFinishEnabled = (payload) => ({
  type: SET_FINISH_ENABLED,
  payload: payload,
});

export const SET_ACCOUNT_REVIEW_HISTORY = "SET_ACCOUNT_REVIEW_HISTORY";
export const setAccountReviewHistory = (payload) => ({
  type: SET_ACCOUNT_REVIEW_HISTORY,
  payload: payload,
});

export const SET_OVERALL_COMMENTS = "SET_OVERALL_COMMENTS";
export const setOverallComments = (payload) => ({
  type: SET_OVERALL_COMMENTS,
  payload: payload,
});

export const SET_OVERALL_COMMENTS_HISTORY = "SET_OVERALL_COMMENTS_HISTORY";
export const setOverallCommentsHistory = (payload) => ({
  type: SET_OVERALL_COMMENTS_HISTORY,
  payload: payload,
});

export const SET_ESCALATIONS = "SET_ESCALATIONS";
export const setEscalations = (payload) => ({
  type: SET_ESCALATIONS,
  payload: payload,
});

export const SET_TRIGGER_DATE = "SET_TRIGGER_DATE";
export const setTriggerDate = (payload) => ({
  type: SET_TRIGGER_DATE,
  payload: payload,
});

export const SET_ALERT_CLOSURE_HISTORY = "SET_ALERT_CLOSURE_HISTORY";
export const setAlertClosureHistory = (payload) => ({
  type: SET_ALERT_CLOSURE_HISTORY,
  payload: payload,
});

export const SET_SELECTED_ALERT_REWORK = "SET_SELECTED_ALERT_REWORK";
export const setSelectedAlertRework = (payload) => ({
  type: SET_SELECTED_ALERT_REWORK,
  payload: payload,
});

export const SET_NPA_MOVEMENT = "SET_NPA_MOVEMENT";
export const setNPAMovement = (payload) => ({
  type: SET_NPA_MOVEMENT,
  payload: payload,
});

export const SET_ACCOUNTS_WITH_PENDING_ALERTS = "SET_ACCOUNTS_WITH_PENDING_ALERTS";
export const setAccountsWithPendingAlerts = (payload) => ({
  type: SET_ACCOUNTS_WITH_PENDING_ALERTS,
  payload: payload,
});

export const SET_SELECTED_TAB_INDEX = "SET_SELECTED_TAB_INDEX";
export const setSelectedTabIndex = (payload) => ({
  type: SET_SELECTED_TAB_INDEX,
  payload: payload,
});

export const SET_ACCOUNT_PENDING_ALERTS = "SET_ACCOUNT_PENDING_ALERTS";
export const setAccountPendingAlerts = (payload) => ({
  type: SET_ACCOUNT_PENDING_ALERTS,
  payload: payload,
});

export const SET_CLOSED_ALERTS = "SET_CLOSED_ALERTS";
export const setClosedAlerts = (payload) => ({
  type: SET_CLOSED_ALERTS,
  payload: payload,
});

export const SET_ALERTS_CLOSED_BY_SUBORDINATES = "SET_ALERTS_CLOSED_BY_SUBORDINATES";
export const setAlertsClosedBySubordinates = (payload) => ({
  type: SET_ALERTS_CLOSED_BY_SUBORDINATES,
  payload: payload,
});

export const SET_IS_ALERT_CLOSED = "SET_IS_ALERT_CLOSED";
export const setIsAlertClosed = (payload) => ({
  type: SET_IS_ALERT_CLOSED,
  payload: payload,
});

export const SET_REASSIGNED_ALERTS = "SET_REASSIGNED_ALERTS";
export const setReassignedAlerts = (payload) => ({
  type: SET_REASSIGNED_ALERTS,
  payload: payload,
});

export const SET_USER_REVIEW_INITIATORS = "SET_USER_REVIEW_INITIATORS";
export const setUserReviewInitiators = (payload) => ({
  type: SET_USER_REVIEW_INITIATORS,
  payload: payload,
});
