/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import { Tooltip, withStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormatUtils from "../../utils/formatUtils";

let styles = (theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    "& .MuiInputLabel-outlined": {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.spacing(1.5),
    },
    "& .MuiInputLabel-formControl": {
      top: theme.spacing(-0.5),
    },
    "& .MuiAutocomplete-option": {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.spacing(1.75),
    },
    "& .MuiInputBase-input": {
      height: theme.spacing(1.5),
    },
  },
  inputRoot: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.spacing(1.5),
  },
  paper: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.spacing(1.75),
  },
});

const ISelect = (props) => {
  const { options, onChange, classes, defaultValue, label, multiple, onPaste, value, disableClear, disabled } = props;

  const [open, setOpen] = useState(false);
  return (
    <div className={classes.select}>
      {multiple ? (
        <Tooltip title={`${FormatUtils.ToTitleCase(label)}`} aria-label={`${label}`}>
          <Autocomplete
            id="i-multi-select"
            multiple
            alt={label}
            label={label}
            filterSelectedOptions
            options={options}
            disableClearable={disableClear}
            onChange={onChange}
            classes={classes}
            defaultValue={defaultValue}
            getOptionLabel={(option) => option.name || option}
            onPaste={onPaste}
            value={value}
            getOptionSelected={(option, value) => option.value === value.value}
            renderInput={(params) => <TextField {...params} label={label || "Select"} variant="outlined" size="medium" />}
            inputProps={{ role: "select", "aria-describedby": label, "aria-label": `Search ${label}`, "aria-expanded": open ? "true" : "false" }}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            ariaLabel={label}
            disabled={disabled}
          />
        </Tooltip>
      ) : (
        <Tooltip title={`${FormatUtils.ToTitleCase(label)}`} aria-label={`${label}`}>
          <Autocomplete
            id="i-select"
            alt={label}
            label={label}
            options={options}
            onChange={onChange}
            defaultValue={defaultValue || ""}
            disableClearable={disableClear}
            value={value}
            classes={classes}
            getOptionLabel={(option) => option.name || ""}
            renderInput={(params) => <TextField {...params} label={label || "Select"} variant="outlined" size="medium" />}
            inputProps={{ role: "select", "aria-describedby": label, "aria-label": `Search ${label}`, "aria-expanded": open ? "true" : "false" }}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            ariaLabel={label}
            disabled={disabled}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default withStyles(styles)(ISelect);
