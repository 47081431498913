export const SET_SNACKBAR = "SET_SNACKBAR";
export const setSnackBar = (payload) => ({
  type: SET_SNACKBAR,
  payload: payload,
});

export const SET_LOADER = "SET_LOADER";
export const setLoader = (payload) => ({
  type: SET_LOADER,
  payload: payload,
});

export const CLEAR_STORE = "CLEAR_STORE";
export const clearStore = () => ({
  type: CLEAR_STORE,
});
