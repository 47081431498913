import React from "react";
import Loader from "react-loader-spinner";

const Spinner = (props) => {
  const { color, type } = props;
  return (
    <Loader
      type={type || "BallTriangle"}
      color={color || "#ABCDEF"}
      height={100}
      width={100}
    />
  );
};

export default Spinner;
