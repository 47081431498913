import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import FormatUtils from "../../utils/formatUtils";
import { setSelectedAccount } from "../../actions/accountActions";
import { withRouter } from "react-router-dom";

let styles = (theme) => ({
  root: {
    "& .MuiTypography-h6": {
      textAlign: `left`,
    },
    "& .MUIDataTableToolbar-actions-36": {
      textAlign: `right`,
    },
    "& .MuiTableCell-head": {
      fontWeight: `bold`,
    },
    "& .MuiTableCell-root": {
      fontSize: theme.spacing(1.5),
    },
  },
  tableHeading: {
    fontWeight: `bold`,
    textAlign: `left`,
  },
  amount: {
    fontWeight: `bold`,
  },
  borrowerName: {
    fontWeight: theme.spacing(80),
    textDecorationColor: `gray`,
    cursor: `pointer`,
  },
});

const LoanAccountBreakdown = (props) => {
  const { classes } = props;

  const columns = [
    {
      name: "BorrowerId",
      label: "BorrowerId",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "BorrowerName",
      label: "Borrower Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <span className={classes.borrowerName}>{value}</span>;
        },
      },
    },
    {
      name: "LoanType",
      label: "Loan Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "AmountDisbursed",
      label: "Amount Disbursed",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => <div className={classes.amount}>{FormatUtils.FormatNumberToCurrency(value)}</div>,
      },
    },
    {
      name: "BusinessUnit",
      label: "Country",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "Circle",
      label: "Zone",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "Network",
      label: "State",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "Module",
      label: "Region",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "Branch",
      label: "Branch",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
  ];

  const options = {
    selectableRows: "none",
    responsive: "vertical",
    search: true,
    viewColumns: true,
    rowsPerPage: 6,
    rowsPerPageOptions: [10, 15, 100, 1000, 10000],
    onRowClick: (rowData) => {
      const id = rowData[0];
      const name = rowData[1];
      const account = {
        id,
        name,
      };
      props.setSelectedAccount(account);
      props.history.push(`account/${id}`);
    },
    downloadOptions: {
      filename: FormatUtils.GetCSVFileName("Loan-Account-Breakdown"),
      separator: ",",
    },
  };

  return (
    props.dimensionLoans?.length > 0 && (
      <MUIDataTable
        columns={columns}
        title={<div className={classes.tableHeading}>Loan Account Breakdown</div>}
        data={props.dimensionLoans}
        options={options}
        className={classes.root}
        height={420}
      ></MUIDataTable>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    dimensionLoans: state.analyticsReducer.dimensionLoans,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedAccount: (payload) => dispatch(setSelectedAccount(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(LoanAccountBreakdown)));
