import { withStyles } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import React from "react";
import { connect } from "react-redux";
import FormatUtils from "../../utils/formatUtils";

let styles = (theme) => ({
  root: {},
  tableHeading: {
    fontSize: theme.spacing(3),
    float: `left`,
  },
});

const AccountReviewHistory = (props) => {
  const { classes } = props;

  const options = {
    selectableRows: "none",
    responsive: "vertical",
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 15, 100, 1000, 10000],
    downloadOptions: {
      filename: FormatUtils.GetCSVFileName("Account-Review-History"),
      separator: ",",
    },
  };

  const columns = [
    {
      name: "UserReviewId",
      label: "UserReviewId",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "TriggerId",
      label: "TriggerId",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "SuggestiveActionId",
      label: "SuggestiveActionId",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "Category",
      label: "Category",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "TriggerName",
      label: "Trigger Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "TriggerDate",
      label: "Trigger Date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => FormatUtils.FormatToPrettyDate(value),
      },
    },
    {
      name: "SuggestiveAction",
      label: "Suggestive Action",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "ReviewedBy",
      label: "Reviewed By",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "ReviewDate",
      label: "Review Date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => FormatUtils.FormatToPrettyDate(value),
      },
    },
    // {
    //   name: "IsComplete",
    //   label: "Status",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     customBodyRender: (value) => (value ? "Complete" : "Incomplete"),
    //   },
    // },
    // {
    //   name: "CompletedDate",
    //   label: "Completed On",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     customBodyRender: (value) => (value ? FormatUtils.FormatToPrettyDate(value) : ""),
    //   },
    // },
  ];

  return (
    <div>
      <MUIDataTable
        columns={columns}
        title={<div className={classes.tableHeading}>{`Account Review History`}</div>}
        data={props.accountReviewHistory}
        options={options}
        className={classes.root}
      ></MUIDataTable>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    accountReviewHistory: state.reviewReducer.accountReviewHistory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountReviewHistory));
