import {
  SET_TO_FORM_OPEN,
  SET_TO_FORM_CLOSE,
  SET_FORM_DETAILS,
  SET_TO_FORM_REVIEW,
  SET_CLOSED_RFA,
  SET_BORROWER_NAME,
} from "../actions/rfaActions";

const initialState = {
  toFormOpen: false,
  toFormClose: false,
  formDetails: {},
  toFormReview: false,
  closedRFA: true,
  borrowerName: null
};

const rfaReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TO_FORM_OPEN:
      return {
        ...state,
        toFormOpen: action.payload,
      };
    case SET_TO_FORM_CLOSE:
      return {
        ...state,
        toFormClose: action.payload,
      };
    case SET_FORM_DETAILS:
      return {
        ...state,
        formDetails: { ...action.payload },
      };
    case SET_TO_FORM_REVIEW:
      return {
        ...state,
        toFormReview: action.payload,
      };
    case SET_CLOSED_RFA:
      return {
        ...state,
        closedRFA: action.payload,
      };
    case SET_BORROWER_NAME:
      return {
        ...state,
        borrowerName: action.payload,
      };
    default:
      return state;
  }
};

export default rfaReducer;
