import React from "react";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";

const Criticality = (props) => {
  const getStatus = (value) => {
    if (value > 8) return "error";
    if (value < 7) return "success";
    else return "active";
  };

  return (
    <Progress
      theme={{
        error: {
          symbol: props.value,
          trailColor: "pink",
          color: "red",
        },
        default: {
          symbol: props.value,
          trailColor: "lightblue",
          color: "blue",
        },
        active: {
          symbol: props.value,
          trailColor: "yellow",
          color: "orange",
        },
        success: {
          symbol: props.value,
          trailColor: "lime",
          color: "green",
        },
      }}
      percent={(props.value / 10) * 100}
      status={getStatus(props.value)}
    />
  );
};

export default Criticality;
