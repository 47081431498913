import React, { useEffect } from "react";
import { setRowCloneMode, setUserRoles } from "../../actions/adminActions";
import AdminUtils from "../../utils/adminUtils";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import IEditable from "../common/IEditable";
import { setSnackBar } from "../../actions/commonActions";
import DynamicFeed from "@material-ui/icons/DynamicFeed";
import FormatUtils from "./../../utils/formatUtils";

let styles = (theme) => ({
  root: {},
});

const Roles = (props) => {
  const { userRoles } = props;
  const onLoad = () => {
    AdminUtils.FetchUserRoles().then((data) => {
      props.setUserRoles(mapUserRoles(data));
    });
  };

  const mapOrganizationRoles = () => {
    const roles = {};
    (props.organizationRoles || []).forEach((role) => {
      roles[role.OrganizationRoleId] = role.DisplayName;
    });
    return roles;
  };

  const columns = [
    {
      title: "Username",
      field: "Username",
      editable: "never",
    },
    {
      title: "EmailAddress",
      field: "EmailAddress",
      editable: "never",
    },
    {
      title: "PhoneNumber",
      field: "PhoneNumber",
      editable: "never",
    },
    {
      title: "Name",
      field: "Name",
      editable: "never",
    },
    {
      title: "Role",
      field: "RoleId",
      lookup: mapOrganizationRoles(),
      onChange: (e) => {
        console.log("Value changed", e);
      },
    },
  ];

  const options = {
    downloadOptions: {
      filename: FormatUtils.GetCSVFileName("Roles"),
      separator: ",",
    },
    filtering: true,
  };

  const mapUserRoles = (data) => {
    const mapped = data?.map((i) => ({
      UserId: i.UserId,
      Username: i.User.Username,
      EmailAddress: i.User.EmailAddress,
      PhoneNumber: i.User.PhoneNumber,
      Name: `${i.User.FirstName || ""} ${i.User.MiddleName || ""} ${i.User.LastName || ""}`,
      Role: i.OrganizationRoles?.DisplayName,
      RoleId: i.OrganizationRoleId,
      _data: i,
    }));
    return mapped;
  };

  useEffect(onLoad, []);

  const onUpdate = (newValue) => {
    const updateObj = newValue._data;
    updateObj.OrganizationRoleId = parseInt(newValue.RoleId);
    AdminUtils.UpdateUserRole(updateObj).then((data) => {
      if (data) {
        props.setSnackBar("Roles updated");
      } else {
        props.setSnackBar("Failed to update roles");
      }
      onLoad();
    });
  };

  const onBulkUpdate = (changes) => {
    props.setSnackBar("Bulk update operation in progress");
    var sendArray = [];
    for (const key in changes) {
      if (Object.hasOwnProperty.call(changes, key)) {

        const element = changes[key].newData._data;
        element.OrganizationRoleId = parseInt(changes[key].newData.RoleId);
        sendArray.push(element);
      }
    }
    AdminUtils.BulkUpdateUserRole(sendArray).then((data) => {
      if (data) {
        props.setSnackBar("Bulk update of Roles complete");
      } else {
        props.setSnackBar("Failed to bulk update Roles");
      }
      onLoad();
    });
  };

  return (
    <React.Fragment>
      {userRoles.length > 0 && (
        <IEditable
          columns={columns}
          data={userRoles}
          title={`Roles management`}
          onUpdate={onUpdate}
          onBulkUpdate={onBulkUpdate}
          onFilterChange={() => { }} //this is required for filters to work
          options={options}
          actions={[
            {
              icon: "queue",
              tooltip: "Clone mode",
              isFreeAction: true,
              onClick: (event) => {
                props.setSnackBar(`You have ${!props.rowCloneMode ? "enabled" : "disabled"} multiple row edit`);
                props.setRowCloneMode(!props.rowCloneMode);
              },
            },
          ]}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userRoles: state.adminReducer.userRoles,
    organizationRoles: state.adminReducer.organizationRoles,
    rowCloneMode: state.adminReducer.rowCloneMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserRoles: (payload) => dispatch(setUserRoles(payload)),
    setSnackBar: (payload) => dispatch(setSnackBar(payload)),
    setRowCloneMode: (payload) => dispatch(setRowCloneMode(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Roles));
