export const SET_SELECTED_ACCOUNT = "SET_SELECTED_ACCOUNT";
export const setSelectedAccount = (payload) => ({
  type: SET_SELECTED_ACCOUNT,
  payload: payload,
});

export const SET_ACCOUNT_DATA = "SET_ACCOUNT_DATA";
export const setAccountData = (payload) => ({
  type: SET_ACCOUNT_DATA,
  payload: payload,
});

export const SET_ACCOUNT_TRIGGERS = "SET_ACCOUNT_TRIGGERS";
export const setAccountTriggers = (payload) => ({
  type: SET_ACCOUNT_TRIGGERS,
  payload: payload,
});

export const SET_ACCOUNT_CONTROLLERS = "SET_ACCOUNT_CONTROLLERS";
export const setAccountControllers = (payload) => ({
  type: SET_ACCOUNT_CONTROLLERS,
  payload: payload,
});

export const SET_PENDING_ALERTS = "SET_PENDING_ALERTS";
export const setPendingAlerts = (payload) => ({
  type: SET_PENDING_ALERTS,
  payload: payload,
});
