import FormatUtils from "./formatUtils";
import HttpUtils from "./httpUtils";
import config from "./../config/config";

const fetchRiskScores = async (accountNumber, currentDate) => {
  const data = await HttpUtils.Get(`${config.apiUrl}/risk/${accountNumber}/${FormatUtils.FormatToPrettyDate(currentDate)}`);
  return data?.data;
};

const RiskUtils = {
  FetchRiskScores: fetchRiskScores,
};

export default RiskUtils;
