import React from "react";
import Loader from "react-loader-spinner";
import { withStyles } from "@material-ui/core";

let styles = (theme) => ({
  root: {
    position: `fixed`,
    width: `100%`,
    height: `100%`,
    zIndex: `9999`,
    left: `0`,
    top: `45%`,
  },
});

const ILoader = (props) => {
  const { type, color, classes } = props;
  return (
    <Loader
      type={type || "Bars"}
      color={color || "#36b9bc"}
      height={100}
      width={100}
      className={classes.root}
    />
  );
};

export default withStyles(styles)(ILoader);
