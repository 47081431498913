import React from "react";
import { withStyles, Grid, Button, Tooltip, Box } from "@material-ui/core";
import { connect } from "react-redux";
import { setSnackBar } from "../../actions/commonActions";

import FormatUtils from "./../../utils/formatUtils";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { setSelectedTrigger, setSelectedTriggerColor, setSelectedTriggerValue } from "../../actions/triggerActions";
import { withRouter } from "react-router";

let styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "inherit",
    justifyContent: "center",
  },
  red: {
    backgroundColor: `red`,
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: theme.spacing(3),
    marginRight: theme.spacing(1),
    borderRadius: `50%`,
    display: `inline-block`,
    verticalAlign: `bottom`,
  },
  amber: {
    background: `#FFC200`,
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: theme.spacing(3),
    marginRight: theme.spacing(1),
    borderRadius: `50%`,
    display: `inline-block`,
    verticalAlign: `bottom`,
  },
  green: {
    background: `green`,
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: theme.spacing(3),
    marginRight: theme.spacing(1),
    borderRadius: `50%`,
    display: `inline-block`,
    verticalAlign: `bottom`,
  },
  alerts: {
    fontSize: theme.spacing(1.25),
    color: `#2293e2`,
  },
  alignLeft: {
    textAlign: `left`,
    paddingLeft: theme.spacing(3),
    cursor: "pointer",
  },
});

const OverallTriggers = (props) => {
  const { classes } = props;
  const defaultColor = "red";

  const handleClick = (color) => (event) => {
    if (findCount(color) > 0) {
      props.setSelectedTrigger({
        name: `${FormatUtils.ToTitleCase(color)} loans`,
      });
      props.setSelectedTriggerColor(color || defaultColor);
      props.setSelectedTriggerValue(0);
    } else {
      props.setSnackBar("No Loans Found");
    }
  };

  const findCount = (color) => {
    let triggerKey = "";
    if (props.selectedTriggerType?.id === 1) triggerKey = "structuredTriggers";
    if (props.selectedTriggerType?.id === 2) triggerKey = "unstructuredTriggers";
    if (props.selectedTriggerType?.id === 3) triggerKey = "llmsTriggers";

    return props[triggerKey]?.OverallTriggers?.find((item) => item.Color.toLowerCase() === color.toLowerCase())?.Count;
  };

  const getPendingAlerts = () => {
    const count = props.pendingAlerts?.find((i) => i.TriggerType.toLowerCase() === "all")?.PendingAlertCount;
    return count;
  };

  const redirectToReview = () => {
    props.history.push("/review");
  };

  return (
    !props.selectedTriggerColor && (
      <div>
        <Grid container spacing={1} className={`${classes.root} shadow-md`}>
          <Grid item className={classes.alerts}>
            <Tooltip title={"Alerts"} aria-label={`alerts`}>
              <div>
                <NotificationsActiveIcon></NotificationsActiveIcon>
                Alerts
              </div>
            </Tooltip>
          </Grid>
          <Grid item>
            <Box display="inline-flex">
              <Box marginRight={2}>
                <Tooltip title="Red Loans" aria-label="red-loans">
                  <Button className={classes.red} onClick={handleClick("red")} />
                </Tooltip>
                {findCount("red") || 0}
              </Box>
              <Box marginRight={2}>
                <Tooltip title="Amber Loans" aria-label="amber-loans">
                  <Button className={classes.amber} onClick={handleClick("amber")} />
                </Tooltip>
                {findCount("amber") || 0}
              </Box>
              <Box>
                <Tooltip title="Green Loans" aria-label="green-loans">
                  <Button className={classes.green} onClick={handleClick("green")} />
                </Tooltip>
                {findCount("green") || 0}
              </Box>
            </Box>
          </Grid>
        </Grid>
        {
          getPendingAlerts() > 0 && (
            <div className={classes.alignLeft} onClick={redirectToReview}>
              <strong>{getPendingAlerts()}</strong> alert(s) require attention
            </div>
          )
        }
      </div >
    )
  );
};

const mapStateToProps = (state) => {
  return {
    pendingAlerts: state.accountReducer.pendingAlerts,
    structuredTriggers: state.dashboardReducer.structuredTriggers,
    unstructuredTriggers: state.dashboardReducer.unstructuredTriggers,
    llmsTriggers: state.dashboardReducer.llmsTriggers,
    selectedTriggerColor: state.triggerReducer.selectedTriggerColor,
    selectedTriggerType: state.triggerReducer.selectedTriggerType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedTriggerColor: (payload) => dispatch(setSelectedTriggerColor(payload)),
    setSelectedTrigger: (payload) => dispatch(setSelectedTrigger(payload)),
    setSelectedTriggerValue: (payload) => dispatch(setSelectedTriggerValue(payload)),
    setSnackBar: (payload) => dispatch(setSnackBar(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(OverallTriggers)));