import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import {
  withStyles, Card, CardHeader, CardContent, Grid, CircularProgress,
  Box, Button, IconButton, Typography, DialogContentText, DialogActions,
  TextField, TableRow, Table, TableCell, TableContainer, TableHead, TableBody, Tooltip
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { setMISReports, setIsDataLoaded, setPivottableFilters } from "../../actions/misReportsActions";
import '../../styles/pivottable.css';
import ITooltip from "../common/ITooltip";
import IPivotTableUI from "../common/IPivotTableUI";
import MISReportsUtils from "../../utils/misReportsUtils";
import HelperUtils from '../../utils/helperUtils';
import IDatePicker from '../common/IDatePicker';
import DataUtils from '../../utils/dataUtils';
import StorageIcon from '@material-ui/icons/Storage';
import IDialog from '../common/IDialog';
import MUIDataTable from 'mui-datatables';
import FormatUtils from '../../utils/formatUtils';
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { setSnackBar } from "../../actions/commonActions";

let styles = (theme) => ({
  cardHeader: {
    textAlign: "left",
    borderBottom: `1px solid #ccc`,
    background: "#f7f7f7",
    "& span": {
      fontWeight: "initial",
    },
    "& .MuiCardHeader-action": {
      margin: "0px",
      fontWeight: "initial"
    },
    "& .MuiTypography-body1": {
      fontWeight: "bold",
      fontSize: theme.spacing(2),
    },
  },
  noDataText: {
    "& .MuiTypography-body1": {
      fontSize: "1.5rem",
      color: "gray"
    }
  },
  bookmark: {
    float: "left",
    margin: `0px ${theme.spacing(2)}px 0px ${theme.spacing(2)}px`,
  },
  bookmarkName: {
    cursor: "pointer",
  },
  bookmarkTable: {
    ".MuiTableRow-root": {
      cursor: "pointer",
    },
  },
  actionIcon: {
    padding: 0,
  },
});

const MasterMISReport = (props) => {
  const { classes } = props;
  const [triggerFromDate, setTriggerFromDate] = useState(DataUtils.GetDefaultCompareDate());
  const [triggerFromDateError, setTriggerFromDateError] = useState(false);
  const [triggerToDate, setTriggerToDate] = useState(new Date());
  const [triggerToDateError, setTriggerToDateError] = useState(false);
  const [bookmarksDialogOpen, setBookmarksDialogOpen] = useState(false);
  const [bookmarkName, setBookmarkName] = useState("");
  const [savedBookmarksData, setSavedBookmarksData] = useState([]);

  const handleDateChange = (date, name) => {
    let dateTemp = triggerFromDate.getTimezoneOffset();
    if (!HelperUtils.IsValidDate(date) || date > new Date()) {
      if (name === "triggerFromDate") {
        setTriggerFromDateError(true);
        return
      }
      else if (name === "triggerToDate") {
        setTriggerToDateError(true);
        return
      }
    }
    if (name === "triggerFromDate") {
      if (date > triggerToDate) {
        setTriggerFromDateError(true);
        return;
      }
      if (date < triggerToDate) {
        setTriggerToDateError(false);
      }
      setTriggerFromDateError(false);
      setTriggerFromDate(date);
    }
    if (name === "triggerToDate") {
      if (date < triggerFromDate) {
        setTriggerToDateError(true);
        return;
      }
      if (date > triggerFromDate) {
        setTriggerFromDateError(false);
      }
      setTriggerToDateError(false);
      setTriggerToDate(date);
    }
  };

  const handleDataRequest = () => {
    MISReportsUtils.FetchMISReports("masterReport", {
      "alertCreatedFromDate": FormatUtils.FormatToISODateString(triggerFromDate),
      "alertCreatedToDate": FormatUtils.FormatToISODateString(triggerToDate)
    }).then((data) => {
      props.setMISReports(data);
      props.setIsDataLoaded(true);
    });
  };

  const setBookmarkData = (data) => {
    data["unusedOrientationCutoff"] = 1000;
    props.setPivottableFilters(data);
    setBookmarksDialogOpen(true);
  };

  const saveBookmark = () => {
    MISReportsUtils.SaveBookmark({
      "BookmarkName": bookmarkName,
      "BookmarkData": props.pivottableFilters
    }).then((data) => {
      if (data == "success") {
        props.setSnackBar("Bookmark saved successfully");
        setBookmarksDialogOpen(false);
        setBookmarkName("");
      }
      else { props.setSnackBar("Failed to save bookmark"); }
    });
  };

  const retrieveBookmarks = () => {
    MISReportsUtils.FetchBookmarks({}).then((data) => {
      setSavedBookmarksData(data);
    });
  };

  const deleteBookmark = (bookmarkId) => {
    MISReportsUtils.DeleteBookmark(bookmarkId).then((data) => {
      setSavedBookmarksData(data);
    });
  };

  const handleDialogClose = () => {
    setBookmarkName("");
    setBookmarksDialogOpen(false);
  };

  useEffect(() => {
    if (bookmarksDialogOpen) {
      retrieveBookmarks();
    }
  }, [bookmarksDialogOpen]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="left">
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <IDatePicker label="Alert Created - From Date" value={triggerFromDate}
              maxDate={triggerToDate}
              onChange={(date) => handleDateChange(date, "triggerFromDate")}
              error={triggerFromDateError}
            >
            </IDatePicker>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <IDatePicker label="Alert Created - To Date" value={triggerToDate}
              maxDate={new Date()}
              onChange={(date) => handleDateChange(date, "triggerToDate")}
              error={triggerToDateError}
            >
            </IDatePicker>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            disableTypography
            title={
              <Box display="flex">
                <Typography>Drag 'n Drop Pivot table</Typography>
                <ITooltip
                  title={
                    <div>
                      <p>
                        This pivot table gives ability to drag and drop rows and columns, generate charts, heat maps and filter data.
                      </p>
                    </div>
                  }
                />
              </Box>}
            className={classes.cardHeader}
            action={
              <Box>
                {props.isDataLoaded && (
                  <Button variant="outlined" fontWeight="initial" size="small" disabled={(triggerToDateError || triggerFromDateError)}
                    onClick={handleDataRequest} startIcon={<StorageIcon />}>Request data</Button>
                )}
              </Box>
            }
          />
          <CardContent>
            {(props.misReports.length === 0) ? (
              !props.isDataLoaded ? (
                <IconButton aria-label="Request data" size="large" onClick={handleDataRequest} disabled={(triggerToDateError || triggerFromDateError)}>
                  <StorageIcon fontSize="large"></StorageIcon>
                  Request data
                </IconButton>
              ) :
                (<Box className={classes.noDataText}>
                  <Typography>No data for selection</Typography>
                </Box>)
            ) : (
              <IPivotTableUI
                moduleName="MasterReport"
                data={props.misReports}
                saveBookmarks={setBookmarkData}
              />
            )}
          </CardContent>
        </Card>
        {
          <IDialog
            title={"Master Report Bookmarks"}
            open={bookmarksDialogOpen}
            fullScreen={false}
            onClose={handleDialogClose}
            content={
              <Box>
                <DialogContentText>
                  <MUIDataTable
                    options={{
                      selectableRows: "none",
                      filterType: "dropdown",
                      responsive: "simple",
                      filter: false,
                      download: false,
                      print: false,
                      viewColumns: false,
                      rowsPerPage: 5,
                      rowsPerPageOptions: [5, 10, 15, 100],
                      onRowClick: (rowData, rowMeta) => {
                        props.setPivottableFilters(rowData[4]);
                        setBookmarksDialogOpen(false);
                      },
                    }}
                    columns={[
                      {
                        name: "Id",
                        label: "Bookmark Id",
                        options: {
                          filter: false,
                          display: false,
                          sort: false,
                        },
                      },
                      {
                        name: "BookmarkName",
                        label: "Bookmark Name",
                        options: {
                          filter: true,
                          sort: true,
                          customBodyRender: (value) => {
                            return (
                              <Tooltip title="Select bookmark" aria-label="bookmark-select">
                                <span className={classes.bookmarkName}>{value}</span>
                              </Tooltip>
                            );
                          },
                        },
                      },
                      {
                        name: "CreatedDate",
                        label: "Created Date",
                        options: {
                          filter: false,
                          display: true,
                          sort: true,
                          customBodyRender: (value) => FormatUtils.FormatToPrettyDate(value),
                        },
                      },
                      {
                        name: "Actions",
                        label: false,
                        options: {
                          filter: false,
                          sort: false,
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                              <Tooltip title="Remove bookmark" aria-label="bookmark-remove">
                                <IconButton
                                  className={classes.actionIcon}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    const bookmarkId = tableMeta?.rowData[0];
                                    deleteBookmark(bookmarkId);
                                    setBookmarksDialogOpen(true);
                                  }}
                                >
                                  <RemoveCircleIcon color="secondary" />
                                </IconButton>
                              </Tooltip>
                            );
                          },
                        },
                      },
                      {
                        name: "BookmarkData",
                        label: "Bookmark Data",
                        options: {
                          filter: false,
                          sort: false,
                          display: false,
                          viewColumns: false,
                        },
                      }
                    ]}
                    data={savedBookmarksData}
                  ></MUIDataTable>

                  <br></br>
                  <br></br>
                  To bookmark current selection, enter a unique name below and click save.
                  <TextField
                    autoFocus
                    margin="dense"
                    id="bookmarkName"
                    label="Bookmark Name"
                    value={bookmarkName}
                    onChange={(event) => setBookmarkName(event.currentTarget.value)}
                    type="text"
                    fullWidth>
                  </TextField>
                </DialogContentText>
                <DialogActions>
                  <Button onClick={saveBookmark} color="primary"
                    variant="contained" disabled={!(bookmarkName.length > 0)}>
                    Save
                  </Button>
                </DialogActions>
              </Box>
            }
          ></IDialog>}
      </Grid >
    </Grid >
  )
}

const mapStateToProps = (state) => {
  return {
    misReports: state.misReportsReducer.misReports,
    isDataLoaded: state.misReportsReducer.isDataLoaded,
    pivottableFilters: state.misReportsReducer.pivottableFilters
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMISReports: (payload) => dispatch(setMISReports(payload)),
    setIsDataLoaded: (payload) => dispatch(setIsDataLoaded(payload)),
    setPivottableFilters: (payload) => dispatch(setPivottableFilters(payload)),
    setSnackBar: (payload) => dispatch(setSnackBar(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(MasterMISReport)));
