import HttpUtils from "./httpUtils";
import config from "./../config/config";

const fetchTriggers = async (triggerType) => {
  const data = await HttpUtils.Get(
    `${config.apiUrl}/maintenance/triggers/${triggerType}`
  );
  return data?.data;
};

const fetchTriggerConfiguration = async (triggerId) => {
  const data = await HttpUtils.Get(
    `${config.apiUrl}/maintenance/triggerconfig/${triggerId}`
  );
  return data?.data;
};

const putTriggerConfiguration = async (triggerId, triggerConfiguration) => {
  const data = await HttpUtils.Put(
    `${config.apiUrl}/maintenance/triggerconfig/${triggerId}`,
    triggerConfiguration
  );
  return data?.data;
};

const rearrangeTriggerLimits = (
  triggerConfigurationId,
  limitArray,
  newData
) => {
  //check if new limit overlaps with other colors
  const lowerLimitOverlap = limitArray.find(
    (item) =>
      newData.LowerLimit >= item.LowerLimit &&
      newData.LowerLimit <= item.UpperLimit &&
      triggerConfigurationId !== item.TriggerConfigurationId
  );

  const upperLimitOverlap = limitArray.find(
    (item) =>
      newData.UpperLimit >= item.LowerLimit &&
      newData.UpperLimit <= item.UpperLimit &&
      triggerConfigurationId !== item.TriggerConfigurationId
  );

  limitArray = limitArray.map((item) => {
    return item.TriggerConfigurationId === newData.TriggerConfigurationId
      ? newData
      : item;
  });

  if (lowerLimitOverlap) {
    const newLimits = limitArray.map((item) => {
      if (
        item.TriggerConfigurationId === lowerLimitOverlap.TriggerConfigurationId
      ) {
        return {
          ...item,
          UpperLimit: newData.LowerLimit - 0.01,
        };
      }
      return item;
    });

    return newLimits;
  }

  if (upperLimitOverlap) {
    const newLimits = limitArray.map((item) => {
      if (
        item.TriggerConfigurationId === upperLimitOverlap.TriggerConfigurationId
      ) {
        return {
          ...item,
          LowerLimit: newData.UpperLimit + 0.01,
        };
      }
      return item;
    });

    return newLimits;
  }

  return limitArray;
};

const ConfigurationUtils = {
  FetchTriggerConfiguration: fetchTriggerConfiguration,
  FetchTriggers: fetchTriggers,
  UpdateTriggerConfiguration: putTriggerConfiguration,
  RearrangeTriggerLimits: rearrangeTriggerLimits,
};

export default ConfigurationUtils;
