import HttpUtils from "./httpUtils";
import config from "./../config/config";
import FormatUtils from "./formatUtils";

const fetchAccountData = async (id) => {
  const data = await HttpUtils.Get(`${config.apiUrl}/account/${id}`);
  return data?.data;
};

const fetchAccountTriggers = async (accountNumber, currentDate) => {
  const data = await HttpUtils.Get(`${config.apiUrl}/review/${accountNumber}/${FormatUtils.FormatToPrettyDate(currentDate)}`);
  return data?.data;
};

const mapLoanAccounts = (data) => {
  const mapped = data?.map((i) => ({
    LoanId: i.LoanId,
    BorrowerId: i.FkBorrowerId,
    BorrowerName: i.BorrowersData.BorrowerName,
    RMName: i.BorrowersData.RMName,
    AmountApproved: i.AmountApproved,
    AmountDisbursed: i.AmountDisbursed,
    Segment: i.Segment,
    Interest: i.Interest,
    LoanType: i.LoanType,
    RFA: i.RFA,
  }));
  return mapped;
};

const fetchPendingAlerts = async (currentDate) => {
  const data = await HttpUtils.Get(`${config.apiUrl}/account/pending/${FormatUtils.FormatToPrettyDate(currentDate)}`);
  return data?.data;
};

const getCurrentUserEmail = (props) => {
  return props?.EmailAddress;
};

const AccountUtils = {
  FetchAccountData: fetchAccountData,
  FetchAccountTriggers: fetchAccountTriggers,
  MapLoanAccounts: mapLoanAccounts,
  FetchPendingAlerts: fetchPendingAlerts,
  GetCurrentUserEmail: getCurrentUserEmail
};

export default AccountUtils;
