import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import AccountSelect from "../components/review/AccountSelect";
import SwitchCameraIcon from "@material-ui/icons/SwitchCamera";
import { withRouter } from "react-router";
import { setAccountReviewHistory, setAlertClosureHistory, setNPAMovement } from "../actions/reviewActions";
import ReviewUtils from "../utils/reviewUtils";
import { Button } from "@material-ui/core";
import History from "./../components/reviewhistory/History";
import { setAccountTriggers, setSelectedAccount } from "../actions/accountActions";
import { setOrganizationRoles } from "../actions/adminActions";
import AdminUtils from "../utils/adminUtils";

let styles = (theme) => ({
  root: {},
  switch: {
    // padding: theme.spacing(1),
    textAlign: `right`,
    cursor: `pointer`,
    paddingTop: theme.spacing(2),
  },
  switchIcon: {
    fontSize: theme.spacing(3),
    color: `red`,
  },
  button: {
    // margin: theme.spacing(1),
  },
});

const ReviewHistory = (props) => {
  const onLoad = () => {
    props.setAccountReviewHistory([]);
  };

  useEffect(onLoad, []);

  const fetchAccountReviewHistory = (accountNumber) => {
    ReviewUtils.FetchAccountReviewHistory(accountNumber).then((data) => {
      props.setAccountReviewHistory(data);
      ReviewUtils.FetchAlertClosureHistory(accountNumber, new Date()).then((data) => {
        props.setAlertClosureHistory(data);
        ReviewUtils.FetchNPAMovement(accountNumber, new Date()).then((data) => {
          props.setNPAMovement(data);
        });
      });
    });

    AdminUtils.FetchOrganizationRoles().then((data) => {
      props.setOrganizationRoles(data);
    });
  };

  const handleAccountChange = (value) => {
    props.setAccountTriggers("");
    props.setSelectedAccount(value);

    const accountNumber = value.value;
    fetchAccountReviewHistory(accountNumber);
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <AccountSelect onChange={handleAccountChange} />
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}></Grid>
        {/* <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className={classes.switch}>
            <Button variant="contained" color="secondary" className={classes.button} onClick={showReview} startIcon={<SwitchCameraIcon />}>
              Switch to Review Mode
            </Button>
          </div>
        </Grid> */}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <History />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedAccount: state.accountReducer.selectedAccount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedAccount: (payload) => dispatch(setSelectedAccount(payload)),
    setAccountReviewHistory: (payload) => dispatch(setAccountReviewHistory(payload)),
    setAccountTriggers: (payload) => dispatch(setAccountTriggers(payload)),
    setAlertClosureHistory: (payload) => dispatch(setAlertClosureHistory(payload)),
    setOrganizationRoles: (payload) => dispatch(setOrganizationRoles(payload)),
    setNPAMovement: (payload) => dispatch(setNPAMovement(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ReviewHistory)));
