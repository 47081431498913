import React from "react";
import { connect } from "react-redux";
import { withStyles, Card, Button, Tooltip } from "@material-ui/core";
import Spinner from "../common/Spinner";
import MUIDataTable from "mui-datatables";
import Criticality from "../dashboard/Criticality";
import FormatUtils from "../../utils/formatUtils";
import UIUtils from "./../../utils/uiUtils";
import HelperUtils from "./../../utils/helperUtils";

let styles = (theme) => ({
  root: {},
  triggerCard: {
    // minHeight: `80vh`,
  },
  tableHeading: {
    fontSize: theme.spacing(3),
    float: `left`,
  },
  colorDot: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: theme.spacing(3),
    marginRight: theme.spacing(1),
    borderRadius: `50%`,
    display: `inline-block`,
    verticalAlign: `bottom`,
  },
});

const AccountUnstructuredTriggers = (props) => {
  const { classes } = props;

  const options = {
    selectableRows: "none",
    responsive: "vertical",
    rowsPerPage: 10,
    rowsPerPageOptions: [],
  };

  const columns = [
    {
      name: "TriggerId",
      label: "TriggerId",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "TriggerName",
      label: "Trigger Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "Criticality",
      label: "Criticality",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => <Criticality value={value} />,
      },
    },
    {
      name: "Color",
      label: "Color",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <div className={classes.benchmark}>
            <Tooltip title={`${FormatUtils.ToTitleCase(value)}`} aria-label={`${value}`}>
              <Button
                className={classes.colorDot}
                style={{
                  backgroundColor: `${UIUtils.GetSelectedColor(value.toLowerCase())}`,
                }}
              />
            </Tooltip>
          </div>
        ),
      },
    },
  ];

  const formatUnstructuredTriggerData = (data) => {
    const result = (data || [])
      .filter((item) => item.Red > 0 || item.Amber > 0)
      ?.map((item) => {
        return {
          ...item,
          Color: item.Red > 0 ? "Red" : item.Amber > 0 ? "Amber" : "Green",
        };
      });
    return HelperUtils.SortArrayBy(result, "Color", "desc");
  };

  return (
    <div>
      <Card className={classes.triggerCard}>
        {props.unstructuredTriggers?.Triggers?.length === 0 && (
          <div className={classes.alignCenter}>
            <Spinner color={"red"}></Spinner>
          </div>
        )}
        {props.unstructuredTriggers?.Triggers?.length > 0 && (
          <MUIDataTable
            columns={columns}
            title={<div className={classes.tableHeading}>{`${props.selectedTriggerType?.name}`}</div>}
            data={formatUnstructuredTriggerData(props.unstructuredTriggers?.Triggers)}
            options={options}
            className={classes.root}
          ></MUIDataTable>
        )}
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    unstructuredTriggers: state.dashboardReducer.unstructuredTriggers,
    selectedTriggerType: state.triggerReducer.selectedTriggerType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountUnstructuredTriggers));
