export const SET_ANALYTICS = "SET_ANALYTICS";
export const setAnalytics = (payload) => ({
  type: SET_ANALYTICS,
  payload: payload,
});

export const SET_SELECTED_DIMENSION_COLOR = "SET_SELECTED_DIMENSION_COLOR";
export const setSelectedDimensionColor = (payload) => ({
  type: SET_SELECTED_DIMENSION_COLOR,
  payload: payload,
});

export const SET_DIMENSION_LOANS = "SET_DIMENSION_LOANS";
export const setDimensionLoans = (payload) => ({
  type: SET_DIMENSION_LOANS,
  payload: payload,
});

export const SET_BAR_CHART_SERIES = "SET_BAR_CHART_SERIES";
export const setBarChartSeries = (payload) => ({
  type: SET_BAR_CHART_SERIES,
  payload: payload,
});

export const SET_BAR_CHART_CATEGORIES = "SET_BAR_CHART_CATEGORIES";
export const setBarChartCategories = (payload) => ({
  type: SET_BAR_CHART_CATEGORIES,
  payload: payload,
});
