import React from "react";
import { connect } from "react-redux";
import { withStyles, Card, CardContent, Box, Typography, Table, TableHead, TableRow, TableCell } from "@material-ui/core";
import FormatUtils from "./../../utils/formatUtils";
import { TableBody } from "mui-datatables";

let styles = (theme) => ({
  root: {},
  riskScoreCard: {
    height: "auto",
    minHeight: "20vh",
    marginTop: theme.spacing(2),
  },
  heading: {
    fontWeight: "bold",
    fontSize: theme.spacing(1.5),
    paddingBottom: theme.spacing(1),
    color: "red",
    float: "left",
  },
  topSection: {
    background: `#d17474`,
    color: `#fff`,
    borderBottom: `1px solid #dedede`,
    padding: theme.spacing(0.5),
  },
  username: {
    padding: theme.spacing(1),
    fontWeight: "bold",
  },
  tableCell: {
    padding: "0px 6px 0px 6px",

  },
});

const RiskScores = (props) => {
  const { classes } = props;
  return (
    props.riskScores?.length > 0 && (
      <div>
        <Card className={classes.riskScoreCard}>
          <CardContent className={classes.topSection}>
            <div className={classes.username}>Risk Profile</div>
          </CardContent>
          <CardContent>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <b>Risk Model</b>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <b>Score</b>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Box color="red"><b>Distress Zone</b></Box>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Box color="#FFC200"><b>Grey Zone</b></Box>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Box color="green"><b>Safe Zone</b></Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              {props.riskScores.map((item) => (
                <TableRow>
                  <TableCell padding="none" className={classes.tableCell}>
                    <b>{item.Description}</b>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Box color={(item.Color === "Red") ? "Red" : (item.Color === "Amber") ?
                      "#FFC200" : (item.Color === "Green") ?
                        "Green" : "black"}
                    ><Typography>{FormatUtils.FormatNumberToCurrency(item["Value"]) || "-"}</Typography></Box>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {item.DistressZone}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {item.GreyZone}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {item.SafeZone}
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </CardContent>
        </Card>
      </div >
    )
  );
};

const mapStateToProps = (state) => {
  return {
    accountData: state.accountReducer.accountData,
    riskScores: state.riskReducer.riskScores,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RiskScores));
