import { SET_BOOKMARK } from "../actions/bookmarkActions";
import { SET_USER_BOOKMARKS } from "./../actions/bookmarkActions";

const initialState = {
  bookmark: "",
  userBookmarks: [],
};

const bookmarkReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BOOKMARK:
      return {
        ...state,
        bookmark: action.payload,
      };
    case SET_USER_BOOKMARKS:
      return {
        ...state,
        userBookmarks: [...action.payload],
      };
    default:
      return state;
  }
};

export default bookmarkReducer;
