import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import ReviewUtils from "../../utils/reviewUtils";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router";
import ILineChart from "../common/ILineChart";
import FormatUtils from "../../utils/formatUtils";

let styles = (theme) => ({});

const RiskMovement = (props) => {
  const getKeys = () => {
    const keys = Object.keys(props.npaMovement?.Movement || {});
    return keys.sort();
  };

  const mapDataToCategories = () => {
    if (Object.keys(props.npaMovement?.Movement || {})?.length > 0) {
      const keys = getKeys()?.map((i) => FormatUtils.FormatToPrettyDate(i));
      return keys;
    }
    return [];
  };

  const mapDataToSeries = () => {
    if (Object.keys(props.npaMovement?.Movement || {})?.length < 1) {
      return null;
    }
    const series = [];
    const types = ["FinancialNPA", "NonFinancialNPA", "MovingNPA", "PotentialNPA"];
    types.forEach((type) => {
      const data = [];
      getKeys().forEach((k) => {
        const dataObj = {
          y: props.npaMovement?.Movement?.[k]?.NPA?.[0]?.[type],
          structured: props.npaMovement?.Movement?.[k]?.TriggerCount?.find((i) => i.TriggerType === "Structured")?.AlertCount ?? 0,
          unstructured: props.npaMovement?.Movement?.[k]?.TriggerCount?.find((i) => i.TriggerType === "Unstructured")?.AlertCount ?? 0,
          llms: props.npaMovement?.Movement?.[k]?.TriggerCount?.find((i) => i.TriggerType === "LLMS")?.AlertCount ?? 0,
          alertcount: props.npaMovement?.Movement?.[k]?.TriggerCount?.reduce((a, b) => +a + +b.AlertCount, 0) ?? 0,
        };

        data.push(dataObj);
      });

      series.push({
        name: type,
        stack: type,
        data: data,
      });
    });
    return series;
  };
  return <ILineChart categories={mapDataToCategories()} series={mapDataToSeries()} />;
};

const mapStateToProps = (state) => {
  return {
    selectedAccount: state.accountReducer.selectedAccount,
    currentDate: state.dashboardReducer.currentDate,
    npaMovement: state.reviewReducer.npaMovement,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(RiskMovement)));
