import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const options = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
    height: 320,
  },
  tooltip: {
    formatter: function () {
      return this.point.name + " : <b>" + this.y + "</b>";
    },
    style: {
      font: "11px Montserrat, OpenSans, Helvetica, Arial, sans-serif",
      color: "#5a5a5a",
      verticalalign: "top",
    },
  },
  title: false,
  credits: false,
  plotOptions: {
    pie: {
      allowPointSelect: true,
      innerSize: "60%",
      cursor: "pointer",
      showInLegend: false,
      dataLabels: {
        enabled: true,
        color: "#5a5a5a",
        connectorColor: "#5a5a5a",
        format: "{point.name} - {point.y}",
        style: {
          font: "11px Montserrat, OpenSans, Helvetica, Arial, sans-serif",
          color: "#5a5a5a",
          verticalalign: "top",
        },
      },
      point: {
        events: {
          select: (e) => {},
          unselect: (e) => {},
        },
      },
    },
  },
  series: [
    {
      type: "pie",
      dataSorting: {
        enabled: true,
      },
      data: [],
    },
  ],
};

const IPieChart = (props) => {
  const { data, onPointSelect, onPointRelease } = props;

  const handlePointSelect = (e) => {
    onPointSelect(e);
  };

  const handlePointRelease = (e) => {
    onPointRelease(e);
  };

  const mapOptions = () => {
    const mappedOptions = { ...options };
    mappedOptions.series[0].data = data;

    if (onPointSelect)
      mappedOptions.plotOptions.pie.point.events.select = handlePointSelect;

    if (onPointRelease)
      mappedOptions.plotOptions.pie.point.events.unselect = handlePointRelease;

    return mappedOptions;
  };

  return (
    <div>
      {props.data && props.data.length > 0 && (
        <HighchartsReact highcharts={Highcharts} options={mapOptions()} />
      )}
    </div>
  );
};

export default IPieChart;
