import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import ReviewCard from "./ReviewCard";
import { connect } from "react-redux";
import HelperUtils from "./../../utils/helperUtils";
import {
  setAlertClosureHistory,
  setFinishEnabled,
  setOverallCommentsHistory,
  setReviewActions,
  setReviewHistory,
  setReviewRemarksHistory,
  setSelectedAlertRework,
  setTriggerDate,
} from "../../actions/reviewActions";
import { setSnackBar } from "../../actions/commonActions";
import ReviewUtils from "./../../utils/reviewUtils";
import { setAccountTriggers, setSelectedAccount } from "../../actions/accountActions";
import { setCurrentDate } from "../../actions/dashboardActions";
import IDialog from "../common/IDialog";
import { useState } from "react";
import AlertRework from "../reviewhistory/AlertRework";
import AccountUtils from "../../utils/accountUtils";

let styles = (theme) => ({
  root: {},
});

const ReviewSection = (props) => {
  const { data, onActionComplete } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const getSuggestiveActionOptions = (triggerId) => {
    const actions = props.suggestiveActions?.filter((item) => item.TriggerId === triggerId);
    return HelperUtils.MapOptions(actions, "SuggestiveAction", "SuggestiveActionId");
  };

  const handleActionChange = (obj) => {
    const newReviewActions = { ...props.reviewActions };
    newReviewActions[obj.TriggerId] = obj;
    props.setReviewActions(newReviewActions);
    const isActionsEmpty = HelperUtils.IsEmpty(newReviewActions);
    props.setFinishEnabled(!isActionsEmpty);
  };

  const handleRemarksChange = (obj, value) => {
    const newReviewActions = { ...props.reviewActions };
    if (newReviewActions[obj.TriggerId]) {
      newReviewActions[obj.TriggerId].Remarks = value;
      props.setReviewActions(newReviewActions);
    } else {
      newReviewActions[obj.TriggerId] = {};
      newReviewActions[obj.TriggerId].Remarks = value;
      props.setReviewActions(newReviewActions);
    }
  };

  const mapReviewHistory = (item) => {
    const { reviewHistory, suggestiveActions } = props;
    const filteredActions = suggestiveActions.filter((i) => i.TriggerId === item.TriggerId);
    const intersection = HelperUtils.IntersectionBy(reviewHistory, filteredActions, "SuggestiveActionId");

    const mappedHistory = intersection?.map((item) => {
      const suggestiveAction = filteredActions.find((i) => i.SuggestiveActionId === item.SuggestiveActionId);
      return {
        ...item,
        SuggestiveAction: suggestiveAction?.SuggestiveAction,
      };
    });
    return HelperUtils.SortArrayBy(mappedHistory, "ReviewDate", "desc");
  };

  const mapRemarksHistory = (item) => {
    const { reviewRemarksHistory } = props;
    const filteredRemarks = reviewRemarksHistory.filter((i) => i.TriggerId === item.TriggerId);

    return HelperUtils.SortArrayBy(filteredRemarks, "ModifiedDate", "desc");
  };

  const getRemarks = (item) => {
    return props.reviewActions[item.TriggerId]?.Remarks;
  };

  const getAlertClosure = (item) => {
    const emailAddress = props.user?.User?.EmailAddress || props.user?.EmailAddress;
    const closedItem = props.alertClosureHistory.filter((i) => item.TriggerId === i.TriggerId && i.CreatedBy === emailAddress && i.IsActive);
    return closedItem;
  };

  const handleCloseAlert = (item) => {
    const remarks = getRemarks(item);
    // if (!remarks) {
    //   props.setSnackBar("Please fill remarks for closing alert");
    //   return;
    // }

    const timestamp = new Date();
    const currentUser = props.user?.User?.EmailAddress || props.user?.EmailAddress;
    const accountNumber = props.selectedAccount.value;
    const isController = props.roles?.some((i) => i.OrganizationRoles.IsController);

    const payload = {
      ReviewClosures: [],
    };

    const closeItem = {
      ...item,
      TriggerDate: props.triggerDate,
      Remarks: remarks ?? "",
      AccountNumber: accountNumber,
      CreatedBy: currentUser,
      CreatedDate: timestamp,
      ModifiedBy: currentUser,
      ModifiedDate: timestamp,
      SuggestiveAction: null,
      IsFinalClosed: isController,
    };

    payload.ReviewClosures.push(closeItem);
    ReviewUtils.CloseAlert(accountNumber, payload).then((data) => {
      props.setAccountTriggers(data.Triggers.Triggers);
      props.setReviewHistory(data.UserReviews);
      props.setReviewRemarksHistory(data.UserReviewRemarks);
      props.setOverallCommentsHistory(data.UserReviewComments);
      props.setAlertClosureHistory(data.UserReviewClosures);
      props.setTriggerDate(data.TriggerDate);
      props.setSnackBar("Alert closed successfully");
    });
  };

  const isAlertClosed = (item) => {
    return getAlertClosure(item)?.length > 0;
  };

  const canCloseAlert = (item) => {
    const hasSuggestiveActions = props?.reviewHistory?.some((i) => i?.SuggestiveAction?.TriggerId === item.TriggerId);
    if (!hasSuggestiveActions) {
      return false;
    }
    const isFinalClosed = props.alertClosureHistory?.filter((i) => item.TriggerId === i.TriggerId && i.IsActive && i.IsFinalClosed);
    if (isFinalClosed?.length > 0) {
      return false;
    }

    const isInitiator = props.roles?.some((i) => i?.OrganizationRoles?.IsInitiator);
    if (isInitiator) {
      return true;
    }

    const emailAddress = props.user?.User?.EmailAddress || props.user?.EmailAddress;
    const closedItem = props.alertClosureHistory.filter((i) => item.TriggerId === i.TriggerId && i.CreatedBy === emailAddress && i.IsActive);
    if (closedItem?.length > 0) {
      return false;
    }

    return props.alertClosureHistory?.some((i) => item.TriggerId === i.TriggerId && i.IsActive);
  };

  const canReopen = (item) => {
    const isInitiator = props.roles?.some((i) => i?.OrganizationRoles?.IsInitiator);
    if (isInitiator) {
      return false;
    }

    return canCloseAlert(item);
  };

  const handleReopen = (item) => {
    setDialogOpen(true);
    const closedAlert = props?.alertClosureHistory?.find((i) => i.TriggerId === item.TriggerId);
    if (closedAlert) {
      const formattedAlert = [
        closedAlert.UserReviewClosureId,
        closedAlert.TriggerId,
        item.TriggerName,
        closedAlert.TriggerDate,
        closedAlert.Remarks,
        closedAlert.ModifiedBy,
        closedAlert.ModifiedDate,
        closedAlert.IsActive,
      ];
      props.setSelectedAlertRework(formattedAlert);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleReworkSubmit = (payload) => {
    ReviewUtils.ReopenAlert(payload).then((data) => {
      setDialogOpen(false);
      props.setSnackBar("Alert has been re-assigned successfully");
    });
  };

  return (
    <Grid container spacing={3}>
      {data?.map((item) => {
        if (isAlertClosed(item)) {
          return "";
        }
        return (
          <Grid item lg={3} md={4} sm={12} xs={12} key={item.TriggerId}>
            <ReviewCard
              data={item}
              actionOptions={getSuggestiveActionOptions(item.TriggerId)}
              onChange={handleActionChange}
              onRemarksChange={handleRemarksChange}
              remarks={getRemarks(item)}
              selectedActions={props.reviewActions[item.TriggerId]?.SuggestiveActions}
              reviewHistory={mapReviewHistory(item)}
              remarksHistory={mapRemarksHistory(item)}
              onActionComplete={onActionComplete}
              onClose={handleCloseAlert}
              closureHistory={getAlertClosure(item)}
              canCloseAlert={canCloseAlert(item)}
              canReopen={canReopen(item)}
              handleReopen={() => handleReopen(item)}
              enableSelect={props.canAddReviews}
            />
          </Grid>
        );
      })}
      <IDialog
        title={<div>Reopen alert</div>}
        fullScreen={false}
        open={dialogOpen}
        content={<AlertRework onSubmit={handleReworkSubmit} />}
        onClose={handleDialogClose}
      ></IDialog>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    suggestiveActions: state.reviewReducer.suggestiveActions,
    selectedAccount: state.accountReducer.selectedAccount,
    currentDate: state.dashboardReducer.currentDate,
    user: state.authReducer.user,
    roles: state.authReducer.roles,
    triggerDate: state.reviewReducer.triggerDate,
    reviewActions: state.reviewReducer.reviewActions,
    reviewHistory: state.reviewReducer.reviewHistory,
    reviewRemarksHistory: state.reviewReducer.reviewRemarksHistory,
    alertClosureHistory: state.reviewReducer.alertClosureHistory,
    canAddReviews: state.authReducer.canAddReviews,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setReviewActions: (payload) => dispatch(setReviewActions(payload)),
    setReviewHistory: (payload) => dispatch(setReviewHistory(payload)),
    setFinishEnabled: (payload) => dispatch(setFinishEnabled(payload)),
    setSnackBar: (payload) => dispatch(setSnackBar(payload)),
    setAccountTriggers: (payload) => dispatch(setAccountTriggers(payload)),
    setSelectedAccount: (payload) => dispatch(setSelectedAccount(payload)),
    setCurrentDate: (payload) => dispatch(setCurrentDate(payload)),
    setTriggerDate: (payload) => dispatch(setTriggerDate(payload)),
    setReviewRemarksHistory: (payload) => dispatch(setReviewRemarksHistory(payload)),
    setOverallCommentsHistory: (payload) => dispatch(setOverallCommentsHistory(payload)),
    setAlertClosureHistory: (payload) => dispatch(setAlertClosureHistory(payload)),
    setSelectedAlertRework: (payload) => dispatch(setSelectedAlertRework(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReviewSection));
