import {
  SET_ANALYTICS,
  SET_BAR_CHART_CATEGORIES,
  SET_BAR_CHART_SERIES,
  SET_DIMENSION_LOANS,
  SET_SELECTED_DIMENSION_COLOR,
} from "./../actions/analyticsActions";

const initialState = {
  analytics: [],
  selectedDimensionColor: "",
  dimensionLoans: [],
  barChartSeries: [],
  barChartCategories: [],
};

const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ANALYTICS:
      return {
        ...state,
        analytics: [...action.payload],
      };
    case SET_SELECTED_DIMENSION_COLOR:
      return {
        ...state,
        selectedDimensionColor: action.payload,
      };
    case SET_DIMENSION_LOANS:
      return {
        ...state,
        dimensionLoans: [...action.payload],
      };
    case SET_BAR_CHART_SERIES:
      return {
        ...state,
        barChartSeries: [...action.payload],
      };
    case SET_BAR_CHART_CATEGORIES:
      return {
        ...state,
        barChartCategories: [...action.payload],
      };
    default:
      return state;
  }
};

export default analyticsReducer;
