import React from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { withStyles } from "@material-ui/core";

let styles = (theme) => ({
  root: {
    width: `100%`,
  },
});

const IDatePicker = (props) => {
  const { label, value, onChange, format, minDate, maxDate, classes, error } = props;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoOk
        variant="inline"
        label={label}
        format={format || "dd/MM/yyyy"}
        value={value}
        onChange={(date) => onChange(date)}
        minDate={minDate}
        maxDate={maxDate}
        className={classes.root}
        error={error}
        helperText={error? "Invalid date" : ""}
      />
    </MuiPickersUtilsProvider>
  );
};

export default withStyles(styles)(IDatePicker);
