import { TextField, withStyles } from "@material-ui/core";
import React from "react";

let styles = (theme) => ({
  root: {
    "& .MuiInputBase-input": {
      height: theme.spacing(1.5),
      fontSize: theme.spacing(1.5),
    },
    "& .MuiFormLabel-root": {
      fontSize: theme.spacing(1.5),
    },
    paddingBottom: theme.spacing(0.5),
    display: "grid",
  },
});

const defaultProps = {
  label: "Label",
  id: "textfield-basic",
  variant: "standard",
};

const ITextField = (props) => {
  const { classes, label, placeholder, id, variant, defaultValue, value, ...rest } = props;
  return (
    <TextField
      className={classes.root}
      id={id ?? defaultProps.id}
      label={label ?? defaultProps.label}
      variant={variant || defaultProps.variant}
      placeholder={placeholder ?? defaultProps.placeholder}
      defaultValue={value}
      multiline
      {...rest}
    ></TextField>
  );
};

export default withStyles(styles)(ITextField);
