import React from "react";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import Spinner from "./../common/Spinner";
import MUIDataTable from "mui-datatables";
import TriggeredLoans from "./TriggeredLoans";
import { setSelectedTrigger } from "../../actions/triggerActions";
import RiskGradeBenchmark from "./RiskGradeBenchmark";
import BreadCrumbs from "./BreadCrumbs";
import FormatUtils from "./../../utils/formatUtils";

let styles = (theme) => ({
  root: {
    "& .MuiTypography-h6": {
      textAlign: `left`,
    },
    "& .MUIDataTableToolbar-actions-36": {
      textAlign: `right`,
    },
  },
  benchmark: {
    minWidth: theme.spacing(10),
    width: theme.spacing(10),
  },
  tableHeading: {
    fontSize: theme.spacing(3),
    float: `left`,
  },
});

const LLMSTriggers = (props) => {
  const { classes } = props;
  const columns = [
    {
      name: "TriggerId",
      label: "TriggerId",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "Dimension",
      label: "Dimension",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "TriggerName",
      label: "Trigger Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "Description",
      label: "Trigger Description",
      options: {
        filter: false,
        sort: true,
      },
    },
    // {
    //   name: "Criticality",
    //   label: "Criticality",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     customBodyRender: (value) => <Criticality value={value} />,
    //   },
    // },
    {
      name: "Red",
      label: "Red",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (
          <div className={classes.benchmark}>
            <RiskGradeBenchmark value={value} color="red" />
          </div>
        ),
      },
    },
    {
      name: "Green",
      label: "Green",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (
          <div className={classes.benchmark}>
            <RiskGradeBenchmark value={value} color="green" />
          </div>
        ),
      },
    },
  ];

  const options = {
    selectableRows: "none",
    responsive: "vertical",
    onRowClick: (rowData, rowState) => {
      props.setSelectedTrigger({
        id: rowData[0],
        dimension: rowData[1],
        name: rowData[2],
      });
    },
    downloadOptions: {
      filename: FormatUtils.GetCSVFileName("LLMS-Triggers"),
      separator: ",",
    },
    rowsPerPageOptions: [10, 15, 100, 1000, 10000],
  };

  return (
    <div>
      {props.llmsTriggers?.Triggers?.length === 0 && (
        <div className={classes.alignCenter}>
          <Spinner color={null}></Spinner>
        </div>
      )}
      {!props.selectedTriggerColor && props.llmsTriggers?.Triggers?.length > 0 && (
        <MUIDataTable
          columns={columns}
          title={<div className={classes.tableHeading}>{`${props.selectedTriggerType?.name}`}</div>}
          data={props.llmsTriggers?.Triggers}
          options={options}
          className={classes.root}
        ></MUIDataTable>
      )}
      {props.selectedTriggerColor && (
        <div>
          <BreadCrumbs />
          <TriggeredLoans className={classes.popupDialog}></TriggeredLoans>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    llmsTriggers: state.dashboardReducer.llmsTriggers,
    selectedTriggerColor: state.triggerReducer.selectedTriggerColor,
    selectedTriggerType: state.triggerReducer.selectedTriggerType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedTrigger: (payload) => dispatch(setSelectedTrigger(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LLMSTriggers));
