import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withStyles, Card, CardContent, Grid, TextField, Button } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LockIcon from "@material-ui/icons/Lock";
import AuthUtils from "../utils/authUtils";
import { setCanAddReviews, setRoles, setUser } from "../actions/authActions";
import { setSnackBar } from "../actions/commonActions";

let styles = (theme) => ({
  loginForm: {
    width: `100%`,
    maxWidth: theme.spacing(35),
    margin: `auto`,
  },
  button: {
    float: "right",
    margin: theme.spacing(1),
    right: theme.spacing(3),
    width: `100%`,
  },
  dropdown: {
    marginTop: theme.spacing(1),
    width: `100%`,
  },
});

const Login = (props) => {
  const { classes, user } = props;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const validateInput = () => {
    if (!username) {
      props.setSnackBar("Username is required");
      return false;
    }

    if (!password) {
      props.setSnackBar("Password is required");
      return false;
    }

    return true;
  };

  const handleLogin = async () => {
    if (!validateInput()) {
      return;
    }

    const user = await AuthUtils.Login(username, password);
    if (!user) {
      props.setSnackBar("Invalid credentials");
      return;
    }
    props.setUser(user?.User);
    props.setRoles(user?.UserRoles);
    const canAddReviews = user?.UserRoles?.some((i) => i?.OrganizationRoles?.CanAddReviews);
    props.setCanAddReviews(canAddReviews);
  };

  const redirectAfterLogin = () => {
    if (props.user) props.history.push("/dashboard");
  };

  useEffect(redirectAfterLogin, [user]);

  return (
    <Card className={classes.loginForm}>
      <CardContent>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={12}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item className={classes.dropdown}></Grid>
            </Grid>

            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={2}>
                <AccountCircle />
              </Grid>
              <Grid item xs={10}>
                <TextField id="input-with-icon-grid" label="Username" onChange={handleUsernameChange} />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={2}>
                <LockIcon />
              </Grid>
              <Grid item xs={10}>
                <TextField id="password-with-icon-grid" type="password" label="Password" onChange={handleChangePassword} />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item className={classes.button}>
                <Button onClick={handleLogin} variant="contained" color="primary">
                  Login
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    roles: state.authReducer.roles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (payload) => dispatch(setUser(payload)),
    setRoles: (payload) => dispatch(setRoles(payload)),
    setSnackBar: (payload) => dispatch(setSnackBar(payload)),
    setCanAddReviews: (payload) => dispatch(setCanAddReviews(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));
