export const SET_OVERVIEW = "SET_OVERVIEW";
export const setOverview = (payload) => ({
  type: SET_OVERVIEW,
  payload: payload,
});

export const SET_STRUCTURED_TRIGGERS = "SET_STRUCTURED_TRIGGERS";
export const setStructuredTriggers = (payload) => ({
  type: SET_STRUCTURED_TRIGGERS,
  payload: payload,
});

export const SET_UNSTRUCTURED_TRIGGERS = "SET_UNSTRUCTURED_TRIGGERS";
export const setUnstructuredTriggers = (payload) => ({
  type: SET_UNSTRUCTURED_TRIGGERS,
  payload: payload,
});

export const SET_LLMS_TRIGGERS = "SET_LLMS_TRIGGERS";
export const setLLMSTriggers = (payload) => ({
  type: SET_LLMS_TRIGGERS,
  payload: payload,
});

export const SET_CURRENT_DATE = "SET_CURRENT_DATE";
export const setCurrentDate = (payload) => ({
  type: SET_CURRENT_DATE,
  payload: payload,
});

export const SET_COMPARE_DATE = "SET_COMPARE_DATE";
export const setCompareDate = (payload) => ({
  type: SET_COMPARE_DATE,
  payload: payload,
});

export const SET_SELECTED_FILTERS = "SET_SELECTED_FILTERS";
export const setSelectedFilters = (payload) => ({
  type: SET_SELECTED_FILTERS,
  payload: payload,
});

export const SET_FILTER_DETAILS = "SET_FILTER_DETAILS";
export const setFilterDetails = (payload) => ({
  type: SET_FILTER_DETAILS,
  payload: payload,
});

export const SET_SELECTED_FILTER_ITEMS = "SET_SELECTED_FILTER_ITEMS";
export const setSelectedFilterItems = (payload) => ({
  type: SET_SELECTED_FILTER_ITEMS,
  payload: payload,
});

export const CLEAR_SELECTED_FILTERS = "CLEAR_SELECTED_FILTERS";
export const clearSelectedFilters = (payload) => ({
  type: CLEAR_SELECTED_FILTERS,
  payload: payload,
});

export const SET_SELECTED_FILTER_VALUES = "SET_SELECTED_FILTER_VALUES";
export const setSelectedFilterValues = (payload) => ({
  type: SET_SELECTED_FILTER_VALUES,
  payload: payload,
});

export const SET_FILTER_HIERARCHY = "SET_FILTER_HIERARCHY";
export const setFilterHierarchy = (payload) => ({
  type: SET_FILTER_HIERARCHY,
  payload: payload,
});

export const SET_NPA_LOANS = "SET_NPA_LOANS";
export const setNPALoans = (payload) => ({
  type: SET_NPA_LOANS,
  payload: payload,
});
