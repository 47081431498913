import { Tab, withStyles } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
// import { TabPanel } from "@material-ui/lab";
import React from "react";
import Typography from "@material-ui/core/Typography";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

let styles = (theme) => ({
  scrollable: {
    "& .MuiTabs-scrollButtonsDesktop": {
      display: "flex",
    },
  },
});

const IControlledTab = (props) => {
  const { tabs, value, onChange, classes } = props;
  // const [value, setValue] = React.useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const getTabs = () => {
    return tabs?.filter((i) => !i.hidden) ?? [];
  };

  return (
    <React.Fragment>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={onChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
          className={classes.scrollable}
        >
          {getTabs().map((tab) => {
            return <Tab key={tab.index} label={tab.label} {...a11yProps(tab.index)} />;
          })}
        </Tabs>
      </Box>
      {getTabs().map((tab) => {
        return (
          <TabPanel key={tab.index} value={value} hidden={tab.hidden} index={tab.index}>
            {tab.content}
          </TabPanel>
        );
      })}
    </React.Fragment>
  );
};

export default withStyles(styles)(IControlledTab);
