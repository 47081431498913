import { reactLocalStorage } from "reactjs-localstorage";
import HttpUtils from "./httpUtils";
import config from "./../config/config";

const login = async (username, password) => {
  let userObj = {
    Username: username,
    Password: password,
  };

  const data = await HttpUtils.Post(`${config.apiUrl}/auth/authenticate`, userObj);

  if (data?.data?.User?.UserId) {
    const user = data.data;
    reactLocalStorage.setObject("user", user);
    return user;
  }

  return false;
};

const getRoles = async () => {
  const data = await HttpUtils.Get(`${config.apiUrl}/auth/roles`);
  return data?.data;
};

const isAuthenticated = () => {
  const user = reactLocalStorage.get("user");
  if (!user) {
    return false;
  }
  return true;
};

const logout = () => {
  reactLocalStorage.remove("user");
};

const getUser = () => {
  return JSON.parse(reactLocalStorage.get("user"));
};

const AuthUtils = {
  Login: login,
  Logout: logout,
  IsAuthenticated: isAuthenticated,
  GetUser: getUser,
  GetRoles: getRoles,
};

export default AuthUtils;
