import React, { useEffect } from "react";
import { Grid, Card } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";

import TopLayer from "../components/dashboard/TopLayer";
import DashboardUtils from "./../utils/dashboardUtils";

import {
  setOverview,
  setCurrentDate,
  setCompareDate,
  setStructuredTriggers,
  setUnstructuredTriggers,
  setFilterDetails,
  clearSelectedFilters,
  setSelectedFilterValues,
  setLLMSTriggers,
  setFilterHierarchy,
} from "../actions/dashboardActions";
import StructuredTriggers from "../components/dashboard/StructuredTriggers";
import UnstructuredTriggers from "../components/dashboard/UnstructuredTriggers";
import OverallTriggers from "../components/dashboard/OverallTriggers";
import Filter from "../components/common/Filter";
import DataUtils from "../utils/dataUtils";
import TriggersTab from "../components/common/TriggersTab";
import { clearSelectedTriggers } from "../actions/triggerActions";
import LLMSTriggers from "../components/dashboard/LLMSTriggers";
import AccountUtils from "../utils/accountUtils";
import { setPendingAlerts } from "../actions/accountActions";

let styles = (theme) => ({
  button: {
    float: "left",
    margin: theme.spacing(1),
    width: `100%`,
  },
  filterButton: {
    float: "left",
    margin: theme.spacing(1),
    cursor: `pointer`,
  },
  alignLeft: {
    textAlign: "left",
  },
  loggedIn: {
    textAlign: "right",
    fontSize: theme.spacing(1.5),
    paddingBottom: theme.spacing(1),
  },
  menuIcon: {
    padding: theme.spacing(1),
    fontSize: theme.spacing(2),
  },
  bottom: {
    verticalAlign: `bottom`,
  },
  overallTriggersGroup: {
    minHeight: theme.spacing(8),
    padding: theme.spacing(1),
  },
  username: {
    color: `#2293e2`,
  },
});

const Dashboard = (props) => {
  const { classes } = props;

  const fetchOverview = () => {
    DashboardUtils.FetchOverview(
      props.compareDate || DataUtils.GetDefaultCompareDate(),
      props.currentDate || DataUtils.GetDefaultCurrentDate(),
      props.selectedFilterItems
    ).then((data) => {
      props.setOverview(data);
    });
  };

  const fetchStructuredTriggers = () => {
    DashboardUtils.FetchTriggers("Structured", props.currentDate || DataUtils.GetDefaultCurrentDate(), props.selectedFilterItems).then((data) => {
      props.setStructuredTriggers(data);
    });
  };

  const fetchUnstructuredTriggers = () => {
    DashboardUtils.FetchTriggers("Unstructured", props.currentDate || DataUtils.GetDefaultCurrentDate(), props.selectedFilterItems).then((data) => {
      props.setUnstructuredTriggers(data);
    });
  };

  const fetchLLMSTriggers = () => {
    DashboardUtils.FetchTriggers("LLMS", props.currentDate || DataUtils.GetDefaultCurrentDate(), props.selectedFilterItems).then((data) => {
      props.setLLMSTriggers(data);
    });
  };

  const fetchPendingAlerts = () => {
    AccountUtils.FetchPendingAlerts(props.currentDate || DataUtils.GetDefaultCurrentDate()).then((data) => {
      props.setPendingAlerts(data);
    });
  };

  const onFilter = () => {
    props.clearSelectedTriggers();
    fetchOverview();
    fetchStructuredTriggers();
    fetchUnstructuredTriggers();
    fetchLLMSTriggers();
    fetchPendingAlerts();
  };

  const onLoad = () => {
    DataUtils.FetchFilterOptions().then((options) => {
      props.setFilterHierarchy(options);
    });
  };

  //call on each filter change
  useEffect(onFilter, [props.selectedFilterValues, props.compareDate, props.currentDate]);

  //call on initial loading
  useEffect(onLoad, []);
  return (
    <React.Fragment>
      <div className={classes.loggedIn}>
        <span className={classes.username}>{props.user?.Username}</span> |<strong>{props.roles?.[0]?.OrganizationRoles?.DisplayName}</strong>
      </div>
      <Filter></Filter>

      <Grid container spacing={3}>
        <TopLayer></TopLayer>
      </Grid>
      <Grid container spacing={3}>
        <Grid item lg={9} md={8} sm={6} xs={12} className={classes.alignLeft}>
          <TriggersTab />
        </Grid>
        {!props.selectedColor && (
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.alignRight}>
            <Card className={`shadow-sm ${classes.overallTriggersGroup}`}>
              <OverallTriggers />
            </Card>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          {props.selectedTriggerType?.id === 1 && <StructuredTriggers></StructuredTriggers>}
          {props.selectedTriggerType?.id === 2 && <UnstructuredTriggers></UnstructuredTriggers>}
          {props.selectedTriggerType?.id === 3 && <LLMSTriggers></LLMSTriggers>}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    overview: state.dashboardReducer.overview,
    roles: state.authReducer.roles,
    compareDate: state.dashboardReducer.compareDate,
    currentDate: state.dashboardReducer.currentDate,
    selectedFilterItems: state.dashboardReducer.selectedFilterItems,
    filterDetails: state.dashboardReducer.filterDetails,
    selectedFilterValues: state.dashboardReducer.selectedFilterValues,
    selectedColor: state.triggerReducer.selectedTriggerColor,
    selectedTriggerType: state.triggerReducer.selectedTriggerType,
    user: state.authReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOverview: (payload) => dispatch(setOverview(payload)),
    setStructuredTriggers: (payload) => dispatch(setStructuredTriggers(payload)),
    setUnstructuredTriggers: (payload) => dispatch(setUnstructuredTriggers(payload)),
    setLLMSTriggers: (payload) => dispatch(setLLMSTriggers(payload)),
    setCurrentDate: (payload) => dispatch(setCurrentDate(payload)),
    setCompareDate: (payload) => dispatch(setCompareDate(payload)),
    setFilterDetails: (payload) => dispatch(setFilterDetails(payload)),
    clearSelectedFilters: (payload) => dispatch(clearSelectedFilters()),
    setSelectedFilterValues: (payload) => dispatch(setSelectedFilterValues(payload)),
    clearSelectedTriggers: (payload) => dispatch(clearSelectedTriggers()),
    setFilterHierarchy: (payload) => dispatch(setFilterHierarchy(payload)),
    setPendingAlerts: (payload) => dispatch(setPendingAlerts(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dashboard));
