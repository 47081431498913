import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import SuggestiveActions from "../components/review/SuggestiveActions";
import AccountSelect from "./../components/review/AccountSelect";
import AccountUtils from "../utils/accountUtils";
import { setAccountControllers, setAccountTriggers, setSelectedAccount } from "../actions/accountActions";
import { Tooltip } from "@material-ui/core";
import IDatePicker from "../components/common/IDatePicker";
import HelperUtils from "../utils/helperUtils";
import DataUtils from "./../utils/dataUtils";
import {
  setReviewActions,
  setReviewHistory,
  setReviewRemarksHistory,
  setOverallCommentsHistory,
  setTriggerDate,
  setAlertClosureHistory,
  setSuggestiveActions,
  setAccountReviewHistory,
  setNPAMovement,
  setAccountsWithPendingAlerts,
  setSelectedTabIndex,
  setAccountPendingAlerts,
  setClosedAlerts,
  setAlertsClosedBySubordinates,
  setIsAlertClosed,
  setReassignedAlerts,
  setUserReviewInitiators,
  setOverallComments,
} from "../actions/reviewActions";
import SwitchCameraIcon from "@material-ui/icons/SwitchCamera";
import { withRouter } from "react-router";
import { Button } from "@material-ui/core";
import { setLoanAccounts, setOrganizationRoles } from "../actions/adminActions";
import AdminUtils from "../utils/adminUtils";
import ITab from "../components/common/ITab";
import AccountReviewHistory from "../components/reviewhistory/AccountReviewHistory";
import AlertClosureHistory from "../components/reviewhistory/AlertClosureHistory";
import OpenAlerts from "../components/reviewhistory/OpenAlerts";
import RiskMovement from "../components/reviewhistory/RiskMovement";
import ReviewUtils from "../utils/reviewUtils";
import PendingAlerts from "../components/review/PendingAlerts";
import { useState } from "react";
import IControlledTab from "../components/common/IControlledTab";
import AccountPendingAlerts from "../components/review/AccountPendingAlerts";
import IDialog from "../components/common/IDialog";
import ClosedAlerts from "../components/review/ClosedAlerts";
import SubordinateClosures from "../components/review/SubordinateClosures";
import FormatUtils from "../utils/formatUtils";
import ReassignedAlerts from "../components/review/ReassignedAlerts";

let styles = (theme) => ({
  root: {},
  datepicker: {
    paddingTop: `${theme.spacing(2)}px !important`,
  },
  switch: {
    // padding: theme.spacing(1),
    textAlign: `right`,
    cursor: `pointer`,
    paddingTop: theme.spacing(2),
  },
  switchIcon: {
    fontSize: theme.spacing(3),
    color: `green`,
  },
  alignRight: {
    textAlign: "right",
    paddingRight: `${theme.spacing(1)} !important`,
    fontWeight: "bold",
    color: "black",
  },
  button: {
    // margin: theme.spacing(1),
  },
  accountName: {
    cursor: "pointer",
  },
});

const Review = (props) => {
  const { selectedAccount, triggerDate, classes } = props;

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [currentTriggerDate, setCurrentTriggerDate] = useState(DataUtils.GetDefaultTriggerEndDate());

  const handleChange = (event, newValue) => {
    setSelectedTabIndex(newValue);
  };

  useEffect(() => {
    props.setAccountTriggers("");
    if (props.selectedAccount?.value) {
      fetchData(props.selectedAccount?.value);
      fetchAccountReviewHistory(props.selectedAccount?.value);
      setSelectedTabIndex(getActiveTabIndex());
    }
  }, [props.selectedAccount?.value, props.triggerDate]);

  const fetchData = (account) => {
    if (account) {
      const dateParam = triggerDate || DataUtils.GetDefaultTriggerEndDate();
      fetchAccountTriggers(account, dateParam);
      // props.setReviewActions({});
      ReviewUtils.FetchAccountsWithPendingAlerts(dateParam).then((data) => {
        props.setAccountsWithPendingAlerts(data);
      });
      ReviewUtils.FetchAccountPendingAlerts(account).then((data) => {
        props.setAccountPendingAlerts(data);
      });
      ReviewUtils.FetchAccountClosedAlerts(dateParam).then((data) => {
        props.setClosedAlerts(data);
      });
      ReviewUtils.FetchAlertsClosedBySubordinates(dateParam).then((data) => {
        props.setAlertsClosedBySubordinates(data);
      });
      ReviewUtils.FetchReassignedAlerts().then((data) => {
        props.setReassignedAlerts(data);
      });
    }
  };

  const fetchAccountReviewHistory = (accountNumber) => {
    ReviewUtils.FetchAccountReviewHistory(accountNumber).then((data) => {
      props.setAccountReviewHistory(data);
      ReviewUtils.FetchAlertClosureHistory(accountNumber, triggerDate || DataUtils.GetDefaultTriggerEndDate()).then((data) => {
        props.setAlertClosureHistory(data);
        ReviewUtils.FetchNPAMovement(accountNumber, triggerDate || DataUtils.GetDefaultTriggerEndDate()).then((data) => {
          props.setNPAMovement(data);
        });
      });
    });

    AdminUtils.FetchOrganizationRoles().then((data) => {
      props.setOrganizationRoles(data);
    });
  };

  const setDefaultData = () => {
    props.setSelectedAccount("");
    props.setReviewActions({});
    props.setAccountTriggers([]);
    props.setReviewHistory([]);
    props.setReviewRemarksHistory([]);
    props.setOverallCommentsHistory([]);
    props.setAlertClosureHistory([]);
    props.setAccountReviewHistory([]);
    props.setAccountControllers([]);
    props.setOverallComments("");
  };

  const onLoad = () => {
    setDefaultData();
    AdminUtils.FetchAccounts().then((data) => {
      props.setLoanAccounts(AccountUtils.MapLoanAccounts(data));
    });
    AdminUtils.FetchOrganizationRoles().then((data) => {
      props.setOrganizationRoles(data);
    });
    DataUtils.FetchSuggestiveActions().then((data) => {
      props.setSuggestiveActions(data);
    });

    const dateParam = triggerDate || DataUtils.GetDefaultTriggerEndDate();
    ReviewUtils.FetchAccountsWithPendingAlerts(dateParam).then((data) => {
      props.setAccountsWithPendingAlerts(data);
    });
    ReviewUtils.FetchAccountClosedAlerts(dateParam).then((data) => {
      props.setClosedAlerts(data);
    });
    ReviewUtils.FetchAlertsClosedBySubordinates(dateParam).then((data) => {
      props.setAlertsClosedBySubordinates(data);
    });
    ReviewUtils.FetchReassignedAlerts().then((data) => {
      props.setReassignedAlerts(data);
    });
  };

  useEffect(onLoad, []);

  useEffect(() => {
    return () => {
      props.setSelectedAccount("");
    };
  }, []);

  const fetchAccountTriggers = (accountNumber, triggerDate) => {
    AccountUtils.FetchAccountTriggers(accountNumber, triggerDate).then((data) => {
      const formattedData = formatAccountTriggers(data.Triggers?.Triggers);
      props.setAccountTriggers(formattedData);
      props.setReviewHistory(data.UserReviews);
      props.setReviewRemarksHistory(data.UserReviewRemarks);
      props.setOverallCommentsHistory(data.ReviewOverallComments);
      props.setAlertClosureHistory(data.UserReviewClosures);
      if (FormatUtils.FormatToPrettyDate(currentTriggerDate) !== FormatUtils.FormatToPrettyDate(data.TriggerDate)) {
        props.setTriggerDate(FormatUtils.FormatToPrettyDate(data.TriggerDate));
      }

      props.setAccountControllers(data.UnitControllers);
      props.setIsAlertClosed(data.IsAlertClosed);
      props.setUserReviewInitiators(data.UserReviewInitiators);
    });
  };

  const formatAccountTriggers = (data) => {
    return data;
  };

  // const handleDateChange = (date) => {
  //   if (!HelperUtils.IsValidDate(date)) return;
  //   props.setCurrentDate(date);
  //   ReviewUtils.FetchAccountsWithPendingAlerts(date).then((data) => {
  //     props.setAccountsWithPendingAlerts(data);
  //   });
  // };

  const hasSubordinates = () => {
    return props?.roles?.some((i) => i?.OrganizationRoles?.Name !== "RelationshipManager");
  };

  const refreshData = (holdTab) => {
    props.setAccountTriggers("");
    fetchData(props.selectedAccount?.value);
    fetchAccountReviewHistory(props.selectedAccount?.value);

    if (!holdTab) {
      props.setSelectedAccount("");
      setSelectedTabIndex(0);
    }
  };

  let tabs = [
    {
      index: 0,
      label: "Pending Alerts",
      content: <PendingAlerts />,
      levels: [],
    },
    {
      index: 1,
      label: "Submitted By Subordinates",
      content: <SubordinateClosures />,
      hidden: !hasSubordinates(),
      levels: [],
    },

    {
      index: 2,
      label: "Reassigned Alerts",
      content: <ReassignedAlerts />,
      levels: [],
    },
    {
      index: 3,
      label: "Closed Alerts",
      content: <ClosedAlerts />,
      levels: [],
    },
    {
      index: 4,
      label: "Account Review",
      content: <SuggestiveActions onSubmit={refreshData} />,
      hidden: !props?.selectedAccount?.value,
      levels: ["account", "date"],
    },
    {
      index: 5,
      label: "Account Review History",
      content: <AccountReviewHistory />,
      hidden: !props?.selectedAccount?.value,
      levels: ["account", "date"],
    },
    {
      index: 6,
      label: "Risk Movement",
      content: <RiskMovement />,
      hidden: !props?.selectedAccount?.value,
      levels: ["account", "date"],
    },
    // {
    //   index: 6,
    //   label: "Closed Triggers",
    //   content: <AlertClosureHistory />,
    //   hidden: !props?.selectedAccount?.value,
    // },
    // {
    //   index: 7,
    //   label: "Pending Triggers",
    //   content: <OpenAlerts />,
    //   hidden: !props?.selectedAccount?.value,
    // },
  ];

  const getActiveTabIndex = () => {
    return mapTabs()?.find((i) => i.label === "Account Review")?.index;
  };

  const isInitiator = () => {
    return props.roles?.some((i) => i?.OrganizationRoles?.IsInitiator);
  };

  const getAccountName = () => {
    const accountNumber = props.selectedAccount?.value;
    const accountName =
      props.accountsWithPendingAlerts?.find((i) => i.AccountNumber === accountNumber)?.BorrowerName ??
      props.closedAlerts?.find((i) => i.AccountNumber === accountNumber)?.BorrowerName ??
      props.alertsClosedBySubordinates?.find((i) => i.AccountNumber === accountNumber)?.BorrowerName;
    return accountName;
  };

  const mapTabs = () => {
    if (!hasSubordinates()) {
      const index = tabs.find((i) => i.label === "Submitted By Subordinates").index;
      const filteredTabs = tabs.filter((i) => i.label !== "Submitted By Subordinates");
      return filteredTabs.map((tab) => {
        return {
          ...tab,
          index: tab.index > index ? tab.index - 1 : tab.index,
        };
      });
    }

    return tabs;
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const showAccountName = (tabIndex) => {
    return mapTabs()
      ?.find((tab) => tab.index === tabIndex)
      ?.levels?.includes("account");
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={3} md={3} sm={6} xs={12} className={classes.datepicker}>
          {/* <Tooltip title="Trigger Date" aria-label="trigger-date">
            <IDatePicker label={"Trigger Date"} value={currentDate || new Date()} onChange={(date) => handleDateChange(date)}></IDatePicker>
          </Tooltip> */}
        </Grid>
        <Grid item lg={9} md={9} sm={6} xs={12} className={classes.alignRight}>
          {selectedTabIndex > 0 && props?.selectedAccount?.value && (
            <React.Fragment>
              {showAccountName(selectedTabIndex) && (
                <Tooltip title="Click to show dates for which alerts are pending" aria-label="alerts-pending-dates">
                  <span className={classes.accountName} onClick={handleDialogOpen}>
                    {getAccountName()}
                  </span>
                </Tooltip>
              )}
              <div>
                <Tooltip title="Selected Date" aria-label="trigger-date">
                  <span className={classes.accountName} onClick={handleDialogOpen}>
                    {FormatUtils.FormatToPrettyDate(triggerDate || DataUtils.GetDefaultTriggerEndDate())}
                  </span>
                </Tooltip>
              </div>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <IControlledTab tabs={mapTabs()} value={selectedTabIndex} onChange={handleChange} />
        </Grid>
      </Grid>
      <IDialog
        title={<div>Pending Alerts</div>}
        open={dialogOpen}
        content={<AccountPendingAlerts onClose={handleDialogClose} />}
        onClose={handleDialogClose}
      ></IDialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedAccount: state.accountReducer.selectedAccount,
    closedAlerts: state.reviewReducer.closedAlerts,
    alertsClosedBySubordinates: state.reviewReducer.alertsClosedBySubordinates,
    roles: state.authReducer.roles,
    selectedTabIndex: state.reviewReducer.selectedTabIndex,
    accountsWithPendingAlerts: state.reviewReducer.accountsWithPendingAlerts,
    triggerDate: state.reviewReducer.triggerDate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoanAccounts: (payload) => dispatch(setLoanAccounts(payload)),
    setAccountTriggers: (payload) => dispatch(setAccountTriggers(payload)),
    setSelectedAccount: (payload) => dispatch(setSelectedAccount(payload)),
    setTriggerDate: (payload) => dispatch(setTriggerDate(payload)),
    setReviewActions: (payload) => dispatch(setReviewActions(payload)),
    setReviewHistory: (payload) => dispatch(setReviewHistory(payload)),
    setReviewRemarksHistory: (payload) => dispatch(setReviewRemarksHistory(payload)),
    setOverallCommentsHistory: (payload) => dispatch(setOverallCommentsHistory(payload)),
    setOverallComments: (payload) => dispatch(setOverallComments(payload)),
    setAlertClosureHistory: (payload) => dispatch(setAlertClosureHistory(payload)),
    setOrganizationRoles: (payload) => dispatch(setOrganizationRoles(payload)),
    setSuggestiveActions: (payload) => dispatch(setSuggestiveActions(payload)),
    setAccountReviewHistory: (payload) => dispatch(setAccountReviewHistory(payload)),
    setNPAMovement: (payload) => dispatch(setNPAMovement(payload)),
    setAccountControllers: (payload) => dispatch(setAccountControllers(payload)),
    setAccountsWithPendingAlerts: (payload) => dispatch(setAccountsWithPendingAlerts(payload)),
    setAccountPendingAlerts: (payload) => dispatch(setAccountPendingAlerts(payload)),
    setClosedAlerts: (payload) => dispatch(setClosedAlerts(payload)),
    setAlertsClosedBySubordinates: (payload) => dispatch(setAlertsClosedBySubordinates(payload)),
    setIsAlertClosed: (payload) => dispatch(setIsAlertClosed(payload)),
    setReassignedAlerts: (payload) => dispatch(setReassignedAlerts(payload)),
    setUserReviewInitiators: (payload) => dispatch(setUserReviewInitiators(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(Review)));
