import React from "react";
import { connect } from "react-redux";
import { withStyles, Typography } from "@material-ui/core";
import { useEffect } from "react";
import ITab from "./../components/common/ITab";
import BorrowerwiseReport from "../components/misreports/BorrowerwiseReport";
import TriggerwiseReport from "../components/misreports/TriggerwiseReport";
import AlertwiseReport from "../components/misreports/AlertwiseReport";
import SuggestiveActionwiseReport from "../components/misreports/SuggestiveActionwiseReport";
import { setMISReports } from "../actions/misReportsActions";
import MasterMISReport from "../components/misreports/MasterMISReport";
import RFAStatusReport from "../components/misreports/RFAStatusReport";

let styles = (theme) => ({
  root: {
  },
});

const MISReports = (props) => {
  const classes = props;

  const onLoad = () => {
  };

  useEffect(onLoad, []);

  const tabs = [
    {
      index: 0,
      label: "Master Report",
      content: <MasterMISReport></MasterMISReport>,
    },
    {
      index: 1,
      label: "Alert-wise status Report",
      content: <AlertwiseReport></AlertwiseReport>,
    },
    {
      index: 2,
      label: "Trigger-wise status Report",
      content: <TriggerwiseReport></TriggerwiseReport>,
    },
    {
      index: 3,
      label: "Borrower-wise status Report",
      content: <BorrowerwiseReport></BorrowerwiseReport>,
    },
    {
      index: 4,
      label: "Suggestive action-wise Responses Report",
      content: <SuggestiveActionwiseReport></SuggestiveActionwiseReport>,
    },
    {
      index: 5,
      label: "RFA Status Report",
      content: <RFAStatusReport></RFAStatusReport>,
    }
  ];

  return <ITab tabs={tabs} />;
};

const mapStateToProps = (state) => {
  return {
    misReports: state.misReportsReducer.misReports
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMISReports: (payload) => dispatch(setMISReports(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MISReports));