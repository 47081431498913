import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Grid, withStyles, useMediaQuery, useTheme } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DangerousIcon from "@material-ui/icons/Error";
import { useEffect } from "react";

let styles = (theme) => ({
  root: {
    width: "100%",
  },
  whiteBg: {
    background: "#FFF",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    float: `right`,
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepLabel: {
    "& .MuiStepLabel-label": {
      textAlign: `left`,
      fontWeight: `bold !important`,
    },
  },
  stepLabelExtraSmallScreen: {
    "& .MuiStepLabel-label": {
      textAlign: `center`,
      fontWeight: `bold !important`,
      fontSize: 8,
      wordBreak: "break-word",
    },
  },
  stepLabelSmallScreen: {
    "& .MuiStepLabel-label": {
      textAlign: `center`,
      fontWeight: `bold !important`,
      fontSize: 12,
      wordBreak: "break-word",

    },
  },
  title: {
    textAlign: `left`,
    color: "black",
    fontSize: theme.spacing(3),
    border: "none",
    textTransform: "none",
    background: `#FFFFFF`,
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
  completed: {
    color: "green",
  },
  failed: {
    color: "red",
  },
  alignRight: {
    textAlign: "right",
    fontSize: theme.spacing(2),
    color: "black",
    paddingTop: theme.spacing(1),
  },
  horizontalStepContentContainer: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    overflow: "auto",
    color: "black"
  }
});

const IStepper = (props) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const {
    steps,
    title,
    resetEnabled,
    finishEnabled,
    closeEnabled,
    reopenEnabled,
    finishMessage,
    resetText,
    onReset,
    onFinish,
    onPrevious,
    onNext,
    onCloseAlert,
    onReopenAlert,
    orientationStyle,
    classes,
    showAllSteps,
    finishSuccess,
    showLastStep,
  } = props;

  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"))
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("xs", "md"))

  const getStepContent = (index) => {
    return steps.find((item) => item.index === index).content;
  };

  useEffect(() => {
    setActiveStep(showLastStep ? steps.length - 1 : 0);
  }, [showLastStep, steps.length]);

  const handleNext = () => {
    const nextStep = activeStep + 1;
    setActiveStep(nextStep);

    if (nextStep === steps.length) {
      if (onFinish) {
        onFinish();
      }
    } else {
      if (onNext) {
        onNext(nextStep);
      }
    }
  };

  const handleBack = () => {
    const previousStep = activeStep - 1;
    setActiveStep(previousStep);

    if (onPrevious) {
      onPrevious(previousStep);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    if (onReset) {
      onReset();
    }
  };

  const getSteps = () => {
    if (!showAllSteps) {
      return steps.filter((step) => step.index === steps.length - 1) ?? [];
    }
    return steps;
  };

  const stepLabelVertical = (step) => {
    return (
      <StepLabel className={classes.stepLabel}>{step.label}</StepLabel>
    );
  };

  const actionElements = (activeStep, steps) => {
    return (
      <div className={`${classes.actionsContainer} ${classes.root} ${classes.whiteBg}`}>
        <div>
          {activeStep > 0 && (
            <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
              Previous
            </Button>
          )}
          {showAllSteps && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={classes.button}
              disabled={activeStep === steps.length - 1 && !finishEnabled}
            >
              {activeStep === steps.length - 1 ? "Finish Review" : "Next"}
            </Button>
          )}
        </div>
      </div>
    );
  };

  const renderStepper = (activeStep) => {
    if (orientationStyle === "vertical") {
      return (
        getSteps().map((activeStep) => renderVerticalStepContent(activeStep))
      )
    }
    else {
      return (
        getSteps().map((activeStep) => (
          <Step>
            <StepLabel className={isExtraSmallScreen ? classes.stepLabelExtraSmallScreen : isSmallScreen ? classes.stepLabelSmallScreen : classes.stepLabel}
              alternativeLabel={isExtraSmallScreen || isSmallScreen}>{activeStep.label}</StepLabel>
          </Step>)
        )
      );
    }
  };

  const renderVerticalStepContent = (step) => {
    if (orientationStyle === "vertical") {
      return (
        <Step key={step.index}>
          <StepLabel className={classes.stepLabel}>{stepLabelVertical(step)}</StepLabel>
          <StepContent>
            <div>{getStepContent(step.index)}</div>

            <div>{actionElements(activeStep, getSteps())}</div>
          </StepContent>
        </Step >

      );
    }
  };

  const renderHorizontalStepContent = (activeStep, steps) => {
    if (activeStep < steps.length && activeStep != steps.length && steps[0].index === 0) {
      return (
        <div>
          <div>{getStepContent(activeStep)}</div>
          <div className={classes.resetContainer}>{actionElements(activeStep, steps)}</div>
        </div>
      );
    }
    else if(steps[0].index != 0) {//Return review summary
      return (
        <div>
          <div>{getStepContent(steps[0].index)}</div>
          <div className={classes.resetContainer}>{actionElements(activeStep, steps[0].index)}</div>
        </div>
      );
    }
    else if(activeStep >= steps.length) {
      //return nothing (Failed review)
    }
  };

  return (
    <div className={`shadow-sm ${classes.root}`}>
      <Grid container>
        <Grid item xs={8} className={classes.whiteBg}>
          {title && <div className={classes.title}>{title}</div>}
        </Grid>
        <Grid item xs={4} className={classes.whiteBg}>
          <div className={classes.alignRight}>
            {closeEnabled && (
              <Button
                variant="contained"
                color="primary"
                onClick={onCloseAlert}
                className={classes.button}
                disabled={activeStep === steps.length - 1 && !closeEnabled}
              >
                Close Alert
              </Button>
            )}
            {reopenEnabled && (
              <Button
                variant="contained"
                color="secondary"
                onClick={onReopenAlert}
                className={classes.button}
                disabled={activeStep === steps.length - 1 && !reopenEnabled}
              >
                Re-assign Alert
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
      <Stepper activeStep={activeStep} orientation={orientationStyle}>
        {renderStepper({ activeStep }, steps)}
      </Stepper>
      {orientationStyle === "horizontal" && (<div className={`${classes.horizontalStepContentContainer} ${classes.root} ${classes.whiteBg}`}>{renderHorizontalStepContent(activeStep, getSteps())}</div>)}
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          {finishMessage && (
            <div>
              {finishSuccess ? <CheckCircleIcon className={classes.completed} /> : <DangerousIcon className={classes.failed} />}
              <Typography>{finishMessage}</Typography>
            </div>
          )}
          {resetEnabled && (
            <Button onClick={handleReset} className={classes.button}>
              {resetText || "Reset"}
            </Button>
          )}
        </Paper>
      )}
    </div>
  );
};

export default withStyles(styles)(IStepper);
