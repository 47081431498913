import { SET_RISK_SCORES } from "../actions/riskActions";

const initialState = {
  riskScores: [],
};

const riskReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RISK_SCORES:
      return {
        ...state,
        riskScores: [...action.payload],
      };
    default:
      return state;
  }
};

export default riskReducer;
