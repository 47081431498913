import React, { createRef, useEffect, useState, useRef } from "react";
import {
  withStyles, Box, Paper, Grid, FormControl, Select, MenuItem, Typography,
  Button,
  TextField,
  Toolbar,
  CircularProgress,
  useTheme,
  IconButton,
  TableCell,
  Table,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CreateIcon from '@material-ui/icons/Create';
import RFAUtils from "../../utils/rfaUtils";
import FormUtils from "../../utils/formUtils";
import { setLoader, setSnackBar } from "../../actions/commonActions";
import { connect } from "react-redux";
import { setToFormOpen, setToFormClose, setFormDetails, setClosedRFA } from "../../actions/rfaActions";
import jsPDF from 'jspdf';
import FormatUtils from "../../utils/formatUtils";
import ReactDOMServer from "react-dom/server";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import * as XLSX from 'xlsx';

let styles = (theme) => ({
  misReportFilterToolbar: {
    '& .MuiTypography-body1': {
      fontWeight: 400,
      fontSize: "24px",
      float: "left",
      color: "black",
    },
  },
  button: {
    float: `right`,
    margin: theme.spacing(0.5)
  },
  versionChangeTableBody: {
    '& .MuiTableCell-root': {
      line: "8px"
    },
  },
  pdfBodyFont: {
    fontSize: 12
  },
  tableHeading: {
    fontSize: theme.spacing(3),
    float: `left`,
  },
  formPaper: {
    overflow: "auto"
  }
});

const RFAForm = (props) => {
  const { classes, setSelectedTabIndex, hasSubordinates, isController, setEnableForm } = props;
  const theme = useTheme();

  const paperRoot = useRef(null);
  let sectionLastLabelNo = 0;

  const [formData, setFormData] = useState({});
  const [formSubmissionObj, setformSubmissionObj] = useState({});
  const [formProgress, setFormProgress] = useState();
  const [totalNumberOfLabels, setTotalNumberOfLabels] = useState(0);
  const [savedValues, setSavedValues] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [disableEdit, setDisableEdit] = useState(true);
  const [hideApproveReassign, setHideApproveReassign] = useState(true);
  const [changeHistory, setChangeHistory] = useState({});
  const [disableOverallComment, setDisableOverallComment] = useState(false);
  const [isPendingSubmission, setIsPendingSubmission] = useState(false);
  const [isPendingWith, setIsPendingWith] = useState(false);
  const [tabularChangeHistoryList, setTabularChangeHistoryList] = useState([]);
  const [subordinatesInChangeHistory, setSubordinatesInChangeHistory] = useState([]);
  const [formDetailsFromAPI, setFormDetailsFromAPI] = useState([]);
  const [subordinateFormData, setSubordinateFormData] = useState({});
  const [subordinatesInSubordinateFormData, setSubordinatesInSubordinateFormData] = useState([]);
  const [validationWarningDialog, setValidationWarningDialogOpen] = useState(false);

  const onload = () => {
    setToFormOpen(false);
    if (Object.keys(props.formDetails).length == 0) {
      setSelectedTabIndex(0);
      return;
    };
    RFAUtils.FetchFormValues(props.formDetails).then((data) => {
      setSavedValues(data.FormData);
      //full copy and resubmission is needed as selected subLabel logic depends upon submitted labeldata
      setformSubmissionObj(data.FormData);
      setIsPendingSubmission(data.IsPendingSubmission);
      if (data.IsPendingWith) {
        setIsPendingWith(true);
        if (data.IsAlreadySubmitted) {
          setDisableEdit(true);
        }
        else if (!data.IsAlreadySubmitted) {
          setDisableEdit(false);
          //do nothing for now
        };
        if (data.IsPendingSubmission) {
          //form is pending submission so disable approve/reassign
          setDisableEdit(false);
          setHideApproveReassign(true);
        }
        else if (!data.IsPendingSubmission) {
          setHideApproveReassign(false);
        };
        if (isController()) {
          //disable save and submit for controller and disable edit
          setDisableEdit(true);
        };
      };
      if (!data.IsPendingWith) {
        //not pending with user, he can do nothing
        setIsPendingWith(false);//to set overall comments
        setDisableEdit(true);
        setHideApproveReassign(true);
        setDisableOverallComment(true);
      };
      if (data.IsAlreadySubmitted) {
        //get the change history only when a form has a previous submission
        const implementChangeDifference = false;
        if (implementChangeDifference) {
          RFAUtils.FetchRFAChangeDifference(props.formDetails).then((responseData) => {
            setChangeHistory(responseData);
            let tabularChangeHistoryListLocal = [];
            let uniqueRoles = new Set();

            Object.keys(responseData).map((historyLabelData, j) => {
              Object.keys(responseData[historyLabelData]).map((labelType, j) => {
                Object.keys(responseData[historyLabelData][labelType]).map((roleId, j) => {
                  uniqueRoles.add(responseData[historyLabelData][labelType][roleId].role);
                });
              });
            });
            setSubordinatesInChangeHistory(Array.from(uniqueRoles));

            Object.keys(responseData).map((historyLabelData, j) => {
              //Get roles present in change history
              // traverse through two levels and find unique roles and store the role names in a set

              let tabularChangeHistoryListObj = {
                LabelId: historyLabelData,
                //RMResponse: "",
                //RMRemark: "",
                //BMResponse: "",
                //BMRemark: ""
              };
              Object.keys(responseData[historyLabelData]).map((labelType, j) => {
                Object.keys(responseData[historyLabelData][labelType]).map((roleId, j) => {
                  if (responseData[historyLabelData][labelType][roleId].role == "Relationship Manager") {
                    if (labelType == "labelData") {
                      tabularChangeHistoryListObj.RMResponse = responseData[historyLabelData][labelType][roleId].value
                    }
                    else if (labelType == "subLabelData") {
                      tabularChangeHistoryListObj.RMRemark = responseData[historyLabelData][labelType][roleId].value
                    }
                  }
                  else if (responseData[historyLabelData][labelType][roleId].role == "Branch Manager") {
                    if (labelType == "labelData") {
                      tabularChangeHistoryListObj.BMResponse = responseData[historyLabelData][labelType][roleId].value
                    }
                    else if (labelType == "subLabelData") {
                      tabularChangeHistoryListObj.BMRemark = responseData[historyLabelData][labelType][roleId].value
                    }
                  }
                });
              });
              tabularChangeHistoryListLocal.push(tabularChangeHistoryListObj);
            });
            setTabularChangeHistoryList(tabularChangeHistoryListLocal);
          });
        }
        //get subordinateForms only when a form has a previous submission
        RFAUtils.FetchSubordinateForms(props.formDetails).then((responseData) => {
          setSubordinateFormData(responseData.SubordinateFormData);
          let subordinateRolesInSubordinateFormData = [];
          Object.keys(responseData.SubordinateFormData).map((subordinateEmailId, j) => {
            subordinateRolesInSubordinateFormData.push(responseData.SubordinateFormData[subordinateEmailId].Role);
          });
          setSubordinatesInSubordinateFormData(subordinateRolesInSubordinateFormData);
        });
      };
      delete data.FormData; //Update formDetails with API information without data
      setFormDetailsFromAPI(data);
    });
    FormUtils.FetchFormTemplate(1).then((data) => {
      setFormData(data);
      let totalNumberOfFormLabels = 0;
      data.sections.forEach(sections => {
        totalNumberOfFormLabels = totalNumberOfFormLabels + sections.labels.length;
      });
      setTotalNumberOfLabels(totalNumberOfFormLabels);
      setFormProgress(Object.keys(savedValues).length * 100 / totalNumberOfFormLabels);
    });

  }

  useEffect(onload, []);

  const updateFormProgress = () => {
    let totalValues = {};
    Object.assign(totalValues, savedValues);
    Object.assign(totalValues, formSubmissionObj);
    /* remove keys from total values where first choice length is 0 (label response is empty)
     * so progress is set back
     */
    for (const [labelKey, labelData] of Object.entries(totalValues)) {
      for (const [choiceKey, choiceData] of Object.entries(labelData)) {
        if (choiceKey !== "subLabelData" && choiceData.trim().length === 0) {
          delete totalValues[labelKey];
        }
      }
    }
    setFormProgress(Object.keys(totalValues).length * 100 / totalNumberOfLabels);
    if ((Object.keys(totalValues).length * 100 / totalNumberOfLabels) === 100) {
      setDisableSubmit(false);
    }
  };

  const saveForm = () => {
    let formDetailsData = props.formDetails;
    formDetailsData["FormData"] = formSubmissionObj
    RFAUtils.PutSavedRFAForm(formDetailsData).then((data) => {
      props.setSnackBar("Form saved successfully");
      return;
    });
  };

  const submitForm = () => {
    if (formProgress !== 100) {
      setValidationWarningDialogOpen(true);
      return;
    };
    let formDetailsData = props.formDetails;
    formDetailsData["FormData"] = formSubmissionObj
    RFAUtils.SubmitRFAForm(formDetailsData).then((data) => {
      props.setSnackBar("Form has been submitted successfully");
      setSelectedTabIndex(0);
      setToFormOpen(false);
      setEnableForm(false);
      return;
    });
  };

  const modifyForm = () => {
    // send state to change to to submit in formstate so he cannot approve
    // it is already pending with this user
    RFAUtils.ModifySubmittedRFAForm(props.formDetails).then((data) => {
      //do something
      setDisableEdit(false);
      setHideApproveReassign(true);
    });
  };

  const handleValidationDialogClose = () => {
    setValidationWarningDialogOpen(false);
  };

  const approveForm = () => {
    if (formProgress !== 100) {
      setValidationWarningDialogOpen(true);
      return;
    };
    let formDetailsData = props.formDetails;
    formDetailsData["FormData"] = formSubmissionObj
    RFAUtils.ApproveRFAForm(formDetailsData).then((data) => {
      props.setSnackBar("Form has been approved successfully");
      setSelectedTabIndex(0);
      setToFormOpen(false);
      setEnableForm(false);
      return;
    });
  };

  const getSelectedValues = (labelData, subLabelData, choiceGroupData) => {
    const savedLabel = formSubmissionObj[labelData.labelId];
    if (savedLabel !== undefined && subLabelData === undefined) {
      return savedLabel[choiceGroupData.choiceGroupId]
    }
    else if ((savedLabel !== undefined && savedLabel["subLabelData"] !== undefined)) {
      return savedLabel["subLabelData"][choiceGroupData.choiceGroupId]
    }
    else {
      return
    }
  };

  const getSavedValues = (labelData, subLabelData, choiceGroupData) => {
    const savedLabel = savedValues[labelData.labelId];
    if (savedLabel !== undefined && subLabelData === undefined) {
      return savedLabel[choiceGroupData.choiceGroupId]
    }
    else if ((savedLabel !== undefined && subLabelData !== undefined)) {
      if (savedLabel["subLabelData"] !== undefined) {
        return savedLabel["subLabelData"][subLabelData.labelId][choiceGroupData.choiceGroupId]
      }
    }
    else {
      return
    }
  };

  const getSubordinateSubmittedValues = (subordinateEmailId, labelData, subLabelData, choiceGroupData) => {
    const savedLabel = subordinateFormData[subordinateEmailId].FormData[labelData.labelId];
    if (savedLabel !== undefined && subLabelData === undefined) {
      return savedLabel[choiceGroupData.choiceGroupId]
    }
    else if ((savedLabel !== undefined && subLabelData !== undefined)) {
      if (savedLabel["subLabelData"] !== undefined) {
        return savedLabel["subLabelData"][subLabelData.labelId][choiceGroupData.choiceGroupId]
      }
    }
    else {
      return
    }
  };

  const handleLabelSelectValue = (event, value, labelData, choiceGroupData) => {
    const map1 = new Map();
    const map2 = new Map();
    map2.set(choiceGroupData.choiceGroupId, value);
    const map2Obj = Object.fromEntries(map2)
    var labelDat = [];
    map1.set(labelData.labelId, map2Obj);
    const labelId = String(labelData.labelId);
    var obj = {};
    Object.assign(obj, formSubmissionObj[labelData.labelId])
    Object.assign(obj, Object.fromEntries(map2));
    setformSubmissionObj({ ...formSubmissionObj, [labelId]: obj });
  };

  const handleTextFieldValue = (value, labelData, subLabelData, choiceGroupData) => {
    const map1 = new Map();
    const map2 = new Map();
    const map3 = new Map();
    if (subLabelData !== undefined) {
      map3.set(subLabelData.labelId, Object.fromEntries(new Map().set(choiceGroupData.choiceGroupId, value)));
      map1.set("subLabelData", Object.fromEntries(map3));
      var obj = {};
      Object.assign(obj, formSubmissionObj[labelData.labelId])
      Object.assign(obj, Object.fromEntries(map1));
      const labelId = String(labelData.labelId);
      setformSubmissionObj({ ...formSubmissionObj, [labelId]: obj });
    }
    else {
      map2.set(choiceGroupData.choiceGroupId, value);
      var obj = {};
      Object.assign(obj, formSubmissionObj[labelData.labelId])
      Object.assign(obj, Object.fromEntries(map2));
      setformSubmissionObj({ ...formSubmissionObj, [labelData.labelId]: obj });
    }
  };

  const getParentLabelValue = (labelData, choiceGroupData) => {
    const labelObj = formSubmissionObj[labelData.labelId];
    const savedLabelObj = savedValues[labelData.labelId];
    if (labelObj !== undefined) {
      return labelObj[1];
    }
    if (savedLabelObj !== undefined) {
      return savedLabelObj[1];
    }
  };

  useEffect(updateFormProgress, [savedValues, handleLabelSelectValue])

  const exportToPDF = () => {
    if (!props.closedRFA && isPendingWith) {
      return;
    };
    props.setLoader(true);

    var pdfFileName = FormatUtils.GetDownloadFileName("ClosedRFA", "pdf");
    sectionLastLabelNo = 0;
    var pdf = new jsPDF("p", "mm", "a4");
    const pdfElement =
      <Box p={2} bgcolor={"white"} color="black">
        <Toolbar>
          <Box flexDirection="row" display="flex" alignItems="center">
            <Box className={classes.misReportFilterToolbar}>
              <Typography>{formData.formLabel}</Typography>
            </Box>
          </Box>
          <Box flexDirection="column" display="flex" flex="auto" alignItems="flex-end">
            <Typography>
              Borrower Name: <b>{formDetailsFromAPI["BorrowerName"]}</b>
            </Typography>
            {formDetailsFromAPI.TriggerDate !== undefined && formDetailsFromAPI.TriggerDate !== null && (
              <Typography>
                Trigger Date: <b>{FormatUtils.FormatToPrettyDate(formDetailsFromAPI.TriggerDate)}</b>
              </Typography>
            )}
          </Box>
        </Toolbar>
        <Grid container>
          <Grid item xs={12}>
            <Box pl={6} pr={6}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>S.No</b>
                    </TableCell>
                    <TableCell>
                      <b>Label</b>
                    </TableCell>
                    <TableCell>
                      <b>Response</b>
                    </TableCell>
                    {!isController() && (
                      <TableCell>
                        <b>Remark</b>
                      </TableCell>
                    )
                    }
                    {subordinatesInSubordinateFormData.map((subordinateRole) => {
                      return (<React.Fragment>
                        <TableCell>
                          <b>{subordinateRole + " Response"}</b>
                        </TableCell>
                        <TableCell>
                          <b>{subordinateRole + " Remark"}</b>
                        </TableCell>
                      </React.Fragment>
                      )
                    })}
                  </TableRow>
                </TableHead>
                {formData?.sections?.map((sectionData, secNo) => {
                  return (
                    <React.Fragment>
                      {sectionData.labels.map((labelData, i) => {
                        const subLabelData = undefined; // sublabeldata as undefined anyway for label so pass undefined to functions
                        sectionLastLabelNo = sectionLastLabelNo + 1;
                        return (
                          <TableRow sx={{
                            borderBottom: " 1px solid rgba(224, 224, 224, 1)"
                          }}>
                            <TableCell>
                              <Typography>{sectionLastLabelNo}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{labelData.label}</Typography>
                            </TableCell>
                            {labelData.choiceGroups.map((choiceGroupData) => {
                              if (isController() && labelData.labelId !== 62) {
                                return (
                                  <TableCell></TableCell>
                                )
                              }
                              else if (choiceGroupData.type === "radio") {
                                return (
                                  <TableCell>
                                    {(getSelectedValues(labelData, subLabelData, choiceGroupData) ?? getSavedValues(labelData, subLabelData, choiceGroupData)) ?? ""}
                                  </TableCell>
                                )
                              }
                              else if (choiceGroupData.type === "text") {
                                return (
                                  <TableCell>
                                    {(isPendingWith && !isPendingSubmission && labelData.labelId === 62/*Overall Comments*/) ? ("") : (getSelectedValues(labelData, subLabelData, choiceGroupData)) || (getSavedValues(labelData, subLabelData, choiceGroupData)) || undefined}
                                  </TableCell>
                                )
                              }
                            })
                            }
                            {!isController() && labelData.subLabels.length === 0 && (//no remarks sublabel so render an empty cell for sublabel column
                              <TableCell></TableCell>
                            )}
                            {!isController() && labelData.subLabels.map((subLabelData, subLabelIndex, choiceGroupData) => {
                              if (getParentLabelValue(labelData, choiceGroupData) === "Yes") {
                                return (
                                  subLabelData.choiceGroups.map((choiceGroupData) => {
                                    if (choiceGroupData.type === "text") {
                                      return (
                                        <TableCell>
                                          {getSelectedValues(labelData, subLabelData, choiceGroupData) ?? getSavedValues(labelData, subLabelData, choiceGroupData)}
                                        </TableCell>
                                      )
                                    }
                                  })
                                )
                              }
                              else {
                                return (<TableCell></TableCell>)
                              }
                            })
                            }
                            {Object.keys(subordinateFormData).length !== 0 && Object.keys(subordinateFormData).map((subordinateInfo) => {
                              return (
                                labelData.choiceGroups.map((choiceGroupData) => {
                                  return (
                                    <React.Fragment>
                                      <TableCell>
                                        <p>{getSubordinateSubmittedValues(subordinateInfo, labelData, subLabelData, choiceGroupData)}</p>
                                      </TableCell>
                                      {labelData.subLabels.length !== 0 && labelData.subLabels.map((subLabelData) => {
                                        return (
                                          subLabelData.choiceGroups.map((choiceGroupData) => {
                                            return (
                                              <TableCell>
                                                <p>{getSubordinateSubmittedValues(subordinateInfo, labelData, subLabelData, choiceGroupData)}</p>
                                              </TableCell>
                                            )
                                          })
                                        )
                                      })}
                                      {
                                        labelData.subLabels.length === 0 && (
                                          <TableCell></TableCell>
                                        )
                                      }
                                    </React.Fragment>
                                  )
                                }))
                            })
                            }
                          </TableRow>
                        )
                      })
                      }
                    </React.Fragment>
                  )
                })}
              </Table>
            </Box>
          </Grid>
        </Grid>
      </Box >
      ;

    pdf.html(ReactDOMServer.renderToString(pdfElement), {
      autoPaging: "text",
      callback: function (pdf) {
        pdf.save(pdfFileName);
        props.setLoader(false);
      },
      width: 211,
      windowWidth: 1920,
      fontSize: 9,
    });
  };

  const exportToXLSX = () => {
    if (!props.closedRFA && isPendingWith) {
      return;
    };
    props.setLoader(true);
    // set download file name
    let xlsxFileName = FormatUtils.GetDownloadFileName("RFA", "xlsx");
    // get the table , it has class name as "pvtTable", set an Id to the table element

    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.table_to_sheet(paperRoot.current, { origin: 1 });
    XLSX.utils.sheet_add_aoa(
      ws,
      [["Borrower Name: " + formDetailsFromAPI["BorrowerName"],
      "Trigger Date: " + FormatUtils.FormatToPrettyDate(formDetailsFromAPI["TriggerDate"])]],
      { origin: 0 }
    );
    XLSX.utils.book_append_sheet(wb, ws, "RFA")
    // Export to a file (download)
    XLSX.writeFile(wb, xlsxFileName);
    props.setLoader(false);
  };

  return (
    <Box>
      <Paper elevation={4} className={classes.formPaper} ref={paperRoot}>
        <Box p={2} display={"block"} bgcolor={"white"}>
          <Toolbar>
            <Box flexDirection="row" display="flex" alignItems="center">
              <Box className={classes.misReportFilterToolbar}>
                <Typography>{formData.formLabel}</Typography>
              </Box>
              <Box ml={2} sx={{ position: 'relative', display: 'inline-flex' }} alignContent={"flex-start"}>
                <CircularProgress variant="determinate" value={formProgress} size="4rem" />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="caption" component="div" color="textSecondary">
                    {`${Math.round(formProgress)}%`}
                  </Typography>
                </Box>
              </Box>
              <Box m={1} fontSize={"12px"}>
                <IconButton size="medium" hidden={!props.closedRFA && isPendingWith} onClick={exportToPDF}>
                  <PictureAsPdfIcon />
                  <Typography>PDF</Typography>
                </IconButton>
                <IconButton size="medium" hidden={!props.closedRFA && isPendingWith} onClick={exportToXLSX}>
                  <DescriptionRoundedIcon />
                  <Typography>XLSX</Typography>
                </IconButton>
              </Box>
            </Box>
            <Box flexDirection="column" display="flex" flex="auto" justifyContent="flex-end">
              <Typography>
                Borrower Name: <b>{formDetailsFromAPI["BorrowerName"]}</b>
              </Typography>
              {formDetailsFromAPI.TriggerDate !== undefined && formDetailsFromAPI.TriggerDate !== null && (
                <Typography>
                  Trigger Date: <b>{FormatUtils.FormatToPrettyDate(formDetailsFromAPI.TriggerDate)}</b>
                </Typography>
              )}
            </Box>
            <Box flexDirection="row" display="flex" flex="auto" justifyContent="flex-end" flexGrow={0}>
              <Button
                variant="contained"
                size="small"
                color="default"
                disabled={(props.isAlreadySubmitted || props.closedRFA)}
                hidden={(disableEdit || props.isAlreadySubmitted || props.closedRFA)}
                className={classes.button}
                onClick={saveForm} startIcon={<SaveRoundedIcon />}>
                Save
              </Button>
              <Button
                variant="contained"
                size="small"
                color="primary"
                disabled={disableEdit}
                hidden={(disableEdit || props.closedRFA)}
                className={classes.button}
                onClick={submitForm} startIcon={<AssignmentTurnedInIcon />}>
                Submit
              </Button>
              <Button
                variant="contained"
                size="small"
                color="primary"
                disabled={(isController()) ? true : (hideApproveReassign)}
                hidden={(isController()) ? true : ((hideApproveReassign) ? true : (props.closedRFA || !hasSubordinates))}
                className={classes.button}
                onClick={modifyForm} startIcon={<CreateIcon />}>
                Modify
              </Button>
              <Button
                variant="contained"
                size="small"
                color="green"
                disabled={hideApproveReassign || props.isAlreadySubmitted}
                hidden={(((props.roles?.some((i) => i?.OrganizationRoles?.CanReassignAlerts)) ? ((hideApproveReassign) ? true : props.isAlreadySubmitted || props.closedRFA) : true))}
                className={classes.button}
                onClick={approveForm} startIcon={<PlaylistAddCheckIcon />}>
                Approve
              </Button>
            </Box>
          </Toolbar>
          <Grid container>
            <Grid item xs={12}>
              <Box pl={6} pr={6}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>S.No</b>
                      </TableCell>
                      <TableCell>
                        <b>Label</b>
                      </TableCell>
                      <TableCell>
                        <b>Response</b>
                      </TableCell>
                      {!isController() && (
                        <TableCell>
                          <b>Remark</b>
                        </TableCell>
                      )
                      }
                      {subordinatesInSubordinateFormData.map((subordinateRole) => {
                        return (<React.Fragment>
                          <TableCell>
                            <b>{subordinateRole + " Response"}</b>
                          </TableCell>
                          <TableCell>
                            <b>{subordinateRole + " Remark"}</b>
                          </TableCell>
                        </React.Fragment>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  {formData?.sections?.map((sectionData, secNo) => {
                    return (
                      <React.Fragment>
                        {sectionData.labels.map((labelData, i) => {
                          const subLabelData = undefined; // sublabeldata as undefined anyway for label so pass undefined to functions
                          sectionLastLabelNo = sectionLastLabelNo + 1;
                          return (
                            <TableRow sx={{
                              borderBottom: " 1px solid rgba(224, 224, 224, 1)"
                            }}>
                              <TableCell>
                                <Typography>{sectionLastLabelNo}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{labelData.label}</Typography>
                              </TableCell>
                              {labelData.choiceGroups.map((choiceGroupData) => {
                                if (isController() && labelData.labelId !== 62) {
                                  return (
                                    <TableCell></TableCell>
                                  )
                                }
                                else if (choiceGroupData.type === "radio") {
                                  return (
                                    <TableCell>
                                      <FormControl sx={{ m: 1, minWidth: 120 }} size="small" disabled={disableEdit ? true : props.closedRFA}>
                                        <Select
                                          labelId={labelData.label}
                                          value={(getSelectedValues(labelData, subLabelData, choiceGroupData) ?? getSavedValues(labelData, subLabelData, choiceGroupData)) ?? ""}
                                          onChange={(event, value) => handleLabelSelectValue(event, event.target.value, labelData, choiceGroupData)}
                                        >
                                          {choiceGroupData.choices.map((choiceData) => {
                                            return (
                                              <MenuItem value={choiceData.choiceValue} disabled={disableEdit ? true : props.closedRFA}
                                              >{choiceData.choiceLabel}</MenuItem>
                                            )
                                          })
                                          }
                                        </Select>
                                      </FormControl>
                                    </TableCell>
                                  )
                                }
                                else if (choiceGroupData.type === "text") {
                                  return (
                                    <TableCell>
                                      <TextField variant="standard" fullWidth
                                        disabled={(props.closedRFA) ? true : ((!disableOverallComment && labelData.labelId === 62/*Overall Comments*/) ? false : (disableEdit || props.closedRFA))}
                                        onBlur={(event) => { handleTextFieldValue(event.target.value.trim(), labelData, subLabelData, choiceGroupData) }}
                                        defaultValue={(isPendingWith && !isPendingSubmission && labelData.labelId === 62/*Overall Comments*/) ? ("") : (getSelectedValues(labelData, subLabelData, choiceGroupData)) || (getSavedValues(labelData, subLabelData, choiceGroupData)) || undefined}
                                        multiline
                                      />
                                    </TableCell>
                                  )
                                }
                              })
                              }
                              {!isController() && labelData.subLabels.length === 0 && (//no remarks sublabel so render an empty cell for sublabel column
                                <TableCell></TableCell>
                              )}
                              {!isController() && labelData.subLabels.map((subLabelData, subLabelIndex, choiceGroupData) => {
                                if (getParentLabelValue(labelData, choiceGroupData) === "Yes") {
                                  return (
                                    <TableCell>
                                      {subLabelData.choiceGroups.map((choiceGroupData) => {
                                        if (choiceGroupData.type === "text") {
                                          return (
                                            <TextField variant="standard" fullWidth disabled={(disableEdit || props.closedRFA)}
                                              onBlur={(event) => { handleTextFieldValue(event.target.value, labelData, subLabelData, choiceGroupData) }}
                                              defaultValue={getSelectedValues(labelData, subLabelData, choiceGroupData) ?? getSavedValues(labelData, subLabelData, choiceGroupData)}
                                              multiline
                                            />
                                          )
                                        }
                                      })
                                      }
                                    </TableCell>
                                  )
                                }
                                else {
                                  return (<TableCell></TableCell>)
                                }
                              })
                              }
                              {Object.keys(subordinateFormData).length !== 0 && Object.keys(subordinateFormData).map((subordinateInfo) => {
                                return (
                                  labelData.choiceGroups.map((choiceGroupData) => {
                                    return (
                                      <React.Fragment>
                                        <TableCell>
                                          <p>{getSubordinateSubmittedValues(subordinateInfo, labelData, subLabelData, choiceGroupData)}</p>
                                        </TableCell>
                                        {labelData.subLabels.length !== 0 && labelData.subLabels.map((subLabelData) => {
                                          return (
                                            subLabelData.choiceGroups.map((choiceGroupData) => {
                                              return (
                                                <TableCell>
                                                  <p>{getSubordinateSubmittedValues(subordinateInfo, labelData, subLabelData, choiceGroupData)}</p>
                                                </TableCell>
                                              )
                                            })
                                          )
                                        })}
                                        {
                                          labelData.subLabels.length === 0 && (
                                            <TableCell></TableCell>
                                          )
                                        }
                                      </React.Fragment>
                                    )
                                  }))
                              })
                              }
                              {//ChangeHistoryCode commented for future use
                                    /*Object.keys(changeHistory).length !== 0 && Object.keys(changeHistory[labelData.labelId]["labelData"]).map((historyLabelData, j) => {
                                      return (
                                        <TableCell>
                                          <p>{changeHistory[labelData.labelId]["labelData"][historyLabelData].value}</p>
                                        </TableCell>
                                      )
                                    })
                                  */}
                              {//ChangeHistoryCode commented for future use
                                    /*Object.keys(changeHistory).length !== 0 && Object.keys(changeHistory[labelData.labelId]["subLabelData"]).map((historySubLabelData, j) => {
                                      return (
                                        <TableCell>
                                          <p>{changeHistory[labelData.labelId]["subLabelData"][historySubLabelData].value}</p>
                                        </TableCell>
                                      )
                                    })
                                  */}
                            </TableRow>
                          )
                        })
                        }
                      </React.Fragment>
                    )
                  })}
                </Table>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Dialog
          onClose={handleValidationDialogClose}
          aria-labelledby="RFA-form-information-dialog"
          open={validationWarningDialog}
          fullScreen={false}
        >
          <DialogTitle>
            Incomplete form!
          </DialogTitle>
          <DialogContent>
            <Typography>
              Please provide answers to all labels to submit, if you are approving this form please enter overall comments.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleValidationDialogClose} autofocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    toFormOpen: state.rfaReducer.toFormOpen,
    toFormClose: state.rfaReducer.toFormClose,
    formDetails: state.rfaReducer.formDetails,
    roles: state.authReducer.roles,
    closedRFA: state.rfaReducer.closedRFA
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackBar: (payload) => dispatch(setSnackBar(payload)),
    setLoader: (payload) => dispatch(setLoader(payload)),
    setToFormOpen: (payload) => dispatch(setToFormOpen(payload)),
    setToFormClose: (payload) => dispatch(setToFormClose(payload)),
    setFormDetails: (payload) => dispatch(setFormDetails(payload)),
    setClosedRFA: (payload) => dispatch(setClosedRFA(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(RFAForm)));
