import HttpUtils from "./httpUtils";
import config from "./../config/config";
import { subMonths } from "date-fns";
import ReviewUtils from "./reviewUtils";

const fetchFilterDetails = async () => {
  // const data = await HttpUtils.Get(`${config.apiUrl}/masterdata/organizationlevels`);
  const data = await HttpUtils.Get(`${config.apiUrl}/dashboard/filterdetails`);
  return data?.data;
};

const formatFilterDetails = (data, options) => {
  // let resultObj = {};
  // (options ?? []).forEach((o) => (resultObj[o["name"]] = []));

  // (data ?? []).forEach((item) => {
  //   const name = options?.find((o) => o.value === item["OrganizationHierarchyId"])?.name;
  //   if (name && resultObj[name]) {
  //     resultObj[name].push({
  //       label: item["DisplayName"],
  //       value: item["DisplayName"],
  //       name: item["DisplayName"],
  //     });
  //   }
  // });
  // return resultObj;
  let resultArray = {
    BusinessUnit: [],
    Circle: [],
    Network: [],
    Module: [],
    Region: [],
    Branch: [],
    Account: [],
  };
  data.forEach((item) => {
    resultArray[item["FilterType"]].push({
      label: item["FilterId"],
      value: item["FilterValue"],
      name: item["FilterId"],
    });
  });
  return resultArray;
};

const fetchFilterOptions = async () => {
  const data = await HttpUtils.Get(`${config.apiUrl}/masterdata/organizationhierarchy`);
  return data?.data?.map((i) => {
    return {
      label: i["DisplayName"],
      value: i["OrganizationHierarchyId"],
      name: i["DisplayName"],
      type: "Select",
      apiKey: i["LevelName"],
      multiSelect: true,
    };
  });
};

const getDefaultCurrentDate = () => {
  return new Date();
};

const getDefaultCompareDate = () => {
  let monthsToRewind = 6;
  let actualCurrentDate = new Date();
  let mutatedCurrentDate = new Date();
  mutatedCurrentDate.setDate(1);
  mutatedCurrentDate.setMonth(actualCurrentDate.getMonth() - monthsToRewind);
  let priorMonthOldDate = (new Date(mutatedCurrentDate.getFullYear(), mutatedCurrentDate.getMonth(), 1));
  let lastDayOfPriorMonth = (new Date(priorMonthOldDate.getFullYear(), priorMonthOldDate.getMonth() + 1, 0)).getDate();
  if (actualCurrentDate.getDate() < lastDayOfPriorMonth) {
    return (new Date(mutatedCurrentDate.getFullYear(), mutatedCurrentDate.getMonth(), actualCurrentDate.getDate()));
  }
  else {
    return new Date(priorMonthOldDate.getFullYear(), priorMonthOldDate.getMonth(), lastDayOfPriorMonth);
  }
};

const getDefaultTriggerType = () => {
  return 1;
};

const getDefaultTriggerEndDate = () => {
  return getLastDayOfPreviousMonth();
};

const getDefaultTriggerStartDate = () => {
  return subMonths(new Date(), 3);
};

function getLastDayOfPreviousMonth() {
  const today = new Date();
  today.setDate(0); // Set the day of the date to 0, which rolls back to the last day of the previous month.
  return today;
}

const getAllTriggerTypes = () => {
  return [
    {
      id: 1,
      key: "structured",
      name: "Transaction Triggers",
    },
    {
      id: 2,
      key: "unstructured",
      name: "External Triggers",
    },
    {
      id: 3,
      key: "llms",
      name: "Loan Organization Triggers",
    },
  ];
};

const getTrigger = (triggerTypeId) => {
  return getAllTriggerTypes()?.find((item) => item.id === triggerTypeId);
};

const fetchSuggestiveActions = async () => {
  const data = await HttpUtils.Get(`${config.apiUrl}/review/actions`);
  return data?.data;
};

const DataUtils = {
  FetchFilterDetails: fetchFilterDetails,
  FormatFilterDetails: formatFilterDetails,
  FetchFilterOptions: fetchFilterOptions,
  GetDefaultCurrentDate: getDefaultCurrentDate,
  GetDefaultCompareDate: getDefaultCompareDate,
  GetDefaultTriggerStartDate: getDefaultTriggerStartDate,
  GetDefaultTriggerEndDate: getDefaultTriggerEndDate,
  GetDefaultTriggerType: getDefaultTriggerType,
  GetAllTriggerTypes: getAllTriggerTypes,
  GetTrigger: getTrigger,
  FetchSuggestiveActions: fetchSuggestiveActions,
};

export default DataUtils;
