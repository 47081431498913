import React from "react";
import { Grid } from "@material-ui/core";
import OverviewItem from "./OverviewItem";
import NPACard from "./NPACard";

import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { setOverview } from "../../actions/dashboardActions";
import FormatUtils from "./../../utils/formatUtils";

let styles = (theme) => ({
  icon: {
    padding: theme.spacing(1),
    background: `#fff`,
    borderRadius: theme.spacing(2),
    fontWeight: `bolder`,
  },
});

const items = [
  {
    name: "Current Exposure",
    title: "Current Exposure",
    icon: "CE",
    bgClass: "bg-primary",
    percentageField: true,
    type: "float",
    visible: false,
  },
  {
    name: "Potential Exposure",
    title: "Potential Exposure",
    icon: "PE",
    bgClass: "bg-success",
    percentageField: true,
    type: "float",
    visible: false,
  },
  {
    name: "TFL",
    title: "TFL",
    icon: "TF",
    bgClass: "bg-info",
    percentageField: true,
    type: "float",
    visible: false,
  },
  {
    name: "Willful Defaulters",
    title: "Willful Defaulters",
    icon: "WD",
    bgClass: "bg-warning",
    percentageField: false,
    type: "integer",
    visible: true,
  },
  {
    name: "Red Flags",
    title: "Red Flags",
    icon: "RF",
    bgClass: "bg-danger",
    percentageField: false,
    type: "integer",
    visible: true,
  },
  {
    name: "SMA2Accounts",
    title: "SMA2 Accounts",
    icon: "SM",
    bgClass: "bg-secondary",
    percentageField: false,
    type: "integer",
    visible: true,
  },
];

const TopLayer = (props) => {
  const { classes, overview } = props;

  const getItem = (name) => {
    return overview?.find((el) => el.MeasureName === name);
  };

  return (
    <React.Fragment>
      <Grid item lg={9} md={9} sm={12} xs={12}>
        <Grid container spacing={1}>
          {items
            ?.filter((i) => i.visible)
            .map((item) => {
              return (
                <Grid item lg={4} md={4} sm={6} xs={12} key={item.name}>
                  <OverviewItem
                    title={item.title}
                    value={
                      item.type === "float"
                        ? FormatUtils.FormatNumberToCurrency(getItem(item.name)?.Value || 0)
                        : FormatUtils.FormatNumberToInteger(getItem(item.name)?.Value || 0)
                    }
                    bgClass={item.bgClass}
                    difference={FormatUtils.FormatNumberToPercentage(getItem(item.name)?.PercentageDifference || 0)}
                    icon={<span className={classes.icon}>{item.icon}</span>}
                    percentageField={item.percentageField}
                  ></OverviewItem>
                </Grid>
              );
            })}
        </Grid>
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <NPACard icon={<span className={classes.icon}>NPA</span>} bgClass={"bg-warning"} data={overview}></NPACard>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    overview: state.dashboardReducer.overview,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOverview: (payload) => dispatch(setOverview(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TopLayer));
