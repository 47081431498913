import HttpUtils from "./httpUtils";
import config from "../config/config";

const fetchMISReports = async (reportName, payload) => {
  const data = await HttpUtils.Post(
    `${config.apiUrl}/MISReports/reports?reportName=${reportName}`, payload);
  return data?.data;
}

const fetchXLSXReport = async (reportName, payload) => {
  const data = await HttpUtils.Post(
    `${config.apiUrl}/MISReports/generateXLSXReport?reportName=${reportName}`, payload, {
    responseType: 'arraybuffer'
  }
  );
  return data?.data;
}

const fetchAlertStatusSummary = async (payload) => {
  const data = await HttpUtils.Post(
    `${config.apiUrl}/MISReports/alertStatusSummary`, payload
  );
  return data?.data;
}

const fetchRFAStatusSummary = async (payload) => {
  const data = await HttpUtils.Post(
    `${config.apiUrl}/MISReports/rfaStatusSummary`, payload
  );
  return data?.data;
}

const fetchFilterValues = async (payload) => {
  const data = await HttpUtils.Post(
    `${config.apiUrl}/MISReports/filterValues`, payload);
  return data?.data;
}

const saveBookmark = async (payload) => {
  const data = await HttpUtils.Post(
    `${config.apiUrl}/MISReports/saveBookmark`, payload);
  return data?.data;
}

const deleteBookmark = async (bookmarkId) => {
  const data = await HttpUtils.Post(
    `${config.apiUrl}/MISReports/deleteBookmark?bookmarkId=${bookmarkId}`);
  return data?.data;
}

const fetchBookmarks = async (payload) => {
  const data = await HttpUtils.Get(
    `${config.apiUrl}/MISReports/retrieveBookmarks`, payload);
  return data?.data;
}

const MISReportsUtils = {
  FetchMISReports: fetchMISReports,
  FetchXLSXReport: fetchXLSXReport,
  FetchAlertStatusSummary: fetchAlertStatusSummary,
  FetchRFAStatusSummary: fetchRFAStatusSummary,
  FetchFilterValues: fetchFilterValues,
  SaveBookmark: saveBookmark,
  DeleteBookmark: deleteBookmark,
  FetchBookmarks: fetchBookmarks
};

export default MISReportsUtils;
