import { Card, CardContent, CardHeader, withStyles } from "@material-ui/core";
import React from "react";
import IPivotGrid from "../common/IPivotGrid";
import { connect } from "react-redux";
import ITooltip from "../common/ITooltip";

let styles = (theme) => ({
  root: {},
  cardHeader: {
    textAlign: "left",
    borderBottom: `1px solid #ccc`,
    background: "#f7f7f7",
    "& span": {
      fontWeight: "bold",
      fontSize: theme.spacing(2),
    },
  },
});

const SlicenDice = (props) => {
  const { classes } = props;
  return (
    <div>
      {props.analytics && (
        <Card>
          <CardHeader
            title={"Slice 'n Dice"}
            className={classes.cardHeader}
            action={
              <ITooltip
                title={
                  <div>
                    <p>
                      This pivot grid gives the ability to do an out-and-out
                      analysis of loans for selected filters.
                    </p>

                    <p>
                      You can slice and dice both rows and columns, export and
                      print reports here. Play around!
                    </p>
                  </div>
                }
              />
            }
          />
          <CardContent>
            <IPivotGrid data={props.analytics} />
            {console.log("changed")}
          </CardContent>
        </Card>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    analytics: state.analyticsReducer.analytics,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SlicenDice));
