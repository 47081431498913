import React from "react";
import IStepper from "./../common/IStepper";
import { Button, Grid, Tooltip, withStyles, useMediaQuery, useTheme, Box, Dialog, Typography, DialogTitle, DialogActions, DialogContent } from "@material-ui/core";
import { connect } from "react-redux";
import {
  setAlertClosureHistory,
  setFinishEnabled,
  setOverallComments,
  setOverallCommentsHistory,
  setReviewActions,
  setReviewHistory,
  setReviewRemarksHistory,
  setTriggerDate,
} from "../../actions/reviewActions";
import HelperUtils from "../../utils/helperUtils";
import ReviewSection from "./ReviewSection";
import ReviewUtils from "./../../utils/reviewUtils";
import { setSnackBar } from "../../actions/commonActions";
import ReviewSummary from "./ReviewSummary";
import { setAccountTriggers } from "../../actions/accountActions";
import IDialog from "../common/IDialog";
import { useState, useEffect } from "react";
import AlertRework from "../reviewhistory/AlertRework";
import IKeyValuePair from "../common/IKeyValuePair";
import RiskGradeBenchmark from "../dashboard/RiskGradeBenchmark";
import FormatUtils from "../../utils/formatUtils";
import DataUtils from "../../utils/dataUtils";
import SettingsUtils from "../../utils/settingsUtils";
import { setUserSettings } from "../../actions/settingsActions";
import { Redirect, useHistory } from "react-router-dom/cjs/react-router-dom";
import { setToFormOpen, setToFormClose, setFormDetails, setToFormReview } from "../../actions/rfaActions";
import RFAUtils from "../../utils/rfaUtils";

let styles = (theme) => ({
  alignRight: {
    textAlign: "right",
    fontSize: theme.spacing(2),
    color: "black",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  conflict: {
    textAlign: "left",
    fontSize: theme.spacing(1.5),
  },
  redLabel: {
    color: "red",
  },
  pendingItems: {
    paddingRight: theme.spacing(1),
  },
  red: {
    backgroundColor: `red`,
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: theme.spacing(3),
    marginRight: theme.spacing(1),
    borderRadius: `50%`,
    display: `inline-block`,
    verticalAlign: `bottom`,
  },
  amber: {
    background: `#FFC200`,
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: theme.spacing(3),
    marginRight: theme.spacing(1),
    borderRadius: `50%`,
    display: `inline-block`,
    verticalAlign: `bottom`,
  },
  green: {
    background: `green`,
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: theme.spacing(3),
    marginRight: theme.spacing(1),
    borderRadius: `50%`,
    display: `inline-block`,
    verticalAlign: `bottom`,
  },
});

const SuggestiveActions = (props) => {
  const theme = useTheme();
  const { classes, onSubmit } = props;
  const [isReviewSuccess, setIsReviewSuccess] = useState(true);
  const [finishMessage, setFinishMessage] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [borrowerId, setBorrowerId] = useState();
  const [rfaDialogOpen, setRFADialogOpen] = useState(false);
  const isUnderSmallSizeScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  const onLoad = () => {
    SettingsUtils.FetchUserSetting().then((data) => {
      props.setUserSettings(data)
    });
    ReviewUtils.FetchAccountPendingAlerts(props.selectedAccount?.value).then((data) => {
      setBorrowerId(data?.[0]?.BorrowerId)
    });
  };

  useEffect(onLoad, []);

  const handleNext = (index) => {
    console.log("Moving forward", index);
  };

  const handlePrevious = (index) => {
    console.log("Moving back", index);
  };

  const isSomeoneElseReviewing = () => {
    var whoIsReviewing = ReviewUtils.IsSomeoneElseReviewing(props.userReviewInitiators, props.user?.UserId);
    console.log("Assigned to", whoIsReviewing);
    return whoIsReviewing;
  };

  const handleFinish = () => {
    const payload = formReviewPayload();

    ReviewUtils.PostReview(payload)
      .then((data) => {
        props.setReviewActions({});
        props.setOverallComments("");
        props.setFinishEnabled(false);
        props.setSnackBar("Review added successfully!");
        if (onSubmit) {
          onSubmit(true);
        }
        setFinishMessage("Review complete!");
      })
      .catch((err) => {
        props.setSnackBar(err?.response?.data);
        setIsReviewSuccess(false);
        setFinishMessage("Review failed!");
        return false;
      });
    return true;
  };

  const formReviewPayload = () => {
    const { reviewActions: actions, user, selectedAccount, triggerDate } = props;

    let reviews = [];
    let remarks = {};
    let reviewNames = [];
    const timestamp = new Date();
    const currentUser = user?.EmailAddress;

    const trivialInfo = {
      AccountNumber: selectedAccount.value,
      CurrentDate: FormatUtils.FormatToPrettyDate(triggerDate || new Date()),
      ReviewDate: timestamp,
      ReviewedBy: currentUser,
      ModifiedBy: currentUser,
      ModifiedDate: timestamp,
    };

    Object.entries(actions).forEach(([key, value]) => {
      const suggestiveActions = HelperUtils.ExtractProperty(
        value?.SuggestiveActions,
        "value"
      );
      const suggestiveActionName = HelperUtils.ExtractProperty(
        value?.SuggestiveActions,
        "name"
      );
      remarks[value.TriggerId] = value.Remarks;
      reviews = [...reviews, ...suggestiveActions];
      reviewNames = [...reviewNames, ...suggestiveActionName];
    });

    const reviewActions = reviews.map((item) => {
      return {
        SuggestiveActionId: item,
        ...trivialInfo,
      };
    });

    const reviewRemarks = Object.values(actions).map((item) => {
      return {
        TriggerId: item.TriggerId,
        Remarks: remarks[item.TriggerId],
        ...trivialInfo,
      };
    });

    const reviewComments = [
      {
        comments: props.overallComments,
        ...trivialInfo,
      },
    ];

    return {
      reviewActions,
      reviewRemarks,
      reviewComments,
      reviewNames
    };
  };

  const handleModify = () => {
    console.log("Modifying");
  };

  const handleActionComplete = (item) => {
    const timestamp = new Date();
    const currentUser = props.user?.User?.Username;
    const accountNumber = props.selectedAccount.value;

    const completedItem = {
      ...item,
      IsComplete: true,
      CompletedDate: timestamp,
      ModifiedBy: currentUser,
      ModifiedDate: timestamp,
      SuggestiveAction: null,
    };

    ReviewUtils.MarkComplete(accountNumber, completedItem).then((data) => {
      props.setReviewHistory(data.UserReviews);
    });
  };

  const formatSteps = (accountTriggers) => {
    let index = 0;
    const categories = HelperUtils.GroupBy(accountTriggers, (item) => item.Category);

    let steps = [];
    Object.keys(categories).forEach((key) => {
      const data = categories[key];
      const criticalData = data?.filter((item) => item.Red > 0);
      if (criticalData.length > 0) {
        steps.push({
          label: key,
          index: index++,
          content: <ReviewSection data={criticalData} onActionComplete={handleActionComplete} />,
        });
      }
    });

    const reviewSteps = addCommentsStep(steps);
    return reviewSteps;
  };

  const addCommentsStep = (steps) => {
    return [...steps, { label: "Review Summary", index: steps.length, content: <ReviewSummary hidden={!shouldEnableClose()} /> }];
  };

  const getOpenAlerts = () => {
    const closedAlerts = props.alertClosureHistory?.filter((i) => i.IsActive && i.CreatedBy === props?.user?.EmailAddress)?.length ?? 0;
    return (props.accountTriggers?.length ?? 0) - closedAlerts;
  };

  const getPendingAlerts = () => {
    const openAlerts = props.accountTriggers?.filter(
      (t) => !props.alertClosureHistory?.some((i) => i.IsActive && i.CreatedBy === props?.user?.EmailAddress && i.TriggerId === t.TriggerId)
    );
    const result = {
      Red: openAlerts?.filter((i) => i.Red !== 0)?.length ?? 0,
      Amber: openAlerts?.filter((i) => i.Amber !== 0)?.length ?? 0,
      Green: openAlerts?.filter((i) => i.Green !== 0)?.length ?? 0,
    };
    return result;
  };

  const getPendingTriggerCount = () => {
    const totalTriggers = getOpenAlerts();

    const triggerDate = props.triggerDate ?? DataUtils.GetDefaultTriggerEndDate();
    const filteredReviewHistory = props.accountReviewHistory?.filter(
      (i) => FormatUtils.FormatToPrettyDate(i.TriggerDate) === FormatUtils.FormatToPrettyDate(triggerDate)
    );
    const reviewedTriggers = HelperUtils.ExtractProperty(filteredReviewHistory, "TriggerId");
    const unique = [...new Set(reviewedTriggers)];

    return totalTriggers - unique.length;
  };

  const shouldEnableClose = () => {
    const accountNumber = props.selectedAccount.value;
    const accountTriggers = props.accountTriggers;
    const reviewHistory = props.reviewHistory;
    const userReviewInitiators = props.userReviewInitiators;
    const closedAlerts = props.closedAlerts;
    const alertClosureHistory = props.alertClosureHistory;
    const userRoles = props.roles;
    const user = props.user;

    return ReviewUtils.IsAlertClosable(
      accountNumber,
      accountTriggers,
      reviewHistory,
      userReviewInitiators,
      closedAlerts,
      alertClosureHistory,
      userRoles,
      user,
      props.triggerDate ?? DataUtils.GetDefaultTriggerEndDate()
    );
  };

  const shouldEnableReassign = () => {
    const { roles: userRoles, user, alertClosureHistory } = props;
    return ReviewUtils.IsAlertReassignable(userRoles, user, alertClosureHistory);
  };

  const handleReassign = () => {
    setDialogOpen(true);
  };

  const handleCloseAlert = () => {
    const timestamp = new Date();
    const currentUser = props.user?.User?.EmailAddress || props.user?.EmailAddress;
    const accountNumber = props.selectedAccount.value;
    const isController = props.roles?.some((i) => i.OrganizationRoles.IsController);

    const payload = {
      ReviewClosures: [],
    };

    payload.ReviewClosures.push({
      TriggerId: null,
      AccountNumber: accountNumber,
      TriggerDate: props.triggerDate,
      Remarks: props.overallComments ?? "(No comments)",
      IsFinalClosed: isController,
      CreatedBy: currentUser,
      CreatedDate: timestamp,
      ModifiedBy: currentUser,
      ModifiedDate: timestamp,
      SuggestiveAction: null,
    });

    ReviewUtils.CloseAccountAlerts(accountNumber, props.triggerDate, payload).then((data) => {
      props.setAccountTriggers(data.Triggers.Triggers);
      props.setReviewHistory(data.UserReviews);
      props.setReviewRemarksHistory(data.UserReviewRemarks);
      props.setOverallCommentsHistory(data.UserReviewComments);
      props.setAlertClosureHistory(data.UserReviewClosures);
      props.setTriggerDate(data.TriggerDate);
      props.setSnackBar("Alert closed successfully");

      //Check if there is a red flag raised to this account for this borrower and this trigger date by this user
      RFAUtils.FetchReviewRedFlagStatus({
        BorrowerId: borrowerId,
        TriggerDate: data.TriggerDate
      }).then((reviewRedFlagStatus) => {
        if (reviewRedFlagStatus.RaisedARedFlag) {
          RFAUtils.FetchRFAFormDetails(borrowerId).then((formDetails) => {
            if (formDetails.FormIdentifier === null) {
              //there is no existing form for this borrower so create a new one
              //call create and get API
              RFAUtils.FetchReviewRFA({
                BorrowerId: borrowerId,
                TriggerDate: data.TriggerDate
              }).then((data) => {
                props.setFormDetails({
                  "FormIdentifier": data.FormIdentifier,
                  "FormId": data.FormId,
                  "BorrowerId": data.BorrowerId,
                  "IsPendingWith": data.IsPendingWith,
                  "IsAlreadySubmitted": data.IsAlreadySubmitted,
                  "IsPendingSubmission": data.IsPendingSubmission
                });
                props.setSnackBar("You are now being redirected to a form");
                props.setToFormOpen(true);
                history.push("/rfa", {
                  "FormIdentifier": data.FormIdentifier,
                  "FormId": data.FormId,
                  "BorrowerId": data.BorrowerId,
                  "IsPendingWith": data.IsPendingWith,
                  "IsAlreadySubmitted": data.IsAlreadySubmitted,
                  "IsPendingSubmission": data.IsPendingSubmission
                })
              })
            }
            else if (formDetails.IsPendingWith) {
              history.push("/rfa", {
                "FormIdentifier": formDetails.FormIdentifier,
                "FormId": formDetails.FormId,
                "BorrowerId": formDetails.BorrowerId,
                "IsPendingWith": formDetails.IsPendingWith,
                "IsAlreadySubmitted": formDetails.IsAlreadySubmitted,
                "IsPendingSubmission": formDetails.IsPendingSubmission
              })
            }
            else if (!formDetails.IsPendingWith) {
              //Popup if there is a red flag already assigned to another user.
              setRFADialogOpen(true);
            }
          })
        }
        else {
          if (onSubmit) {
            onSubmit();
          }
        }
      })
    });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleRFADialogClose = () => {
    setRFADialogOpen(false);
    if (onSubmit) {
      onSubmit();
    }
  };

  const handleReworkSubmit = (payload) => {
    const requestBody = {
      UserId: payload.UserId,
      Remarks: payload.Remarks,
      CreatedBy: payload.CreatedBy,
      CreatedDate: payload.CreatedDate,
      ModifiedBy: payload.ModifiedBy,
      ModifiedDate: payload.ModifiedDate,
    };
    const accountNumber = props.selectedAccount.value;
    ReviewUtils.ReopenAccountAlerts(accountNumber, props.triggerDate, requestBody).then((data) => {
      setDialogOpen(false);
      props.setAccountTriggers(data.Triggers.Triggers);
      props.setReviewHistory(data.UserReviews);
      props.setReviewRemarksHistory(data.UserReviewRemarks);
      props.setOverallCommentsHistory(data.UserReviewComments);
      props.setAlertClosureHistory(data.UserReviewClosures);
      props.setTriggerDate(data.TriggerDate);
      props.setSnackBar("Alert has been re-assigned successfully");
      if (onSubmit) {
        onSubmit();
      }
    });
  };

  const getPendingTriggers = (items) => {
    const pendingItems = items
      ?.filter(
        (i) =>
          i.CreatedBy !== props.user?.EmailAddress &&
          i.IsActive &&
          !i.IsFinalClosed &&
          !items?.some((p) => p.CreatedBy === props?.user?.EmailAddress && p.TriggerId === i.TriggerId && p.IsActive)
      )
      ?.map((i) => {
        return { ...i, TriggerName: props.accountTriggers?.find((t) => i.TriggerId === t.TriggerId)?.TriggerName };
      });

    return pendingItems?.length > 0 && getOpenAlerts() > 0;
  };

  const shouldShowAllSteps = () => {
    return !props.isAlertClosed;
  };

  const stepperOrientationStyle = () => {
    var settingValue = props.userSettings.find((i) => i.Section == "Review" && i.Name == "Orientation")?.Value
    if (settingValue === undefined || settingValue === "dynamic" || settingValue === "responsive") {
      return isUnderSmallSizeScreen ? "vertical" : "horizontal";
    }
    else {
      return settingValue;
    }
  };

  const canAddReview = () => {
    return props?.roles?.some((i) => i?.OrganizationRoles?.CanAddReviews);
  };

  return (
    <div>
      {props.selectedAccount?.value && props.accountTriggers.length > 0 && (
        <div>
          {getOpenAlerts() > 0 && (
            <div className={classes.alignRight}>
              {Object.entries(getPendingAlerts() ?? {})?.map(([key, value]) => {
                return (
                  <Box m={2} display="inline-flex">
                    <Tooltip title={`${FormatUtils.ToTitleCase(key)} loans`} aria-label={`${key}-loans`}>
                      <Button className={classes[key.toLowerCase()]} />
                    </Tooltip>
                    {value}
                  </Box>
                );
              })}
            </div>
          )}

          {getPendingTriggerCount() > 0 && (
            <div className={classes.alignRight}>
              <div>
                <span className={classes.redLabel}>
                  <strong>{getPendingTriggerCount()}</strong>
                </span>
                /<strong>{props.accountTriggers?.length ?? 0}</strong> triggers require attention
              </div>
            </div>
          )}

          {isSomeoneElseReviewing() && (
            <div className={classes.alignRight}>
              <div>
                <span className={classes.conflict}>
                  <span>
                    This account is currently being reviewed by <strong>{isSomeoneElseReviewing()}</strong>
                  </span>
                </span>
              </div>
            </div>
          )}
          <IStepper
            steps={formatSteps(props.accountTriggers)}
            title={<div>Review</div>}
            finishMessage={finishMessage}
            finishEnabled={props.finishEnabled && canAddReview()}
            finishSuccess={isReviewSuccess}
            closeEnabled={shouldEnableClose()}
            reopenEnabled={shouldEnableReassign()}
            resetEnabled={false}
            resetText={"Modify"}
            onReset={handleModify}
            onFinish={handleFinish}
            onPrevious={(index) => handlePrevious(index)}
            onNext={(index) => handleNext(index)}
            onCloseAlert={handleCloseAlert}
            onReopenAlert={handleReassign}
            orientationStyle={stepperOrientationStyle()}
            showAllSteps={shouldShowAllSteps()}
            showLastStep={shouldEnableClose()}
          />
          <IDialog
            title={<div>Reopen alert</div>}
            fullScreen={false}
            open={dialogOpen}
            content={<AlertRework accountLevel={true} onSubmit={handleReworkSubmit} />}
            onClose={handleDialogClose}
          ></IDialog>
          <Dialog
            onClose={handleRFADialogClose}
            aria-labelledby="RFA-form-information-dialog"
            open={rfaDialogOpen}
            fullScreen={false}
          >
            <DialogTitle>
              Account is already considered as Red-flagged account
            </DialogTitle>
            <DialogContent>
              <Typography>
                You have raised a Red Flag in this review but this account is already red flagged,
                a RFA form already exists for this borrower and is pending with another user.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRFADialogClose} autofocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    reviewActions: state.reviewReducer.reviewActions,
    user: state.authReducer.user,
    accountTriggers: state.accountReducer.accountTriggers,
    selectedAccount: state.accountReducer.selectedAccount,
    currentDate: state.dashboardReducer.currentDate,
    finishEnabled: state.reviewReducer.finishEnabled,
    overallComments: state.reviewReducer.overallComments,
    alertClosureHistory: state.reviewReducer.alertClosureHistory,
    reviewHistory: state.reviewReducer.reviewHistory,
    triggerDate: state.reviewReducer.triggerDate,
    roles: state.authReducer.roles,
    isAlertClosed: state.reviewReducer.isAlertClosed,
    userSettings: state.settingReducer.userSettings,
    accountReviewHistory: state.reviewReducer.accountReviewHistory,
    userReviewInitiators: state.reviewReducer.userReviewInitiators,
    closedAlerts: state.reviewReducer.closedAlerts,
    toFormOpen: state.rfaReducer.toFormOpen,
    toFormClose: state.rfaReducer.toFormClose,
    formDetails: state.rfaReducer.formDetails,
    toFormReview: state.rfaReducer.toFormReview,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackBar: (payload) => dispatch(setSnackBar(payload)),
    setAccountTriggers: (payload) => dispatch(setAccountTriggers(payload)),
    setFinishEnabled: (payload) => dispatch(setFinishEnabled(payload)),
    setReviewActions: (payload) => dispatch(setReviewActions(payload)),
    setReviewHistory: (payload) => dispatch(setReviewHistory(payload)),
    setReviewRemarksHistory: (payload) => dispatch(setReviewRemarksHistory(payload)),
    setOverallCommentsHistory: (payload) => dispatch(setOverallCommentsHistory(payload)),
    setAlertClosureHistory: (payload) => dispatch(setAlertClosureHistory(payload)),
    setTriggerDate: (payload) => dispatch(setTriggerDate(payload)),
    setUserSettings: (payload) => dispatch(setUserSettings(payload)),
    setOverallComments: (payload) => dispatch(setOverallComments(payload)),
    setToFormOpen: (payload) => dispatch(setToFormOpen(payload)),
    setToFormClose: (payload) => dispatch(setToFormClose(payload)),
    setFormDetails: (payload) => dispatch(setFormDetails(payload)),
    setToFormReview: (payload) => dispatch(setToFormReview(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SuggestiveActions));