import DataUtils from "../utils/dataUtils";
import {
  SET_ACCOUNT_REVIEW_HISTORY,
  SET_FINISH_ENABLED,
  SET_REVIEW_ACTIONS,
  SET_REVIEW_HISTORY,
  SET_REVIEWREMARKS_HISTORY,
  SET_SUGGESTIVE_ACTIONS,
  SET_OVERALL_COMMENTS,
  SET_OVERALL_COMMENTS_HISTORY,
  SET_ESCALATIONS,
  SET_TRIGGER_DATE,
  SET_ALERT_CLOSURE_HISTORY,
  SET_SELECTED_ALERT_REWORK,
  SET_NPA_MOVEMENT,
  SET_ACCOUNTS_WITH_PENDING_ALERTS,
  SET_SELECTED_TAB_INDEX,
  SET_ACCOUNT_PENDING_ALERTS,
  SET_CLOSED_ALERTS,
  SET_ALERTS_CLOSED_BY_SUBORDINATES,
  SET_IS_ALERT_CLOSED,
  SET_REASSIGNED_ALERTS,
  SET_USER_REVIEW_INITIATORS,
} from "./../actions/reviewActions";

const initialState = {
  reviewActions: {},
  suggestiveActions: [],
  reviewHistory: [],
  reviewRemarksHistory: [],
  finishEnabled: false,
  accountReviewHistory: [],
  overallComments: "",
  overallCommentsHistory: [],
  escalations: [],
  triggerDate: DataUtils.GetDefaultTriggerEndDate(),
  alertClosureHistory: [],
  selectedAlertRework: null,
  npaMovement: {},
  accountsWithPendingAlerts: [],
  selectedTabIndex: 0,
  accountPendingAlerts: [],
  closedAlerts: [],
  alertsClosedBySubordinates: [],
  isAlertClosed: false,
  reassignedAlerts: [],
  userReviewInitiators: {},
};

const reviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REVIEW_ACTIONS:
      return {
        ...state,
        reviewActions: action.payload,
      };
    case SET_SUGGESTIVE_ACTIONS:
      return {
        ...state,
        suggestiveActions: [...action.payload],
      };
    case SET_REVIEW_HISTORY:
      return {
        ...state,
        reviewHistory: [...action.payload],
      };
    case SET_REVIEWREMARKS_HISTORY:
      return {
        ...state,
        reviewRemarksHistory: [...action.payload],
      };
    case SET_FINISH_ENABLED:
      return {
        ...state,
        finishEnabled: action.payload,
      };
    case SET_ACCOUNT_REVIEW_HISTORY:
      return {
        ...state,
        accountReviewHistory: [...action.payload],
      };
    case SET_OVERALL_COMMENTS:
      return {
        ...state,
        overallComments: action.payload,
      };
    case SET_OVERALL_COMMENTS_HISTORY:
      return {
        ...state,
        overallCommentsHistory: [...action.payload],
      };
    case SET_ESCALATIONS:
      return {
        ...state,
        escalations: [...action.payload],
      };
    case SET_TRIGGER_DATE:
      return {
        ...state,
        triggerDate: action.payload,
      };
    case SET_ALERT_CLOSURE_HISTORY:
      return {
        ...state,
        alertClosureHistory: [...action.payload],
      };
    case SET_SELECTED_ALERT_REWORK:
      return {
        ...state,
        selectedAlertRework: [...action.payload],
      };
    case SET_NPA_MOVEMENT:
      return {
        ...state,
        npaMovement: { ...action.payload },
      };
    case SET_ACCOUNTS_WITH_PENDING_ALERTS:
      return {
        ...state,
        accountsWithPendingAlerts: [...action.payload],
      };
    case SET_SELECTED_TAB_INDEX:
      return {
        ...state,
        selectedTabIndex: action.payload,
      };
    case SET_ACCOUNT_PENDING_ALERTS:
      return {
        ...state,
        accountPendingAlerts: [...action.payload],
      };
    case SET_CLOSED_ALERTS:
      return {
        ...state,
        closedAlerts: [...action.payload],
      };
    case SET_ALERTS_CLOSED_BY_SUBORDINATES:
      return {
        ...state,
        alertsClosedBySubordinates: [...action.payload],
      };
    case SET_IS_ALERT_CLOSED:
      return {
        ...state,
        isAlertClosed: action.payload,
      };
    case SET_REASSIGNED_ALERTS:
      return {
        ...state,
        reassignedAlerts: [...action.payload],
      };
    case SET_USER_REVIEW_INITIATORS:
      return {
        ...state,
        userReviewInitiators: { ...action.payload },
      };
    default:
      return state;
  }
};

export default reviewReducer;
