import HttpUtils from "./httpUtils";
import config from "./../config/config";
import FormatUtils from "./formatUtils";

const postReview = async (payload) => {
  const data = await HttpUtils.Post(`${config.apiUrl}/review`, payload);
  return data?.data;
};

const fetchAccountReviewHistory = async (accountNumber) => {
  const data = await HttpUtils.Get(`${config.apiUrl}/review/history/${accountNumber}`);
  return data?.data;
};

const markComplete = async (accountNumber, payload) => {
  const data = await HttpUtils.Post(`${config.apiUrl}/review/complete/${accountNumber}`, payload);
  return data?.data;
};

const fetchEscalations = async (currentDate) => {
  const data = await HttpUtils.Get(`${config.apiUrl}/review/escalations/${currentDate}`);
  return data?.data;
};

const closeAlert = async (accountNumber, payload) => {
  const data = await HttpUtils.Post(`${config.apiUrl}/review/closealert/${accountNumber}`, payload);
  return data?.data;
};

const reopenAlert = async (payload) => {
  const data = await HttpUtils.Post(`${config.apiUrl}/review/alert/reopen`, payload);
  return data?.data;
};

const fetchAlertClosureHistory = async (accountNumber, triggerDate) => {
  const data = await HttpUtils.Get(`${config.apiUrl}/review/history/alerts/${accountNumber}/${FormatUtils.FormatToPrettyDate(triggerDate)}`);
  return data?.data;
};

const fetchNPAMovement = async (accountNumber, currentDate) => {
  const data = await HttpUtils.Get(`${config.apiUrl}/review/history/npa/${accountNumber}/${FormatUtils.FormatToPrettyDate(currentDate)}`);
  return data?.data;
};

const closeAccountAlerts = async (accountNumber, triggerDate, payload) => {
  const data = await HttpUtils.Post(`${config.apiUrl}/review/alert/close/${accountNumber}/${FormatUtils.FormatToPrettyDate(triggerDate)}`, payload);
  return data?.data;
};

const reopenAccountAlerts = async (accountNumber, triggerDate, payload) => {
  const data = await HttpUtils.Post(`${config.apiUrl}/review/alert/open/${accountNumber}/${FormatUtils.FormatToPrettyDate(triggerDate)}`, payload);
  return data?.data;
};

const fetchAccountsWithPendingAlerts = async (currentDate) => {
  const data = await HttpUtils.Get(`${config.apiUrl}/review/account/alerts/open/${FormatUtils.FormatToPrettyDate(currentDate)}`);
  return data?.data;
};

const fetchAccountPendingAlerts = async (accountNumber) => {
  const data = await HttpUtils.Get(`${config.apiUrl}/review/account/pendingalerts/${accountNumber}`);
  return data?.data;
};

const fetchAccountClosedAlerts = async (currentDate) => {
  const data = await HttpUtils.Get(`${config.apiUrl}/review/account/alerts/closed/${FormatUtils.FormatToPrettyDate(currentDate)}`);
  return data?.data;
};

const fetchAlertsClosedBySubordinates = async (currentDate) => {
  const data = await HttpUtils.Get(`${config.apiUrl}/review/account/alerts/closed/subordinates/${FormatUtils.FormatToPrettyDate(currentDate)}`);
  return data?.data;
};

const fetchReassignedAlerts = async () => {
  const data = await HttpUtils.Get(`${config.apiUrl}/review/account/alerts/reassigned`);
  return data?.data;
};

const fetchLatestTriggerDate = async () => {
  const data = await HttpUtils.Get(`${config.apiUrl}/review/triggerdate`);
  return data?.data;
};

const isSomeoneElseReviewing = (userReviewInitiators, userId) => {
  const initiator = userReviewInitiators;
  if (initiator?.FkUserId !== userId) {
    return initiator?.User?.Username;
  }

  return null;
};

const isAlertClosable = (
  accountNumber,
  accountTriggers,
  reviewHistory,
  userReviewInitiators,
  closedAlerts,
  alertClosureHistory,
  userRoles,
  user,
  triggerDate
) => {
  const hasSuggestiveActions = accountTriggers?.every((i) =>
    reviewHistory?.some((r) => r?.SuggestiveAction?.TriggerId === i.TriggerId && r.SuggestiveAction.IsActive)
  );

  const isSomeoneElseWorking = isSomeoneElseReviewing(userReviewInitiators, user?.UserId);
  if (isSomeoneElseWorking) {
    return false;
  }

  const isClosedAlert = closedAlerts?.some(
    (i) => i.AccountNumber === accountNumber && FormatUtils.FormatToPrettyDate(i.TriggerDate) === FormatUtils.FormatToPrettyDate(triggerDate)
  );
  if (isClosedAlert) {
    return false;
  }

  const isFullyClosed = accountTriggers?.every((i) =>
    alertClosureHistory?.some((c) => c?.IsActive && c?.CreatedBy === user?.EmailAddress && !c?.IsFinalClosed)
  );

  const isFinalClosed = alertClosureHistory?.some((i) => i.IsActive && i.IsFinalClosed && i?.CreatedBy === user?.EmailAddress);
  if (isFinalClosed) {
    return false;
  }

  let isClosedBySubordinates = accountTriggers?.every((i) =>
    alertClosureHistory?.some((c) => c?.IsActive && c?.CreatedBy !== user?.EmailAddress && !c?.IsFinalClosed)
  );

  const isInitiator = userRoles?.some((i) => i?.OrganizationRoles?.IsInitiator);
  if (isInitiator) {
    isClosedBySubordinates = true;
  }

  return hasSuggestiveActions && !isFullyClosed && isClosedBySubordinates;
};

const isAlertReassignable = (userRoles, user, alertClosureHistory) => {
  const canReassign = userRoles?.some((i) => i?.OrganizationRoles?.CanReassignAlerts);
  if (!canReassign) {
    return false;
  }

  const isClosedBySelf = alertClosureHistory?.some((i) => i?.IsActive && i?.UserId === user?.UserId);
  if (isClosedBySelf) {
    return false;
  }

  const isFinalClosed = alertClosureHistory?.some((i) => i?.IsActive && i?.IsFinalClosed);
  if (isFinalClosed) {
    return false;
  }

  const isClosedBySubordinates = alertClosureHistory?.some((i) => i?.IsActive && i?.CreatedBy !== user?.EmailAddress && !i?.IsFinalClosed);
  if (isClosedBySubordinates) {
    return true;
  }

  return false;
};

const ReviewUtils = {
  PostReview: postReview,
  FetchAccountReviewHistory: fetchAccountReviewHistory,
  MarkComplete: markComplete,
  FetchEscalations: fetchEscalations,
  CloseAlert: closeAlert,
  ReopenAlert: reopenAlert,
  FetchAlertClosureHistory: fetchAlertClosureHistory,
  FetchNPAMovement: fetchNPAMovement,
  CloseAccountAlerts: closeAccountAlerts,
  ReopenAccountAlerts: reopenAccountAlerts,
  FetchAccountsWithPendingAlerts: fetchAccountsWithPendingAlerts,
  FetchAccountPendingAlerts: fetchAccountPendingAlerts,
  FetchAccountClosedAlerts: fetchAccountClosedAlerts,
  FetchAlertsClosedBySubordinates: fetchAlertsClosedBySubordinates,
  FetchReassignedAlerts: fetchReassignedAlerts,
  FetchLatestTriggerDate: fetchLatestTriggerDate,
  IsSomeoneElseReviewing: isSomeoneElseReviewing,
  IsAlertClosable: isAlertClosable,
  IsAlertReassignable: isAlertReassignable,
};

export default ReviewUtils;
