import { Button } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import FormatUtils from "./../../utils/formatUtils";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import { setSelectedAccount } from "../../actions/accountActions";
import { withRouter } from "react-router-dom";

let styles = (theme) => ({
  bookmark: {
    float: "left",
    margin: `0px ${theme.spacing(2)}px 0px ${theme.spacing(2)}px`,
  },
  npaType: {
    cursor: "pointer",
  },
  npaTable: {
    ".MuiTableRow-root": {
      cursor: "pointer",
    },
  },
  actionIcon: {
    padding: 0,
  },
});

const NPATable = (props) => {
  const { classes, selectedType } = props;

  const options = {
    selectableRows: "none",
    responsive: "vertical",
    search: true,
    viewColumns: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 15, 100, 1000, 10000],
    onRowClick: (rowData) => {
      const id = rowData[2];
      const name = rowData[3];
      const account = {
        id,
        name,
      };
      props.setSelectedAccount(account);
      props.history.push(`account/${id}`);
    },
    downloadOptions: {
      filename: FormatUtils.GetCSVFileName(`NPA-${selectedType}`),
      separator: ",",
    },
  };

  const columns = [
    {
      name: "TriggerId",
      label: "TriggerId",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "LoanId",
      label: "LoanId",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "BorrowerId",
      label: "BorrowerId",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "BorrowerName",
      label: "Borrower Name",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return <Button className={classes.borrowerName}>{value}</Button>;
        },
      },
    },
    {
      name: "Circle",
      label: "Circle",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Segment",
      label: "Segment",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "AmountDisbursed",
      label: "Amount Disbursed",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return FormatUtils.FormatNumberToCurrency(value);
        },
      },
    },
  ];

  return <MUIDataTable columns={columns} data={props.npaLoans} options={options} className={classes.npaTable}></MUIDataTable>;
};

const mapStateToProps = (state) => {
  return {
    npaLoans: state.dashboardReducer.npaLoans,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedAccount: (payload) => dispatch(setSelectedAccount(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(NPATable)));
