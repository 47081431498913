import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withStyles, Box, Button, Grid, Typography, IconButton } from "@material-ui/core";
import { setLoader, setSnackBar } from "../../actions/commonActions";
import '../../styles/pivottable.css';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import TableRenderers from 'react-pivottable/TableRenderers'
import $ from 'jquery';
import * as XLSX from 'xlsx';
import FormatUtils from "./../../utils/formatUtils";
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import jsPDF from 'jspdf';
import { store } from "../../store/store";
import ReplayRoundedIcon from '@material-ui/icons/ReplayRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';
import html2canvas from 'html2canvas';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import { setPivottableFilters } from "../../actions/misReportsActions";

let styles = (theme) => ({
  button: {
    float: `left`,
    margin: theme.spacing(0.5)
  },
})

const IPivotTableUI = (props) => {
  let { dispatch } = store;
  const { classes, moduleName, rows, columns, data, saveBookmarks } = props;

  const [pivotTableState, setPivotTableState] = useState(props.pivottableFilters ?? {});

  useEffect(() => {
    setPivotTableState(props.pivottableFilters);
  }, [props.pivottableFilters]);

  const [hideTotals, setHideTotals] = useState(false);

  const PlotlyRenderers = createPlotlyRenderers(Plot);
  const ExportToExcel = () => {
    dispatch(setLoader(true));
    // set download file name
    var xlsxFileName = FormatUtils.GetDownloadFileName(moduleName, "xlsx");
    // get the table , it has class name as "pvtTable", set an Id to the table element
    $('.pvtTable').attr('id', 'exportTable');
    const table = document.getElementById('exportTable');
    if (table == null) {
      dispatch(setLoader(false));
      props.setSnackBar("Only table can be exported as xlsx");
      return;
    }
    const wb = XLSX.utils.table_to_book(table, { sheet: moduleName + '-1', display: true });
    // Export to a file (download)
    XLSX.writeFile(wb, xlsxFileName);
    dispatch(setLoader(false));
  }

  const ExportToPDF = () => {
    // set download file name
    var pdfFileName = FormatUtils.GetDownloadFileName(moduleName, "pdf");
    // get the table , it has class name as "pvtTable", set an Id to the table element
    $('.pvtTable').attr('id', 'exportTable');
    const table = document.getElementById('exportTable');
    if (table == null) {
      $('.pvtOutput').attr('id', 'exportnonTable');
      const nonTable = document.getElementById('exportnonTable');
      html2canvas(nonTable, { width: $(nonTable).width() }).then(canvas => {
        var imgData = canvas.toDataURL(
          'image/png');
        var pdf = new jsPDF('p', 'mm');
        pdf.addImage(imgData, 'PNG', 10, 10);
        pdf.save(pdfFileName);
      });
      dispatch(setLoader(false));
      props.setSnackBar("Exported as pdf");
      return;
    }
    var pdf = new jsPDF("p", "pt", [500, 600])
    dispatch(setLoader(true));

    pdf.html(table, {
      autoPaging: "text",
      callback: function (pdf) {
        pdf.save(pdfFileName);
        dispatch(setLoader(false));
      },
      width: 500,
      windowWidth: $(table).width()
    })
  }

  const HideTotals = () => {
    // set download file name
    $('.pvtTable').attr('id', 'exportTable');
    if (document.getElementById("exportTable") == null) {
      props.setSnackBar("Not in table view");
      return;
    };
    if (!hideTotals) {
      $(".pvtTotal").css("display", "none");
      $(".pvtTotalLabel").css("display", "none");
      $(".pvtGrandTotal").css("display", "none");
      setHideTotals(true);
      return;
    }
    else if (hideTotals) {
      $(".pvtTotal").css("display", "table-cell");
      $(".pvtTotalLabel").css("display", "table-cell");
      $(".pvtGrandTotal").css("display", "table-cell");
      setHideTotals(false);
      return;
    }
  }

  const ResetState = () => {
    // set download file name
    props.setPivottableFilters({});
    props.setSnackBar("Table was reset successfully");
  }

  const handleBookmarks = () => {
    // set download file name
    var config_copy = pivotTableState;
    delete config_copy["aggregators"];
    delete config_copy["renderers"];
    //delete some bulky default values
    delete config_copy["data"]
    saveBookmarks(config_copy);
  }

  return (
    <Grid container>
      <Grid item xs={12} m={1}>
        <Box className={classes.button} align="center" sx={{ display: "inline-block" }}>
          <Typography variant="subtitle1">Options: </Typography>
        </Box>

        <Box>
          <Button variant="outlined" size="small"
            className={classes.button}
            onClick={ExportToExcel}
            color="default"
            startIcon={<GetAppRoundedIcon />}
          >
            XLSX
          </Button>
          <Button variant="outlined" size="small" className={classes.button} onClick={ExportToPDF}
            color="default" startIcon={<GetAppRoundedIcon />}>PDF</Button>

          <Button variant="outlined" size="small" className={classes.button}
            onClick={HideTotals} color="default"
            startIcon={hideTotals ? <VisibilityRoundedIcon /> : <VisibilityOffRoundedIcon />}
          >
            {hideTotals ? "Show totals" : "Hide totals"}
          </Button>

          <Button variant="outlined" size="small" className={classes.button}
            onClick={ResetState} color="default" startIcon={<ReplayRoundedIcon />}
          >
            Reset Table
          </Button>

          <Box sx={{ float: "right", position: "relative" }}>
            <IconButton size="small" onClick={handleBookmarks}>
              <CollectionsBookmarkIcon />
              Bookmarks
            </IconButton>
          </Box>

        </Box>
      </Grid>
      <Box sx={{ overflow: "auto", backgroundColor: "white" }}>
        <PivotTableUI
          rows={rows}
          cols={columns}
          data={data}
          onChange={s => {
            delete s.data;
            setPivotTableState(s);
            props.setPivottableFilters(s);
            if (hideTotals) {
              // need to hide totals again on re-render
              $(".pvtTotal").css("display", "table-cell");
              $(".pvtTotalLabel").css("display", "table-cell");
              $(".pvtGrandTotal").css("display", "table-cell");

              $(".pvtTotal").css("display", "none");
              $(".pvtTotalLabel").css("display", "none");
              $(".pvtGrandTotal").css("display", "none");
            }
          }}
          renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          rendererOptions={{ table: { rowTotals: false, colTotals: false } }}
          unusedOrientationCutoff={1000}
          {...pivotTableState}
        />
      </Box>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    pivottableFilters: state.misReportsReducer.pivottableFilters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackBar: (payload) => dispatch(setSnackBar(payload)),
    setPivottableFilters: (payload) => dispatch(setPivottableFilters(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(IPivotTableUI));
