const getSelectedColor = (color) => {
  if (color.toLocaleLowerCase() === "red") return "red";
  if (color.toLocaleLowerCase() === "amber") return "#FFC200";
  if (color.toLocaleLowerCase() === "green") return "green";
  return "#BBB";
};

const UIUtils = {
  GetSelectedColor: getSelectedColor,
};

export default UIUtils;
