import React from "react";
import { connect } from "react-redux";
import { withStyles, Card, CardContent, Box } from "@material-ui/core";
import IKeyValuePair from "../common/IKeyValuePair";
import FormatUtils from "./../../utils/formatUtils";

let styles = (theme) => ({
  root: {},
  profileCard: {
    minHeight: `70vh`,
  },
  profileIcon: {
    padding: theme.spacing(2),
    background: `#3293a2`,
    borderRadius: theme.spacing(4),
    fontWeight: `bolder`,
    width: theme.spacing(8),
    textAlign: `center`,
    border: `${theme.spacing(0.5)}px solid #fbfbfb`,
  },
  borderBottom: {
    borderBottom: `1px solid #dedede`,
  },
  topSection: {
    background: `#62a1d7`,
    color: `#fff`,
    borderBottom: `1px solid #dedede`,
  },
  username: {
    padding: theme.spacing(1),
  },
});

const Profile = (props) => {
  const { classes } = props;
  return (
    <div>
      <Card className={classes.profileCard}>
        <CardContent className={classes.topSection}>
          <Box justifyContent="center" alignItems="center" display="flex">
            <div className={classes.profileIcon}>{FormatUtils.GetInitials(props.accountData["BorrowerName"] || "-")}</div>
          </Box>
          <div className={classes.username}>{props.accountData["BorrowerName"] || "-"}</div>
        </CardContent>
        {props.accountData && (
          <div>
            <CardContent className={classes.borderBottom}>
              <IKeyValuePair
                data={{
                  key: "AmountDisbursed",
                  value: FormatUtils.FormatNumberToCurrency(props.accountData["AmountDisbursed"]) || "-",
                }}
              />
              <IKeyValuePair
                data={{
                  key: "LoanType",
                  value: FormatUtils.ToTitleCase(props.accountData["LoanType"]) || "-",
                }}
              />
              <IKeyValuePair
                data={{
                  key: "Interest",
                  value: props.accountData["Interest"],
                }}
              />
              <IKeyValuePair
                data={{
                  key: "DisbursementDate",
                  value: FormatUtils.FormatToPrettyDate(props.accountData["DisbursementDate"]) || "-",
                }}
              />
            </CardContent>
            <CardContent className={classes.borderBottom}>
              <IKeyValuePair
                data={{
                  key: "BusinessUnit",
                  value: props.accountData["BusinessUnit"] || "-",
                }}
              />
              <IKeyValuePair
                data={{
                  key: "Circle",
                  value: props.accountData["Circle"] || "-",
                }}
              />
              <IKeyValuePair
                data={{
                  key: "Network",
                  value: props.accountData["Network"] || "-",
                }}
              />
              <IKeyValuePair
                data={{
                  key: "Module",
                  value: props.accountData["Module"] || "-",
                }}
              />
              <IKeyValuePair
                data={{
                  key: "Region",
                  value: props.accountData["Region"] || "-",
                }}
              />
              <IKeyValuePair
                data={{
                  key: "Branch",
                  value: props.accountData["Branch"] || "-",
                }}
              />
            </CardContent>
            <CardContent className={classes.borderBottom}>
              <IKeyValuePair
                data={{
                  key: "InitialInternalRating",
                  value: props.accountData["InitialInternalRating"] || "-",
                }}
              />
              <IKeyValuePair
                data={{
                  key: "ExternalRating",
                  value: props.accountData["ExternalRating"] || "-",
                }}
              />
            </CardContent>
          </div>
        )}
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    accountData: state.accountReducer.accountData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Profile));
