import HttpUtils from "./httpUtils";
import config from "../config/config";

const fetchFormTemplate = async (formId) => {
    const data = await HttpUtils.Get(`${config.apiUrl}/Form/formTemplates?FormId=${formId}`);
    return data?.data;
}

const FormUtils = {
    FetchFormTemplate: fetchFormTemplate
};

export default FormUtils;
