import { SET_CAN_ADD_REVIEWS, SET_ROLES, SET_USER } from "../actions/authActions";

const initialState = {
  user: false,
  roles: [],
  canAddReviews: true,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_ROLES:
      return {
        ...state,
        roles: [...action.payload],
      };
    case SET_CAN_ADD_REVIEWS:
      return {
        ...state,
        canAddReviews: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
