import React, { useEffect } from "react";
import { Card, CardContent, CardHeader, Grid, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { setAnalytics, setBarChartCategories, setBarChartSeries, setDimensionLoans } from "../actions/analyticsActions";
import AnalyticsUtils from "../utils/analyticsUtils";
import Filter from "../components/common/Filter";
import Charts from "../components/dashboard/Charts";
import StackedChart from "../components/analytics/StackedChart";
import DashboardUtils from "../utils/dashboardUtils";
import DataUtils from "../utils/dataUtils";
import FormatUtils from "./../utils/formatUtils";
import TriggersTab from "../components/common/TriggersTab";
import SlicenDice from "../components/analytics/SlicenDice";
import ITooltip from "../components/common/ITooltip";

let styles = (theme) => ({
  root: {},
  cardHeader: {
    textAlign: "left",
    borderBottom: `1px solid #ccc`,
    background: "#f7f7f7",
    "& span": {
      fontWeight: "bold",
      fontSize: theme.spacing(2),
    },
  },
  alignLeft: {
    textAlign: "left",
  },
});

const Analytics = (props) => {
  const { classes } = props;

  const defaultDimensionColor = "red";
  const defaultDimensionValue = 0;

  const fetchAnalytics = () => {
    AnalyticsUtils.FetchFullView(
      props.selectedTriggerType?.key,
      props.currentDate || DataUtils.GetDefaultCurrentDate(),
      props.selectedFilterItems
    ).then((data) => {
      props.setAnalytics("");
      if (!data) return;
      props.setAnalytics(data?.FullView);
      setDefaultLoans(data?.FullView);
    });

    fetchTriggerComparision();
  };

  const fetchTriggerComparision = () => {
    const currentDate = props.currentDate || DataUtils.GetDefaultCurrentDate();
    const compareDate = props.compareDate || DataUtils.GetDefaultCompareDate();

    DashboardUtils.FetchTriggers(props.selectedTriggerType?.key, currentDate, props.selectedFilterItems).then((currentData) => {
      DashboardUtils.FetchTriggers(props.selectedTriggerType?.key, compareDate, props.selectedFilterItems).then((compareData) => {
        const currentTriggers = currentData?.OverallTriggers;
        const compareTriggers = compareData?.OverallTriggers;

        const series = [
          {
            name: "Red",
            data: [],
            stack: "red",
          },
          {
            name: "Amber",
            data: [],
            stack: "amber",
          },
          {
            name: "Green",
            data: [],
            stack: "green",
          },
        ];

        series.forEach((s) => {
          const compareCount = compareTriggers?.find((i) => i.Color.toLocaleLowerCase() === s.name.toLocaleLowerCase())?.Count || 0;
          const currentCount = currentTriggers?.find((i) => i.Color.toLocaleLowerCase() === s.name.toLocaleLowerCase())?.Count || 0;
          s.data = [compareCount, currentCount];
        });

        const categories = [FormatUtils.FormatToPrettyDate(compareDate), FormatUtils.FormatToPrettyDate(currentDate)];

        props.setBarChartCategories(categories);
        props.setBarChartSeries(series);
      });
    });
  };

  const setDefaultLoans = (fullView) => {
    const data = AnalyticsUtils.GetLoansByDimension(defaultDimensionValue, null, fullView, defaultDimensionColor);
    props.setDimensionLoans(data);
  };

  //call on each filter change
  useEffect(fetchAnalytics, [props.selectedFilterValues, props.currentDate, props.compareDate, props.selectedTriggerType?.id]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Filter onFilter={fetchAnalytics} />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.alignLeft}>
          <TriggersTab />
        </Grid>
      </Grid>
      <Charts />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={"Before 'n After"}
              className={classes.cardHeader}
              action={
                <ITooltip
                  title={
                    <div>
                      <p>
                        This grouped bar chart gives an idea about the increase/decrease in number of triggers between two dates. Globally selected
                        filters apply to this chart.
                      </p>

                      <p>Click on colors in the legend to show/hide count of triggers in each group.</p>
                    </div>
                  }
                />
              }
            />
            <CardContent>{props.analytics?.length > 0 && <StackedChart />}</CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SlicenDice />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    analytics: state.analyticsReducer.analytics,
    selectedFilterValues: state.dashboardReducer.selectedFilterValues,
    currentDate: state.dashboardReducer.currentDate,
    compareDate: state.dashboardReducer.compareDate,
    selectedFilterItems: state.dashboardReducer.selectedFilterItems,
    selectedTriggerType: state.triggerReducer.selectedTriggerType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAnalytics: (payload) => dispatch(setAnalytics(payload)),
    setDimensionLoans: (payload) => dispatch(setDimensionLoans(payload)),
    setBarChartSeries: (payload) => dispatch(setBarChartSeries(payload)),
    setBarChartCategories: (payload) => dispatch(setBarChartCategories(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Analytics));
