import HttpUtils from "./httpUtils";
import config from "../config/config";

const fetchPendingRFA = async () => {
  const data = await HttpUtils.Get(`${config.apiUrl}/RFA/pendingRFA`);
  return data?.data;
}

const fetchClosedRFA = async () => {
  const data = await HttpUtils.Get(`${config.apiUrl}/RFA/closedRFA`);
  return data?.data;
}

const fetchSubordinateSubmissions = async () => {
  const data = await HttpUtils.Get(`${config.apiUrl}/RFA/subordinateRFASubmissions`);
  return data?.data;
}

const fetchGeneratedRFAForm = async (formId) => {
  //const data = await HttpUtils.Get(`${config.apiUrl}/Form/generateRFAForm?FormId=${formId}`);
  //return data?.data;
  return {
    "formId": 1,
    "formLabel": "Warning Signals",
    "sections": [
      {
        "sectionId": 1,
        "sectionName": "Account Related",
        "labels": [
          {
            "labelId": 1,
            "label": "Bouncing of high value cheques",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 2,
            "label": "Foreign bills remaining outstanding with the bank for a long time and tendency for bills to remain overdue.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 3,
            "label": "Frequent invocation of BGs",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 4,
            "label": "Frequent Devolvement of LCs",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 5,
            "label": "Under insured or over insured inventory",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 6,
            "label": "Dispute on title of collateral securities.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 7,
            "label": "Funds coming from other banks to liquidate the outstanding loan unless in normal course.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 8,
            "label": "In merchant trade, import leg not revealed to the bank.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 9,
            "label": "Request received from the borrower to postpone the inspection of the godown for flimsy reasons.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 10,
            "label": "Funding of the interest by sanctioning additional facilities",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 11,
            "label": "Exclusive collateral charged to several lenders without NOC of existing charge holders.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          }
        ]
      },
      {
        "sectionId": 2,
        "sectionName": "Finance Related",
        "labels": [
          {
            "labelId": 12,
            "label": "Critical issues highlighted in stock audit report.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 13,
            "label": "Frequent request for general purpose loans.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 14,
            "label": "Frequent ad hoc sanctions.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 15,
            "label": "Non-routing of sales proceeds through consortium / member bank/ lenders to the company.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 16,
            "label": "LCs issued for local trade / related party transactions without underlying trade transaction.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 17,
            "label": "High value RTGS payment to unrelated parties.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 18,
            "label": "Heavy cash withdrawal in loan accounts.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 19,
            "label": "Non-production of original bills for verification upon request.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 20,
            "label": "Onerous Clause in BG/LC/Standby LC",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 21,
            "label": "Financing of unit far away from the Branch",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 22,
            "label": "Movement of an account from one Bank to another",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 23,
            "label": "Delay in Payment to the Banks",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 24,
            "label": "Default in undisputed payment to the statutory bodies",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 25,
            "label": "Delay observed in payment of outstanding dues.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 26,
            "label": "Invoices devoid of TAN and other details.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 27,
            "label": "Concealment of certain vital documents like master agreement, insurance coverage.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 28,
            "label": "Floating front / associate companies by investing borrowed money.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 29,
            "label": "Liabilities appearing in ROC search report, not reported by the borrower in its annual report.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 30,
            "label": "Significant movements in inventory disproportionately differing vis-a-vis change in turnover.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 31,
            "label": "Significant movements in receivables disproportionately differing vis-a-vis change in the turnover and/or increase in ageing og the receivables.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 32,
            "label": "Disporportionate change in other current assets.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 33,
            "label": "Significant increase in working capital borrowing as percentage of turnover.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 34,
            "label": "Increase in borrowings, despite huge cash and cash equivalents in the borrower's balance sheet.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 35,
            "label": "Frequent change in accounting period and / or accounting policies.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 36,
            "label": "Claims not acknowledged as debt high.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 37,
            "label": "Substantial increase in unbilled revenue year after year.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 38,
            "label": "Large number of transactions with inter-connected companies.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 39,
            "label": "Large outstanding from inter-connected companies.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 40,
            "label": "Substantial related party transactions.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 41,
            "label": "Material discrepancies in the annual report.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 42,
            "label": "Significant inconsistencies within the annual report (between various sections).",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 43,
            "label": "Poor disclosure of materially adverse information and no qualification by the statutory auditors.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 44,
            "label": "Raid by Income tax / sales tax / central excise duty officals.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 45,
            "label": "Signifiant reduction in the stake of promoter / director.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 46,
            "label": "Increase in the encumbered shares of promoter / director.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          }
        ]
      },
      {
        "sectionId": 3,
        "sectionName": "Project Related",
        "labels": [
          {
            "labelId": 47,
            "label": "Increase in Fixed Assets, without corresponding increase in long term sources (when project is implemented).",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 48,
            "label": "Costing of the project which is in wide variance with standard cost of installation of the project.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 49,
            "label": "Frequent change in the scope of the project to be undertaken by the borrower.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 50,
            "label": "Suspected Fraud by Auditors, Regulatory Agencies, Indication of Fraud as per Newspaper Reports.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 51,
            "label": "RFA / Fraud Declared by other Banks",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 52,
            "label": "Current Account Opened with Other Banks without any lending Exposure",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 53,
            "label": "Borrower, Promoters/Director/Associates/Group Companies in the lists of defaulters",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 54,
            "label": "Downgrade in External Credit Ratings",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 55,
            "label": "Borrower Reported not cooperative with External Credit Rating Agency",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 56,
            "label": "Frequent Change in Statutory Auditors/Resignation of Auditors.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 57,
            "label": "Strikes, labour unrest, agitation, lock out related news in the Company ",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 58,
            "label": "Decline in Share Prices/Shares Hitting 52 week low.",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          },
          {
            "labelId": 59,
            "label": "Any Major Negative News Relating to Borrower/Industry",
            "choiceGroups": [
              {
                "type": "radio",
                "choiceGroupId": 1,
                "choices": [
                  {
                    "choiceId": 1,
                    "choiceLabel": "Yes",
                    "choiceValue": "Yes"
                  },
                  {
                    "choiceId": 2,
                    "choiceLabel": "No",
                    "choiceValue": "No"
                  },
                  {
                    "choiceId": 3,
                    "choiceLabel": "Not applicable",
                    "choiceValue": "Not applicable"
                  }
                ]
              }
            ],
            "subLabels": [
              {
                "labelId": 61,
                "label": "Remarks",
                "choiceGroups": [
                  {
                    "type": "text",
                    "choiceGroupId": 2,
                    "choices": [
                      {
                        "choiceId": 4,
                        "choiceLabel": "Remarks",
                        "choiceValue": null
                      }
                    ]
                  }
                ],
                "subLabels": []
              }
            ]
          }
        ]
      },
      {
        "sectionId": 4,
        "sectionName": "Other",
        "labels": [
          {
            "labelId": 60,
            "label": "Any other Comments",
            "choiceGroups": [
              {
                "type": "text",
                "choiceGroupId": 2,
                "choices": [
                  {
                    "choiceId": 4,
                    "choiceLabel": "Remarks",
                    "choiceValue": null
                  }
                ]
              }
            ],
            "subLabels": []
          }
        ]
      }
    ]
  }
}

const fetchFormValues = async (payload) => {
  const data = await HttpUtils.Post(`${config.apiUrl}/RFA/retrieveRFAFormData`, payload);
  return data?.data;
}

const putSavedRFAForm = async (payload) => {
  const data = await HttpUtils.Post(`${config.apiUrl}/RFA/saveRFAForm`, payload);
  return data?.data;
}

const submitRFAForm = async (payload) => {
  const data = await HttpUtils.Post(`${config.apiUrl}/RFA/submitRFAForm`, payload);
  return data?.data;
}

const modifySubmittedRFAForm = async (payload) => {
  const data = await HttpUtils.Post(`${config.apiUrl}/RFA/modifySubmittedRFAForm`, payload);
  return data?.data;
}

const approveRFAForm = async (payload) => {
  const data = await HttpUtils.Post(`${config.apiUrl}/RFA/approveRFAForm`, payload);
  return data?.data;
}

const fetchReviewRFA = async (payload) => {
  const data = await HttpUtils.Post(`${config.apiUrl}/RFA/retrieveReviewRFA`, payload);
  return data?.data;
}

const fetchRFAChangeDifference = async (payload) => {
  const data = await HttpUtils.Post(`${config.apiUrl}/RFA/retrieveRFAChangeDifference`, payload);
  return data?.data;
}

const fetchSubordinateForms = async (payload) => {
  const data = await HttpUtils.Post(`${config.apiUrl}/RFA/retrieveSubordinateRFAForms`, payload);
  return data?.data;
}

const fetchRFAFormDetails = async (borrowerId) => {
  const data = await HttpUtils.Get(`${config.apiUrl}/RFA/retrieveRFAFormDetails?borrowerId=${borrowerId}`);
  return data?.data;
}

const fetchReviewRedFlagStatus = async (payload) => {
  const data = await HttpUtils.Post(`${config.apiUrl}/RFA/reviewRedFlagStatus`, payload);
  return data?.data;
}

const RFAUtils = {
  FetchPendingRFA: fetchPendingRFA,
  FetchClosedRFA: fetchClosedRFA,
  FetchGeneratedRFAForm: fetchGeneratedRFAForm,
  FetchFormValues: fetchFormValues,
  PutSavedRFAForm: putSavedRFAForm,
  SubmitRFAForm: submitRFAForm,
  ModifySubmittedRFAForm: modifySubmittedRFAForm,
  ApproveRFAForm: approveRFAForm,
  FetchReviewRFA: fetchReviewRFA,
  FetchSubordinateSubmissions: fetchSubordinateSubmissions,
  FetchRFAChangeDifference: fetchRFAChangeDifference,
  FetchSubordinateForms: fetchSubordinateForms,
  FetchRFAFormDetails: fetchRFAFormDetails,
  FetchReviewRedFlagStatus: fetchReviewRedFlagStatus
};

export default RFAUtils;
