import { Button, withStyles } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import { connect } from "react-redux";
import { setSnackBar } from "../../actions/commonActions";
import { setAlertClosureHistory, setSelectedAlertRework } from "../../actions/reviewActions";
import FormatUtils from "../../utils/formatUtils";
import ReviewUtils from "../../utils/reviewUtils";
import IDialog from "../common/IDialog";
import AlertRework from "./AlertRework";

let styles = (theme) => ({
  root: {},
  tableHeading: {
    fontSize: theme.spacing(3),
    float: `left`,
  },
});

const AlertClosureHistory = (props) => {
  const { classes } = props;
  const [dialogOpen, setDialogOpen] = useState(false);

  const options = {
    selectableRows: "none",
    responsive: "vertical",
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 15, 100, 1000, 10000],
    downloadOptions: {
      filename: FormatUtils.GetCSVFileName("Alert-Closure-History"),
      separator: ",",
    },
    onRowClick: (rowData, rowState) => {
      props.setSelectedAlertRework(rowData);
    },
  };

  const handleRework = (value, rowIndex) => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const columns = [
    {
      name: "UserReviewClosureId",
      label: "UserReviewClosureId",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "TriggerId",
      label: "TriggerId",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "TriggerName",
      label: "Trigger Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "TriggerDate",
      label: "Trigger Date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => FormatUtils.FormatToPrettyDate(value),
      },
    },
    {
      name: "Remarks",
      label: "Remarks",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "ModifiedBy",
      label: "Closed By",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "ModifiedDate",
      label: "Closed On",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (value ? FormatUtils.FormatToPrettyDate(value) : ""),
      },
    },
    // {
    //   name: "IsActive",
    //   label: "Actions",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value) => {
    //       return value ? (
    //         <Button aria-label="re-assign" color="primary" onClick={handleRework}>
    //           Re-work
    //         </Button>
    //       ) : (
    //         ""
    //       );
    //     },
    //   },
    // },
  ];

  const mapData = (items) => {
    return items
      ?.filter((i) => i.IsActive && i.CreatedBy === props.user?.EmailAddress)

      ?.map((i) => {
        return { ...i, TriggerName: props.accountTriggers?.find((t) => i.TriggerId === t.TriggerId)?.TriggerName };
      });
  };

  const handleReworkSubmit = (payload) => {
    ReviewUtils.ReopenAlert(payload).then((data) => {
      setDialogOpen(false);
      props.setSnackBar("Alert has been re-assigned successfully");
      ReviewUtils.FetchAlertClosureHistory(props.selectedAccount.value, new Date()).then((data) => {
        props.setAlertClosureHistory(data);
      });
    });
  };

  return (
    <div>
      <MUIDataTable
        columns={columns}
        title={<div className={classes.tableHeading}>{`Triggers closed by you`}</div>}
        data={mapData(props.alertClosureHistory ?? [])}
        options={options}
        className={classes.root}
      ></MUIDataTable>
      <IDialog
        title={<div>Reopen alert</div>}
        fullScreen={false}
        open={dialogOpen}
        content={<AlertRework onSubmit={handleReworkSubmit} />}
        onClose={handleDialogClose}
      ></IDialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    selectedAccount: state.accountReducer.selectedAccount,
    alertClosureHistory: state.reviewReducer.alertClosureHistory,
    accountTriggers: state.accountReducer.accountTriggers,
    roles: state.authReducer.roles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedAlertRework: (payload) => dispatch(setSelectedAlertRework(payload)),
    setSnackBar: (payload) => dispatch(setSnackBar(payload)),
    setAlertClosureHistory: (payload) => dispatch(setAlertClosureHistory(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AlertClosureHistory));
