import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { Button, withStyles } from "@material-ui/core";

let styles = (theme) => ({
  link: {
    display: "flex",
    padding: theme.spacing(1),
    cursor: "pointer",
    fontSize: theme.spacing(1.5),
    textTransform: "none",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(-0.5),
    width: 20,
    height: 20,
  },
});

const IBreadCrumb = (props) => {
  const { classes, items } = props;

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {items.map((item) => {
        return item.routed ? (
          <Link color="inherit" href={item.link} onClick={item.onClick} className={classes.link}>
            <div className={classes.icon}>{item.icon}</div>
            {item.title}
          </Link>
        ) : (
          <Button color="inherit" key={item.title} onClick={item.onClick} className={classes.link} alt={item.title}>
            <div className={classes.icon}>{item.icon}</div>
            {item.title}
          </Button>
        );
      })}
    </Breadcrumbs>
  );
};

export default withStyles(styles)(IBreadCrumb);
