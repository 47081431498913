import React from "react";
import { connect } from "react-redux";
import { withStyles, Grid, Tooltip } from "@material-ui/core";
import { useEffect, useState } from "react";
import PendingRFA from "../components/rfa/PendingRFA";
import ClosedRFA from "../components/rfa/ClosedRFA";
import SubordinateRFASubmissions from "../components/rfa/SubordinateRFASubmissions";
import RFAForm from "../components/rfa/RFAForm";
import { setOrganizationRoles } from "../actions/adminActions";
import IControlledTab from "../components/common/IControlledTab";
import { setToFormOpen, setToFormClose, setFormDetails, setBorrowerName } from "../actions/rfaActions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

let styles = (theme) => ({
  root: {},
  alignRight: {
    textAlign: "right",
    paddingRight: `${theme.spacing(1)} !important`,
    fontWeight: "bold",
    color: "black",
  },
});

const RFA = (props) => {
  const { classes } = props;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const location = useLocation();
  const [enableForm, setEnableForm] = useState(false);

  const onLoad = () => {
    if (location.state !== undefined && location.state !== null) {
      //assuming that the redirect is always from /review with formDetails
      setEnableForm(true);
      setFormDetails(location.state);
      setSelectedTabIndex(getActiveTabIndex());
    }
    else if (!props.toFormOpen) {
      if (selectedTabIndex !== getActiveTabIndex()) {
        return;
      }
      else {
        setSelectedTabIndex(0);
        setEnableForm(false);
        setToFormOpen(false);
      }
    }
    else if (props.toFormOpen) {
      setEnableForm(true);
      setSelectedTabIndex(getActiveTabIndex());
    }
  };

  useEffect(onLoad, [props.toFormOpen]);


  const handleChange = (event, newValue) => {
    setSelectedTabIndex(newValue);
  };

  const isInitiator = () => {
    return props.roles?.some((i) => i?.OrganizationRoles?.IsInitiator);
  };

  const hasSubordinates = () => {
    return props?.roles?.some((i) => i?.OrganizationRoles?.Name !== "RelationshipManager");
  };

  const isController = () => {
    return props.roles?.some((i) => i?.OrganizationRoles?.IsController);
  };

  const tabs = [
    {
      index: 0,
      label: "Pending RFA",
      content: <PendingRFA />,
    },
    {
      index: 1,
      label: "Submitted By Subordinates",
      content: <SubordinateRFASubmissions />,
      hidden: !hasSubordinates(),
    },
    {
      index: 2,
      label: "Closed RFA",
      content: <ClosedRFA />,
    },
    {
      index: 3,
      label: "RFA Form",
      value: "RFAForm",
      content: <RFAForm setSelectedTabIndex={setSelectedTabIndex} selectedTabIndex={selectedTabIndex} hasSubordinates={hasSubordinates} isController={isController} setEnableForm={setEnableForm}/>,
      hidden: (enableForm) ? false : true,
      levels: ["account"],
    },
  ];

  const getActiveTabIndex = () => {
    return mapTabs()?.find((i) => i.label === "RFA Form")?.index;
  };

  const mapTabs = () => {
    if (!hasSubordinates()) {
      const index = tabs.find((i) => i.label === "Submitted By Subordinates").index;
      const filteredTabs = tabs.filter((i) => i.label !== "Submitted By Subordinates");
      return filteredTabs.map((tab) => {
        return {
          ...tab,
          index: tab.index > index ? tab.index - 1 : tab.index,
        };
      });
    }
    return tabs;
  };

  return (
    <IControlledTab tabs={mapTabs()} value={selectedTabIndex || 0} onChange={handleChange} />
  );
};

const mapStateToProps = (state) => {
  return {
    roles: state.authReducer.roles,
    toFormOpen: state.rfaReducer.toFormOpen,
    toFormClose: state.rfaReducer.toFormClose,
    formDetails: state.rfaReducer.formDetails,
    borrowerName: state.rfaReducer.borrowerName
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOrganizationRoles: (payload) => dispatch(setOrganizationRoles(payload)),
    setToFormOpen: (payload) => dispatch(setToFormOpen(payload)),
    setToFormClose: (payload) => dispatch(setToFormClose(payload)),
    setFormDetails: (payload) => dispatch(setFormDetails(payload)),
    setBorrowerName: (payload) => dispatch(setBorrowerName(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RFA));