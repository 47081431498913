import React, { useState } from "react";
import MaterialTable from "material-table";
import { withStyles } from "@material-ui/core";

let styles = (theme) => ({
  root: {},
  alignLeft: {
    textAlign: "left",
  },
  dropdown: {
    marginTop: theme.spacing(1),
  },
});

const IEditable = (props) => {
  const { columns, data, title, onUpdate, onBulkUpdate, options, actions, onFilterChange } = props;

  return (
    <div style={{ maxWidth: "100%" }}>
      {console.log(data)}
      <MaterialTable
        columns={columns}
        data={data}
        title={title}
        options={{ pageSize: 10, pageSizeOptions: [10, 15, 100], filtering: true, exportButton: true, exportAllData: true, ...options }}
        onFilterChange={(filterData) => { return onFilterChange(filterData) }}

        editable={{
          onRowUpdate: (newData, oldData) => {
            return new Promise(async (resolve, reject) => {
              try {
                await onUpdate(newData);
                // setTableData(data);
                resolve();
              } catch (err) {
                reject(err);
              }
            });
          },
          onBulkUpdate: (changes) => {
            return new Promise(async (resolve, reject) => {
              try {
                await onBulkUpdate(changes);
                // setTableData(data);
                resolve();
              } catch (err) {
                reject(err);
              }
            });
          },
        }}
        actions={actions}
      />
    </div>
  );
};

export default withStyles(styles)(IEditable);
