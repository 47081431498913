export const SET_TO_FORM_OPEN = "SET_TO_FORM_OPEN";
export const setToFormOpen = (payload) => ({
  type: SET_TO_FORM_OPEN,
  payload: payload,
});

export const SET_TO_FORM_CLOSE = "SET_TO_FORM_CLOSE";
export const setToFormClose = (payload) => ({
  type: SET_TO_FORM_CLOSE,
  payload: payload,
});

export const SET_FORM_DETAILS = "SET_FORM_DETAILS";
export const setFormDetails = (payload) => ({
  type: SET_FORM_DETAILS,
  payload: payload,
});

export const SET_TO_FORM_REVIEW = "SET_TO_FORM_REVIEW";
export const setToFormReview = (payload) => ({
  type: SET_TO_FORM_REVIEW,
  payload: payload,
});

export const SET_CLOSED_RFA = "SET_CLOSED_RFA";
export const setClosedRFA = (payload) => ({
  type: SET_CLOSED_RFA,
  payload: payload,
});

export const SET_BORROWER_NAME = "SET_BORROWER_NAME";
export const setBorrowerName = (payload) => ({
  type: SET_BORROWER_NAME,
  payload: payload,
});
