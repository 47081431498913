import React from "react";
import { withStyles } from "@material-ui/core";
import Pivot from "../custom/Pivot";
import FormatUtils from "./../../utils/formatUtils";

let styles = (theme) => ({
  root: {},
});

const IPivotGrid = (props) => {
  const { data } = props;
  const customizeToolbar = (toolbar) => {
    var tabs = toolbar.getTabs(); // get all tabs from the toolbar
    toolbar.getTabs = function () {
      return tabs.splice(3, tabs.length);
    };
  };

  const reportOptions = {
    dataSource: {
      data: data,
    },
    height: 720,
    slice: {
      rows: [
        {
          uniqueName: "Branch",
          sort: "asc",
        },
        {
          uniqueName: "Network",
          sort: "asc",
        },
        {
          uniqueName: "Module",
          sort: "asc",
        },
        {
          uniqueName: "Region",
          sort: "asc",
        },
        {
          uniqueName: "Circle",
          sort: "asc",
        },
        {
          uniqueName: "BusinessUnit",
          sort: "asc",
        },
        {
          uniqueName: "BorrowerName",
          sort: "asc",
        },
      ],
      columns: [
        {
          uniqueName: "Color",
        },
        {
          uniqueName: "Segment",
          sort: "asc",
        },
        {
          uniqueName: "LoanType",
          sort: "asc",
        },
      ],
      measures: [
        {
          uniqueName: "LoanId",
          aggregation: "count",
          caption: "Loans",
          format: "loancount",
          individual: true,
        },
        {
          uniqueName: "AmountDisbursed",
          aggregation: "sum",
          format: "currency",
          caption: "Loan Amount",
        },
      ],
      expands: {
        expandAll: false,
        rows: [
          {
            tuple: ["Branch"],
          },
        ],
        columns: [
          {
            tuple: ["Red"],
          },
        ],
      },
      drills: {
        drillAll: false,
      },
    },
    options: {
      grid: {
        type: "compact",
        title: "",
        showFilter: true,
        showHeaders: true,
        showTotals: true,
        showGrandTotals: "on",
        showHierarchies: true,
        showHierarchyCaptions: true,
        showReportFiltersArea: true,
      },
      configuratorActive: false,
      configuratorButton: true,
      showAggregations: true,
      showCalculatedValuesButton: true,
      drillThrough: true,
      showDrillThroughConfigurator: true,
      sorting: "on",
      datePattern: "dd/MM/yyyy",
      dateTimePattern: "dd/MM/yyyy HH:mm:ss",
      saveAllFormats: false,
      showDefaultSlice: true,
      defaultHierarchySortName: "asc",
    },
    formats: [
      {
        name: "",
        thousandsSeparator: " ",
        decimalSeparator: ".",
        decimalPlaces: 2,
        maxSymbols: 20,
        currencySymbol: "",
        currencySymbolAlign: "left",
        nullValue: " ",
        infinityValue: "Infinity",
        divideByZeroValue: "Infinity",
      },
      {
        name: "currency",
        currencySymbol: "",
        currencySymbolAlign: "left",
        thousandsSeparator: ",",
        decimalPlaces: 2,
      },
      {
        name: "loancount",
        currencySymbol: "",
        currencySymbolAlign: "left",
        thousandsSeparator: ",",
        decimalPlaces: 0,
      },
    ],
  };

  const customizeCellFunction = (cellBuilder, cellData) => {
    if (cellData.type === "value" && cellData.value > 0) {
      cellBuilder.text = FormatUtils.FormatNumberToCurrency(cellData.value);
    }
    if (cellData.type !== "value" && cellData.value > 0) {
      cellBuilder.addClass("font-weight-bold");
    }
    if (cellData.measure?.uniqueName === "LoanId") {
      cellBuilder.text = cellData.label;
    }

    if (cellData.member?.hierarchyName === "Color") {
      cellBuilder.addClass("font-weight-bold");
      cellBuilder.addClass(`header-${cellData?.label?.toLocaleLowerCase()}`);
    }
    if (cellData.isGrandTotalRow) {
      cellBuilder.addClass("grand-total");
    }
  };

  return (
    <div>
      {data.length > 0 && (
        <Pivot toolbar={true} report={reportOptions} beforetoolbarcreated={customizeToolbar} customizeCell={customizeCellFunction} height={1080} />
      )}
    </div>
  );
};

export default withStyles(styles)(IPivotGrid);
