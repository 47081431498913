import React from "react";
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import FormatUtils from "../../utils/formatUtils";

let styles = (theme) => ({
  root: {},
  tableHeading: {
    fontSize: theme.spacing(3),
    float: `left`,
  },
});

const EscalationsGrid = (props) => {
  const { classes } = props;

  const options = {
    selectableRows: "none",
    responsive: "vertical",
    rowsPerPage: 10,
    downloadOptions: {
      filename: FormatUtils.GetCSVFileName("Escalations"),
      separator: ",",
    },
  };

  const columns = [
    {
      name: "EscalationsId",
      label: "Escalationsid",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },

    {
      name: "EmailAddress",
      label: "EmailAddress",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "AccountNumber",
      label: "AccountNumber",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "BorrowerName",
      label: "BorrowerName",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "TriggerDate",
      label: "Trigger Date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => FormatUtils.FormatToPrettyDate(value),
      },
    },
    // {
    //   name: "ReviewedBy",
    //   label: "ReviewedBy",
    //   options: {
    //     filter: false,
    //     sort: true,
    //   },
    // },
    {
      name: "BusinessUnit",
      label: "Country",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "Circle",
      label: "Zone",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "Network",
      label: "State",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "Module",
      label: "Region",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "Branch",
      label: "Branch",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "RMName",
      label: "RMName",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "BMName",
      label: "BMName",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "ControllerName",
      label: "ControllerName",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
  ];

  const mapEscalationsToData = () => {
    return props.escalations?.map((item) => {
      return {
        ...item,
        TriggerDate: item.TriggerDate,
        ReviewedBy: item.ModifiedBy,
        AccountNumber: item.BorrowersData.AccountNumber,
        BorrowerName: item.BorrowersData.BorrowerName,
        BusinessUnit: item.BorrowersData.BusinessUnit,
        Circle: item.BorrowersData.Circle,
        Network: item.BorrowersData.Network,
        Module: item.BorrowersData.Module,
        Region: item.BorrowersData.Region,
        Branch: item.BorrowersData.Branch,
        RMName: item.BorrowersData.RMName,
        BMName: item.BorrowersData.BMName,
        ControllerName: item.BorrowersData.ControllerName,
      };
    });
  };
  return (
    <div>
      <MUIDataTable
        columns={columns}
        title={<div className={classes.tableHeading}>{`Escalations`}</div>}
        data={mapEscalationsToData()}
        options={options}
        className={classes.root}
      ></MUIDataTable>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    escalations: state.reviewReducer.escalations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EscalationsGrid));
