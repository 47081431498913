import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, withStyles } from "@material-ui/core";
import { Grid, Tooltip } from "@material-ui/core";
import IDatePicker from "./IDatePicker";
import TuneIcon from "@material-ui/icons/Tune";
import ExpandLessSharpIcon from "@material-ui/icons/ExpandLessSharp";
import SelectedFilters from "../dashboard/SelectedFilters";
import Filters from "../dashboard/Filters";
import HelperUtils from "./../../utils/helperUtils";
import {
  clearSelectedFilters,
  setCompareDate,
  setCurrentDate,
  setFilterDetails,
  setOverview,
  setSelectedFilterValues,
} from "../../actions/dashboardActions";
import DataUtils from "../../utils/dataUtils";
import Bookmarks from "./Bookmarks";

let styles = (theme) => ({
  button: {
    float: "left",
    margin: theme.spacing(1),
    width: `100%`,
  },
  filterButton: {
    float: "left",
    margin: theme.spacing(1),
    cursor: `pointer`,
  },
  alignLeft: {
    textAlign: "left",
  },
  toggleButton: {
    fontWeight: theme.spacing(70),
    border: "none",
    textTransform: "none",
    color: `rgba(0, 0, 0, 0.54)`,
    backgroundColor: `rgba(0, 0, 0, 0.12)`,
  },
  toggleButtonGroup: {
    background: `#FFFFFF`,
    padding: theme.spacing(1),
  },
  activeToggleButton: {
    color: `#FFFFFF !important`,
    backgroundColor: `#3293a2 !important`,
  },
  menuIcon: {
    padding: theme.spacing(1),
    fontSize: theme.spacing(2),
  },
  bottom: {
    verticalAlign: `bottom`,
  },
  overallTriggersGroup: {
    minHeight: theme.spacing(8),
    padding: theme.spacing(1),
  },
});

const Filter = (props) => {
  const { classes } = props;

  const [startDate, setStartDate] = useState(props.compareDate || DataUtils.GetDefaultCompareDate());
  const [endDate, setEndDate] = useState(props.currentDate || DataUtils.GetDefaultCurrentDate());
  const [showFilters, setShowFilters] = useState(false);
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  const fetchFilterDetails = () => {
    if (props.filterDetails) return;
    DataUtils.FetchFilterDetails().then((data) => {
      const filterDetails = DataUtils.FormatFilterDetails(data);
      props.setFilterDetails(filterDetails);
    });
  };

  const updateState = () => {
    setStartDate(props.compareDate || DataUtils.GetDefaultCompareDate());
    setEndDate(props.currentDate || DataUtils.GetDefaultCurrentDate());
  };

  useEffect(fetchFilterDetails, []);
  useEffect(updateState, [props.currentDate, props.compareDate]);

  const handleDateChange = (date, name) => {
    if (!HelperUtils.IsValidDate(date) || date > new Date()) {
      if (name === "startDate") {
        setStartDateError(true);
        return
      }
      else if (name === "endDate") {
        setEndDateError(true);
        return
      }
    };
    if (name === "startDate") {
      setStartDateError(false);
      if (date > endDate) {
        setStartDateError(true);
        return;
      }

      props.setCompareDate(date);
      return;
    }
    else if (name === "endDate") {
      setEndDateError(false);
      if (date < startDate) {
        setEndDateError(true);
        return;
      }
      props.setCurrentDate(date);
    }
  };

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Tooltip title="Compare Date" aria-label="compare-date">
            <IDatePicker label={"Compare Date"} value={startDate}
              onChange={(date) => handleDateChange(date, "startDate")}
              error={startDateError}></IDatePicker>
          </Tooltip>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Tooltip title="Current Date" aria-label="current-date">
            <IDatePicker
              label={"Current Date"}
              value={endDate}
              onChange={(date) => handleDateChange(date, "endDate")}
              minDate={startDate}
              maxDate={new Date()}
              error={endDateError}
            ></IDatePicker>
          </Tooltip>
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Button className={classes.filterButton} onClick={handleShowFilters}>
            {showFilters ? (
              <React.Fragment>
                <span className={classes.bottom}>Hide Filters</span>
                <ExpandLessSharpIcon></ExpandLessSharpIcon>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <TuneIcon></TuneIcon>
                <span className={classes.bottom}>More Filters</span>
              </React.Fragment>
            )}
          </Button>
          {!showFilters && !HelperUtils.IsObjectEmpty(props.selectedFilterValues) && <SelectedFilters />}
          <Bookmarks />
        </Grid>
      </Grid>
      {showFilters && <Filters />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    overview: state.dashboardReducer.overview,
    selectedFilterItems: state.dashboardReducer.selectedFilterItems,
    filterDetails: state.dashboardReducer.filterDetails,
    selectedFilterValues: state.dashboardReducer.selectedFilterValues,
    selectedColor: state.dashboardReducer.selectedStructuredColor,
    compareDate: state.dashboardReducer.compareDate,
    currentDate: state.dashboardReducer.currentDate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOverview: (payload) => dispatch(setOverview(payload)),
    setCurrentDate: (payload) => dispatch(setCurrentDate(payload)),
    setCompareDate: (payload) => dispatch(setCompareDate(payload)),
    setFilterDetails: (payload) => dispatch(setFilterDetails(payload)),
    clearSelectedFilters: (payload) => dispatch(clearSelectedFilters()),
    setSelectedFilterValues: (payload) => dispatch(setSelectedFilterValues(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Filter));
