import React from "react";
import {
  withStyles,
  Card,
  CardContent,
  Typography,
  IconButton,
  CardHeader,
  Tooltip,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core";
import { connect } from "react-redux";
import AppsIcon from "@material-ui/icons/Apps";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkUtils from "./../../utils/bookmarkUtils";
import { setSnackBar } from "../../actions/commonActions";
import { setBookmark } from "../../actions/bookmarkActions";
import { useEffect } from "react";
import { useState } from "react";
import IDialog from "../common/IDialog";

let styles = (theme) => ({
  root: {
    maxWidth: theme.spacing(40),
    float: "right",
    width: theme.spacing(32),
  },
  title: {
    fontSize: theme.spacing(1.75),
    textAlign: "left",
    fontWeight: theme.spacing(80),
    color: "#45a6fb",
  },
  leftAlign: {
    textAlign: "left",
  },
  key: {
    fontSize: theme.spacing(1.5),
  },
  length: {
    fontSize: theme.spacing(1.5),
    fontWeight: theme.spacing(80),
  },
  cardHeader: {
    textAlign: "left",
    "& span": {
      fontWeight: "bold",
      fontSize: theme.spacing(2),
    },
    padding: theme.spacing(1),
  },
});

const SelectedFilters = (props) => {
  const { classes, bookmark, selectedFilterValues } = props;
  const [open, setOpen] = useState(false);
  const [bookmarkName, setBookmarkName] = useState("");

  const handleClick = (event) => {
    setOpen(true);
  };

  const saveBookmark = () => {
    BookmarkUtils.SaveBookmark(props.userId, null, bookmarkName, props.selectedFilters, props.selectedFilterItems, props.selectedFilterValues).then(
      (data) => {
        props.setBookmark(data);
        props.setSnackBar("Saved bookmark");
        setOpen(false);
      }
    );
  };

  const clearBookmark = () => {
    props.setBookmark("");
  };

  useEffect(clearBookmark, [selectedFilterValues]);

  const handleClose = (event) => {
    setOpen(false);
  };

  return (
    props.selectedFilters.length > 0 && (
      <Card className={classes.root}>
        <CardHeader
          className={classes.cardHeader}
          title={
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              <AppsIcon />
              Selected Filters
            </Typography>
          }
          action={
            !bookmark?.BookmarkId && (
              <Tooltip title="Bookmark this search" aria-label="bookmark-search">
                <IconButton onClick={handleClick}>
                  <BookmarkIcon color="primary" />
                </IconButton>
              </Tooltip>
            )
          }
        ></CardHeader>
        <CardContent>
          {Object.entries(props.selectedFilterValues).map(([key, value]) => {
            return value.length > 0 ? (
              <div key={key} className={classes.leftAlign}>
                <span className={classes.key}>{key}</span>: <span className={classes.length}>{value.length}</span>
              </div>
            ) : (
              ""
            );
          })}

          <IDialog
            title="Save bookmark"
            open={open}
            content={
              <div>
                <DialogContentText>
                  <p>To save this bookmark, please enter a valid name for easier identification.</p> Tip: Using a unique name will make things easy
                  for you.
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="bookmarkName"
                  label="Bookmark Name"
                  onChange={(event) => setBookmarkName(event.currentTarget.value)}
                  type="text"
                  fullWidth
                />
                <DialogActions>
                  <Button onClick={saveBookmark} color="primary" variant="contained" disabled={!bookmarkName}>
                    Save
                  </Button>
                </DialogActions>
              </div>
            }
            onClose={handleClose}
          ></IDialog>
        </CardContent>
      </Card>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    selectedFilterValues: state.dashboardReducer.selectedFilterValues,
    selectedFilters: state.dashboardReducer.selectedFilters,
    selectedFilterItems: state.dashboardReducer.selectedFilterItems,
    bookmark: state.bookmarkReducer.bookmark,
    userId: state.authReducer.user?.UserId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackBar: (payload) => dispatch(setSnackBar(payload)),
    setBookmark: (payload) => dispatch(setBookmark(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SelectedFilters));
