import React from "react";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import IChart from "../common/IChart";
import UIUtils from "../../utils/uiUtils";

let styles = (theme) => ({
  root: {},
});

const StackedChart = (props) => {
  const options = {
    colors: [UIUtils.GetSelectedColor("red"), UIUtils.GetSelectedColor("amber"), UIUtils.GetSelectedColor("green")],
    chart: {
      type: "column",
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      height: 320,
      style: {
        fontFamily: "11px Montserrat, OpenSans, Helvetica, Arial, sans-serif",
      },
    },
    title: {
      text: "Trigger count, grouped by color",
    },

    xAxis: {
      categories: props.barChartCategories,
    },

    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: "Number of loans",
      },
    },
    credits: false,

    tooltip: {
      formatter: function () {
        return `${this.x}<br/><b>${this.series.name}: ${this.y}</b>`;
      },
      style: {
        font: "11px Montserrat, OpenSans, Helvetica, Arial, sans-serif",
        color: "#5a5a5a",
        verticalalign: "top",
      },
    },

    plotOptions: {
      column: {
        stacking: "normal",
      },
    },

    series: [],
  };

  const { barChartSeries } = props;

  const mapOptions = () => {
    const mappedOptions = { ...options };
    mappedOptions.series = barChartSeries;

    return mappedOptions;
  };

  return <IChart options={mapOptions()} />;
};

const mapStateToProps = (state) => {
  return {
    currentDate: state.dashboardReducer.currentDate,
    compareDate: state.dashboardReducer.compareDate,
    barChartSeries: state.analyticsReducer.barChartSeries,
    barChartCategories: state.analyticsReducer.barChartCategories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StackedChart));
