import React, { useEffect } from "react";
import { Grid, withStyles, Typography, useMediaQuery, useTheme, Box } from "@material-ui/core";
import { connect } from "react-redux";
import SettingsUtils from "../utils/settingsUtils";
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { setUserSettings } from "../actions/settingsActions";
import Paper from "@material-ui/core/Paper";
import ITab from "../components/common/ITab";
import IAccordion from "../components/common/IAccordion";

let styles = (theme) => ({
  root: {},
  alignLeft: {
    textAlign: "left",
    padding: theme.spacing(3)
  },
  whiteBg: {
    backgroundColor: `#FFFFFF`,
    overflow: "auto",
    padding: theme.spacing(3),
  },
  accordionContainer: {
    margin: theme.spacing(3),
  },
});

const Settings = (props) => {
  const classes = props;
  const [view, setView] = React.useState('');
  const theme = useTheme();
  const isUnderSmallSizeScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [value, setValue] = React.useState('1');

  const onLoad = () => {
    SettingsUtils.FetchUserSetting().then((data) => {
      props.setUserSettings(data)
    });
  };

  useEffect(onLoad, []);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleToggleChange = (event, nextView) => {
    if (nextView == null) {
      return;
    }
    setView(nextView);
    SettingsUtils.PutUserSetting(
      "Review", "Orientation", nextView
    ).then((res) => { });
  }

  const Settings = () => {
    const tabs = [
      {
        index: 0,
        label: "Review",
        content: <Grid container spacing={1}
          justifyContent="center"
          alignItems="stretch"
        >
          <Grid item>
            <Paper
              elevation={2}
              style={{
                padding: 10,
              }}>
              <Grid container className={classes.subSection} justifyContent="center">
                <Grid item xs={12} md={3} align="center">
                  <Box height="100%" display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    sx={{
                      [theme.breakpoints.down('sm')]: {
                        borderBottom: "1px solid #eaecf4",
                        marginBottom: theme.spacing(1)
                      },
                      [theme.breakpoints.up('md')]: {
                        borderRight: "1px solid #eaecf4"
                      },
                    }}>
                    <Typography>Account Review</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={9} justifyContent="center">
                  <Box sx={{
                    [theme.breakpoints.down('sm')]: {
                      marginTop: theme.spacing(1)
                    },
                  }}
                  >
                    <Typography variant="subtitle2">Suggestive actions page orientation</Typography>
                    <ToggleButtonGroup
                      color="primary"
                      exclusive
                      value={view || (props.userSettings.find((i) => i.Section === "Review" && i.Name === "Orientation")?.Value ?? "dynamic")}
                      onChange={handleToggleChange}
                      size="small"
                    >
                      <ToggleButton value="vertical">Vertical</ToggleButton>
                      <ToggleButton value="horizontal" aria-label="horizontal">Horizontal</ToggleButton>
                      <ToggleButton value="dynamic">Dynamic</ToggleButton>
                    </ToggleButtonGroup>
                    <Typography variant="caption" display="block">Pick an option to save your preference</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid >
        </Grid >
      },
    ];

    if (isUnderSmallSizeScreen) {
      return (
        <div className={classes.accordionContainer}>
          <IAccordion tabs={tabs}></IAccordion>
        </div>
      );
    }
    else {
      return (
        <Box sx={{ borderRadius: 5, backgroundColor: `#FFFFFF` }} className={`shadow-sm`}>
          <ITab tabs={tabs}></ITab>
        </Box >
      );
    }
  };

  return (
    <div className={classes.whiteBg}>
      <Typography variant="h6" alignLeft>User Settings</Typography>
      {Settings()}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userSettings: state.settingReducer.userSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserSettings: (payload) => dispatch(setUserSettings(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Settings));