import React, { useEffect } from "react";
import { setRowCloneMode, setLoanAccounts, setRelationshipManagers, setUnitControllers } from "../../actions/adminActions";
import AdminUtils from "../../utils/adminUtils";
import { connect } from "react-redux";
import { withStyles, Box, Typography } from "@material-ui/core";
import IEditable from "../common/IEditable";
import { setSnackBar } from "../../actions/commonActions";
import FormatUtils from "./../../utils/formatUtils";
import AccountUtils from "../../utils/accountUtils";
import HelperUtils from "../../utils/helperUtils";
import FlagRoundedIcon from '@material-ui/icons/FlagRounded';

let styles = (theme) => ({
  root: {},
});

const Accounts = (props) => {
  const { loanAccounts } = props;
  const onLoad = () => {
    AdminUtils.FetchAccounts().then((data) => {
      props.setLoanAccounts(AccountUtils.MapLoanAccounts(data));
    });
    AdminUtils.FetchRelationshipManagers().then((data) => {
      props.setRelationshipManagers(data);
    });
    AdminUtils.FetchUnitControllers().then((data) => {
      props.setUnitControllers(data);
    });
  };

  const mapRelationshipManagers = () => {
    const relationshipManagers = {};
    (props.relationshipManagers || []).forEach((rm) => {
      relationshipManagers[rm.EmailAddress] = rm.EmailAddress;
    });
    return relationshipManagers;
  };

  const columns = [
    {
      title: "BorrowerName",
      field: "BorrowerName",
      editable: "never",
    },
    {
      title: "AmountApproved",
      field: "AmountApproved",
      editable: "never",
    },
    {
      title: "AmountDisbursed",
      field: "AmountDisbursed",
      editable: "never",
    },
    {
      title: "Segment",
      field: "Segment",
      editable: "never",
    },
    {
      title: "Interest",
      field: "Interest",
      editable: "never",
    },
    {
      title: "LoanType",
      field: "LoanType",
      editable: "never",
    },
    {
      title: "Red Flagged Account",
      field: "RFA",
      editable: "never",
      cellStyle: rowData => (rowData.RFA === "Y") ? ({
        backgroundColor: "red",
        color: "red",
        textAlign: "center",
      }) : {},
      render: rowData => (rowData.RFA === "Y") ?
        <Box sx={{ bgcolor: "red", textAlign: "center", color: "white" }}><b>{rowData.RFA}</b></Box> :
        <Box sx={{ textAlign: "center" }}>{rowData.RFA}</Box>
    },
    // {
    //   title: "RMName",
    //   field: "RMName",
    //   lookup: mapRelationshipManagers(),
    //   onChange: (e) => {
    //     console.log("Value changed", e);
    //   },
    // },
  ];

  const mapLoansData = () => {
    const loans = props.loanAccounts ?? [];
    const assignableRoles = props?.organizationRoles?.filter((i) => i.IsAssignable) ?? [];
    const sortedRoles = HelperUtils.SortArrayBy(assignableRoles, "ReportsTo", "desc") ?? [];

    loans.forEach((i) => {
      sortedRoles.forEach((role) => {
        i[role.Name] = props?.unitControllers?.find(
          (u) => u.BorrowerId === i.BorrowerId && u?.UserRole?.OrganizationRoleId === role.OrganizationRoleId
        )?.UserRole?.User?.EmailAddress;
      });
    });

    return loans;
  };

  const options = {
    downloadOptions: {
      filename: FormatUtils.GetCSVFileName("Accounts"),
      separator: ",",
    },
    selection: true,
    selectionProps: rowData => ({
      disabled: rowData.RFA === "Y",
      color: 'primary'
    })
  };

  useEffect(onLoad, []);

  const onUpdate = (newValue) => {
    const timestamp = new Date();
    const currentUser = props.user?.User?.EmailAddress || props.user?.EmailAddress;

    const payload = {
      LoanId: newValue.LoanId,
      CreatedBy: currentUser,
      CreatedDate: timestamp,
      ModifiedBy: currentUser,
      ModifiedDate: timestamp,
      Controllers: [],
    };

    props.organizationRoles.forEach((role) => {
      if (newValue?.[role.Name]) {
        payload.Controllers.push({
          RoleName: role.Name,
          EmailAddress: newValue[role.Name],
        });
      }
    });

    AdminUtils.UpdateControllers(payload).then((data) => {
      if (data) {
        props.setSnackBar("Relationship manager updated");
      } else {
        props.setSnackBar("Failed to update relationship manager");
      }
      onLoad();
    });
  };

  var filters = [];
  const onFilterChange = (enabledFilters) => {
    filters = enabledFilters;
    return enabledFilters;
  }

  const onBulkUpdate = (changes) => {
    const selectedColumnFilters = filters.filter((i) => (i.column.tableData.id >= 6 && i.column.tableData.id <= 14)) ?? [];
    if (props.rowCloneMode) {
      if (selectedColumnFilters.length == 0) {
        props.setSnackBar("Select a filter for atleast one column when using Clone Mode");
        return;
      }
      else if (selectedColumnFilters.length > 1) {
        props.setSnackBar("Select a filter for only one column when using Clone Mode");
        return;
      }
      const filterValuesArray = selectedColumnFilters[0].value; // Support multiple values in one filter
      const filterField = selectedColumnFilters[0].column.field;
      props.setSnackBar("Bulk update operation (Clone mode) in progress");
      const loans = props.loanAccounts ?? [];
      var tempLoans;
      switch (filterField) {
        case "BranchManager":
          tempLoans = loans.filter((i) => filterValuesArray.some((j) => i.BranchManager == j)) ?? [];
          break;
        case "CSO":
          tempLoans = loans.filter((i) => filterValuesArray.some((j) => i.CSO == j)) ?? [];
          break;
        case "CircleManager":
          tempLoans = loans.filter((i) => filterValuesArray.some((j) => i.CircleManager == j)) ?? [];
          break;
        case "Controller":
          tempLoans = loans.filter((i) => filterValuesArray.some((j) => i.Controller == j)) ?? [];
          break;
        case "GroupManager":
          tempLoans = loans.filter((i) => filterValuesArray.some((j) => i.GroupManager == j)) ?? [];
          break;
        case "ModuleManager":
          tempLoans = loans.filter((i) => filterValuesArray.some((j) => i.ModuleManager == j)) ?? [];
          break;
        case "NetworkManager":
          tempLoans = loans.filter((i) => filterValuesArray.some((j) => i.NetworkManager == j)) ?? [];
          break;
        case "RegionManager":
          tempLoans = loans.filter((i) => filterValuesArray.some((j) => i.RegionManager == j)) ?? [];
          break;
        case "RelationshipManager":
          tempLoans = loans.filter((i) => filterValuesArray.some((j) => i.RelationshipManager == j)) ?? [];
          break;
        default:
          //Should never get here
          break;
      }
      var tempChanges = [];
      var tempChanges = Object.values(changes);
      for (var i = 0; i < tempLoans.length; i++) {
        var oldData = tempLoans[i];
        tempLoans[i].BranchManager = tempChanges[0].newData.BranchManager;
        tempLoans[i].CSO = tempChanges[0].newData.CSO;
        tempLoans[i].CircleManager = tempChanges[0].newData.CircleManager;
        tempLoans[i].Controller = tempChanges[0].newData.Controller;
        tempLoans[i].GroupManager = tempChanges[0].newData.GroupManager;
        tempLoans[i].ModuleManager = tempChanges[0].newData.ModuleManager;
        tempLoans[i].NetworkManager = tempChanges[0].newData.NetworkManager;
        tempLoans[i].RegionManager = tempChanges[0].newData.RegionManager;
        tempLoans[i].RelationshipManager = tempChanges[0].newData.RelationshipManager;
        tempChanges[i] = { oldData, newData: tempLoans[i] }
      }
      changes = tempChanges;
    }
    else {
      // Proceed with normal bulk update
      props.setSnackBar("Bulk update operation in progress");
    }

    const timestamp = new Date();
    const currentUser = AccountUtils.GetCurrentUserEmail(props.user);
    var sendArray = [];
    for (const key in changes) {
      if (Object.hasOwnProperty.call(changes, key)) {
        const element = changes[key].newData;

        const payload = {
          LoanId: element.LoanId,
          ModifiedBy: currentUser,
          ModifiedDate: timestamp,
          Controllers: [],
        };
        props.organizationRoles.forEach((role) => {
          if (element?.[role.Name]) {
            payload.Controllers.push({
              RoleName: role.Name,
              EmailAddress: element[role.Name],
            });
          }
        });
        sendArray.push(payload);
      }
    }

    AdminUtils.BulkUpdateControllers(sendArray).then((data) => {
      if (data) {
        props.setSnackBar("Bulk update of Accounts complete");
      } else {
        props.setSnackBar("Failed to bulk update Accounts");
      }
      onLoad();
    });

  };

  const mapManagers = (roleId) => {
    const managers = {};
    const roleUsers = props?.allUserRoles?.filter((i) => i.OrganizationRoleId === roleId);
    roleUsers.forEach((u) => {
      managers[u.User?.EmailAddress] = u.User?.EmailAddress;
    });

    return HelperUtils.SortObject(managers);
  };

  const mapColumns = () => {
    const assignableRoles = props?.organizationRoles?.filter((i) => i.IsAssignable);
    const sortedRoles = HelperUtils.SortArrayBy(assignableRoles, "ReportsTo", "desc");
    sortedRoles.forEach((r) => {
      columns.push({
        title: r.DisplayName,
        field: r.Name,
        lookup: mapManagers(r.OrganizationRoleId),
        onChange: (e) => {
          //console.log("Value changed", e);
        },
      });
    });
    return columns;
  };

  return (
    <React.Fragment >
      {
        loanAccounts.length > 0 && (
          <IEditable
            columns={mapColumns()}
            data={mapLoansData()}
            title={`Accounts management`}
            onUpdate={onUpdate}
            onBulkUpdate={onBulkUpdate}
            onFilterChange={onFilterChange}
            options={options}
            actions={[
              rowData => ({
                icon: () => <Box sx={{ color: "red" }}><FlagRoundedIcon /></Box>,
                tooltip: 'Mark as RFA',
                disabled: rowData.RFA === "Y",
                onClick: (event, data) => {
                  let rfaBorrowerIds = [];
                  data.map((item) => rfaBorrowerIds.push(item.BorrowerId));
                  //call the assign to admin API here and pass this list of borrowerIds
                  AdminUtils.BulkAssignRFA(rfaBorrowerIds).then((data) => {
                    if (data === "success") {
                      props.setSnackBar("Action successfully completed");
                      onLoad();
                    }
                    else if (data !== "success") {
                      props.setSnackBar("Action failed");
                    }
                  })
                }
              }),
              {
                icon: "queue",
                tooltip: "Clone mode",
                isFreeAction: true,
                onClick: (event) => {
                  props.setSnackBar(`You have ${!props.rowCloneMode ? "enabled" : "disabled"} multiple row edit`);
                  props.setRowCloneMode(!props.rowCloneMode);
                },
              },
            ]}
          />
        )
      }
    </React.Fragment >
  );
};

const mapStateToProps = (state) => {
  return {
    loanAccounts: state.adminReducer.loanAccounts,
    relationshipManagers: state.adminReducer.relationshipManagers,
    organizationRoles: state.adminReducer.organizationRoles,
    allUserRoles: state.adminReducer.allUserRoles,
    user: state.authReducer.user,
    unitControllers: state.adminReducer.unitControllers,
    rowCloneMode: state.adminReducer.rowCloneMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoanAccounts: (payload) => dispatch(setLoanAccounts(payload)),
    setRelationshipManagers: (payload) => dispatch(setRelationshipManagers(payload)),
    setUnitControllers: (payload) => dispatch(setUnitControllers(payload)),
    setSnackBar: (payload) => dispatch(setSnackBar(payload)),
    setRowCloneMode: (payload) => dispatch(setRowCloneMode(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Accounts));
