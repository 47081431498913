import React, { useEffect, useState } from "react";
import { Grid, withStyles } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import IDatePicker from "../components/common/IDatePicker";
import HelperUtils from "../utils/helperUtils";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReviewUtils from "../utils/reviewUtils";
import { setEscalations } from "../actions/reviewActions";
import { format } from "date-fns";
import EscalationsGrid from "../components/escalations/EscalationsGrid";

let styles = (theme) => ({
  root: {},
  datepicker: {
    paddingTop: `${theme.spacing(2)}px !important`,
  },
  switch: {
    padding: theme.spacing(1),
    textAlign: `right`,
    cursor: `pointer`,
    paddingTop: theme.spacing(2),
  },
  switchIcon: {
    fontSize: theme.spacing(3),
    color: `green`,
  },
  button: {
    margin: theme.spacing(1),
  },
});

const Escalations = (props) => {
  const { classes } = props;
  const [createdDate, setCreatedDate] = useState(new Date());
  const [currentDateError, setCurrentDateError] = useState(false);

  const handleDateChange = (date) => {
    if (!HelperUtils.IsValidDate(date) || date > new Date()) {
      setCurrentDateError(true);
      return
    };
    setCreatedDate(date);
  };

  useEffect(() => {
    ReviewUtils.FetchEscalations(format(createdDate, "yyyy-MM-dd")).then((data) => {
      props.setEscalations(data);
    });
  }, [createdDate]);
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={3} md={3} sm={6} xs={12} className={classes.datepicker}>
          <Tooltip title="Created Date" aria-label="created-date">
            <IDatePicker label={"Current Date"} value={createdDate} maxDate={new Date()}
              onChange={(date) => handleDateChange(date)}
              error={currentDateError}></IDatePicker>
          </Tooltip>
        </Grid>
      </Grid>
      <EscalationsGrid></EscalationsGrid>
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    selectedAccount: state.accountReducer.selectedAccount,
    currentDate: state.dashboardReducer.currentDate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEscalations: (payload) => dispatch(setEscalations(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(Escalations)));
