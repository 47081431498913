import React from "react";
import { Grid, withStyles, Button, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { setSnackBar } from "../../actions/commonActions";
import FormatUtils from "./../../utils/formatUtils";
import {
  setSelectedTrigger,
  setSelectedTriggerColor,
  setSelectedTriggerValue,
} from "../../actions/triggerActions";

let styles = (theme) => ({
  red: {
    backgroundColor: `red`,
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: theme.spacing(3),
    marginRight: theme.spacing(1),
    borderRadius: `50%`,
    display: `inline-block`,
    verticalAlign: `bottom`,
  },
  amber: {
    background: `#FFC200`,
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: theme.spacing(3),
    marginRight: theme.spacing(1),
    borderRadius: `50%`,
    display: `inline-block`,
    verticalAlign: `bottom`,
  },
  green: {
    background: `green`,
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: theme.spacing(3),
    marginRight: theme.spacing(1),
    borderRadius: `50%`,
    display: `inline-block`,
    verticalAlign: `bottom`,
  },
  popupDialog: {
    padding: theme.spacing(1),
  },
  dialogTitle: {
    padding: theme.spacing(0),
  },
});

const RiskGradeBenchmark = (props) => {
  const { classes, color, value } = props;

  const defaultColor = "red";

  const handleClick = (event) => {
    if (value) {
      props.setSelectedTriggerColor(color || defaultColor);
      props.setSelectedTriggerValue(value);
    } else {
      props.setSnackBar("No Loans Found");
    }
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Tooltip
            title={`${FormatUtils.ToTitleCase(color)} loans`}
            aria-label={`${color}-loans`}
          >
            <Button className={classes[color]} onClick={handleClick} />
          </Tooltip>
          {value}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedTrigger: state.triggerReducer.selectedTrigger,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedTriggerColor: (payload) =>
      dispatch(setSelectedTriggerColor(payload)),
    setSelectedTrigger: (payload) => dispatch(setSelectedTrigger(payload)),
    setSelectedTriggerValue: (payload) =>
      dispatch(setSelectedTriggerValue(payload)),
    setSnackBar: (payload) => dispatch(setSnackBar(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RiskGradeBenchmark));
