import { Fab, Tooltip, withStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import { connect } from "react-redux";
import { useState } from "react";
import IDialog from "./IDialog";
import BookmarkUtils from "./../../utils/bookmarkUtils";
import { setUserBookmarks } from "../../actions/bookmarkActions";

import BookmarksTable from "../bookmarks/BookmarksTable";
let styles = (theme) => ({
  bookmark: {
    float: "left",
    margin: `0px ${theme.spacing(2)}px 0px ${theme.spacing(2)}px`,
  },
});

const Bookmarks = (props) => {
  const { classes } = props;
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setOpen(!open);
  };

  const handleCloseDialog = (event) => {
    setOpen(false);
  };

  const fetchUserBookmarks = () => {
    if (!open) return;
    BookmarkUtils.GetUserBookmarks(props.userId).then((data) => {
      props.setUserBookmarks(data);
    });
  };
  useEffect(fetchUserBookmarks, [open]);

  return (
    <React.Fragment>
      <Tooltip title="Bookmarked Filters" aria-label="bookmarked-filters">
        <Fab size="small" color="secondary" aria-label="bookmarks" className={classes.bookmark} onClick={handleClick}>
          <BookmarksIcon />
        </Fab>
      </Tooltip>
      <IDialog
        title={
          <div>
            <BookmarksIcon />
            Saved bookmarks
          </div>
        }
        open={open}
        content={<BookmarksTable onClose={handleCloseDialog} />}
        onClose={handleCloseDialog}
      ></IDialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userBookmarks: state.bookmarkReducer.userBookmarks,
    userId: state.authReducer.user?.UserId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserBookmarks: (payload) => dispatch(setUserBookmarks(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Bookmarks));
