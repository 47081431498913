import {
  SET_OVERVIEW,
  SET_STRUCTURED_TRIGGERS,
  SET_UNSTRUCTURED_TRIGGERS,
  SET_LLMS_TRIGGERS,
  SET_CURRENT_DATE,
  SET_COMPARE_DATE,
  SET_SELECTED_FILTERS,
  SET_FILTER_DETAILS,
  SET_SELECTED_FILTER_ITEMS,
  CLEAR_SELECTED_FILTERS,
  SET_SELECTED_FILTER_VALUES,
  SET_FILTER_HIERARCHY,
  SET_NPA_LOANS,
} from "./../actions/dashboardActions";

const initialState = {
  overview: [],
  structuredTriggers: [],
  unstructuredTriggers: [],
  llmsTriggers: [],
  currentDate: "",
  compareDate: "",
  selectedFilters: [],
  filterDetails: "",
  selectedFilterItems: {},
  selectedFilterValues: {},
  filterHierarchy: null,
  npaLoans: [],
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OVERVIEW:
      return {
        ...state,
        overview: [...action.payload],
      };
    case SET_STRUCTURED_TRIGGERS:
      return {
        ...state,
        structuredTriggers: { ...action.payload },
      };
    case SET_CURRENT_DATE:
      return {
        ...state,
        currentDate: action.payload,
      };
    case SET_COMPARE_DATE:
      return {
        ...state,
        compareDate: action.payload,
      };
    case SET_UNSTRUCTURED_TRIGGERS:
      return {
        ...state,
        unstructuredTriggers: { ...action.payload },
      };
    case SET_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: !Array.isArray(action.payload) ? [...state.selectedFilters, { ...action.payload }] : [...action.payload],
      };
    case SET_FILTER_DETAILS:
      return {
        ...state,
        filterDetails: { ...action.payload },
      };
    case SET_SELECTED_FILTER_ITEMS:
      return {
        ...state,
        selectedFilterItems: {
          ...action.payload,
        },
      };
    case CLEAR_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: [],
        selectedFilterValues: {},
        selectedFilterItems: {},
      };
    case SET_SELECTED_FILTER_VALUES:
      return {
        ...state,
        selectedFilterValues: { ...action.payload },
      };
    case SET_LLMS_TRIGGERS:
      return {
        ...state,
        llmsTriggers: { ...action.payload },
      };
    case SET_FILTER_HIERARCHY:
      return {
        ...state,
        filterHierarchy: [...action.payload],
      };
    case SET_NPA_LOANS:
      return {
        ...state,
        npaLoans: [...action.payload],
      };
    default:
      return state;
  }
};

export default dashboardReducer;
