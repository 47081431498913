import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./../reducers/index";

const round = (number) => Math.round(number * 100) / 100;

const monitorReducerEnhancer = (createStore) => (reducer, initialState, enhancer) => {
  const monitoredReducer = (state, action) => {
    const start = performance.now();
    const newState = reducer(state, action);
    const end = performance.now();
    const diff = round(end - start);
    console.log("Reducer process time:", diff);
    return newState;
  };

  return createStore(monitoredReducer, initialState, enhancer);
};

const storeLogger = (store) => (next) => (action) => {
  console.group(action.type);
  console.info("Dispatching", action);
  let result = next(action);
  console.log("Next State", store.getState());
  console.groupEnd();
  return result;
};

const configureStore = (preLoadedState) => {
  const middlewares = [storeLogger]; //thunkMiddleware
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer, monitorReducerEnhancer];
  const composedEnhancers = compose(...enhancers);

  const store = createStore(rootReducer, preLoadedState, composedEnhancers);

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("../reducers", () => store.replaceReducer(rootReducer));
  }

  return store;
};

export const store = configureStore();
