import { SET_USER_SETTINGS } from "../actions/settingsActions";

const initialState = {
  userSettings: [],
};

const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_SETTINGS:
      return {
        ...state,
        userSettings: [...action.payload],
      };
    default:
      return state;
  }
};

export default settingReducer;
