import HttpUtils from "./httpUtils";
import config from "./../config/config";

const fetchUserRoles = async () => {
  const data = await HttpUtils.Get(`${config.apiUrl}/admin/userRoles`);
  return data?.data;
};

const fetchAccounts = async () => {
  const data = await HttpUtils.Get(`${config.apiUrl}/admin/accounts`);
  return data?.data;
};

const fetchUsers = async () => {
  const data = await HttpUtils.Get(`${config.apiUrl}/admin/users`);
  return data?.data;
};

const fetchOrganizationRoles = async () => {
  const data = await HttpUtils.Get(`${config.apiUrl}/admin/organizationRoles`);
  return data?.data;
};

const fetchUsersBelongingToRole = async (id) => {
  const data = await HttpUtils.Get(`${config.apiUrl}/admin/role/users/${id}`);
  return data?.data;
};

const updateUserRole = async (payload) => {
  const data = await HttpUtils.Post(`${config.apiUrl}/admin/updateUserRole`, payload);
  return data?.data;
};

const bulkUpdateUserRole = async (payload) => {
  const data = await HttpUtils.Post(`${config.apiUrl}/admin/bulkUpdateUserRole`, payload);
  return data?.data;
};

const fetchRelationshipManagers = async (payload) => {
  const data = await HttpUtils.Get(`${config.apiUrl}/admin/relationshipmanagers`);
  return data?.data;
};

const updateRelationshipManager = async (payload) => {
  const data = await HttpUtils.Post(`${config.apiUrl}/admin/relationshipmanagersupdate`, payload);
  return data?.data;
};

const fetchAllUserRoles = async () => {
  const data = await HttpUtils.Get(`${config.apiUrl}/auth/userroles`);
  return data?.data;
};

const updateControllers = async (payload) => {
  const data = await HttpUtils.Post(`${config.apiUrl}/admin/updatecontrollers`, payload);
  return data?.data;
};

const bulkUpdateControllers = async (payload) => {
  const data = await HttpUtils.Post(`${config.apiUrl}/admin/bulkUpdateControllers`, payload);
  return data?.data;
};

const fetchUnitControllers = async (payload) => {
  const data = await HttpUtils.Get(`${config.apiUrl}/admin/unitcontrollers`);
  return data?.data;
};

const bulkAssignRFA = async (payload) => {
  const data = await HttpUtils.Post(`${config.apiUrl}/admin/bulkAssignRFA`, payload);
  return data?.data;
};

const AdminUtils = {
  FetchUserRoles: fetchUserRoles,
  FetchUsersBelongingRole: fetchUsersBelongingToRole,
  FetchAccounts: fetchAccounts,
  FetchUsers: fetchUsers,
  FetchOrganizationRoles: fetchOrganizationRoles,
  FetchRelationshipManagers: fetchRelationshipManagers,
  UpdateUserRole: updateUserRole,
  BulkUpdateUserRole: bulkUpdateUserRole,
  UpdateRelationshipManager: updateRelationshipManager,
  FetchAllUserRoles: fetchAllUserRoles,
  UpdateControllers: updateControllers,
  BulkUpdateControllers: bulkUpdateControllers,
  FetchUnitControllers: fetchUnitControllers,
  BulkAssignRFA: bulkAssignRFA
};

export default AdminUtils;
