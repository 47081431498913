export const SET_SELECTED_TRIGGER_TYPE = "SET_SELECTED_TRIGGER_TYPE";
export const setSelectedTriggerType = (payload) => ({
  type: SET_SELECTED_TRIGGER_TYPE,
  payload: payload,
});

export const SET_SELECTED_TRIGGER = "SET_SELECTED_TRIGGER";
export const setSelectedTrigger = (payload) => ({
  type: SET_SELECTED_TRIGGER,
  payload: payload,
});

export const SET_SELECTED_TRIGGER_VALUE = "SET_SELECTED_TRIGGER_VALUE";
export const setSelectedTriggerValue = (payload) => ({
  type: SET_SELECTED_TRIGGER_VALUE,
  payload: payload,
});

export const SET_TRIGGERED_LOANS = "SET_TRIGGERED_LOANS";
export const setTriggeredLoans = (payload) => ({
  type: SET_TRIGGERED_LOANS,
  payload: payload,
});

export const SET_SELECTED_TRIGGER_COLOR = "SET_SELECTED_TRIGGER_COLOR";
export const setSelectedTriggerColor = (payload) => ({
  type: SET_SELECTED_TRIGGER_COLOR,
  payload: payload,
});

export const CLEAR_SELECTED_TRIGGERS = "CLEAR_SELECTED_TRIGGERS";
export const clearSelectedTriggers = (payload) => ({
  type: CLEAR_SELECTED_TRIGGERS,
  payload: payload,
});
