import React from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core";

let styles = (theme) => ({
  header: {},
  body: {
    textAlign: `right`,
    fontSize: theme.spacing(1.5),
  },
  value: {
    textAlign: `right`,
    fontSize: theme.spacing(2),
    fontWeight: theme.spacing(80),
  },
  icon: {
    float: `left`,
    padding: theme.spacing(3),
    marginTop: theme.spacing(-2.5),
    marginRight: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
  },
  difference: {
    fontWeight: theme.spacing(80),
  },
});

const OverviewItem = (props) => {
  const {
    classes,
    icon,
    title,
    value,
    difference,
    bgClass,
    percentageField,
  } = props;
  return (
    <Card>
      <CardContent className={classes.body}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <div className={`${classes.icon} ${bgClass}`}>{icon}</div>
          </Grid>
          <Grid item xs={9}>
            {percentageField && (
              <div className={classes.difference}>
                <span
                  className={`${
                    difference > 0 ? "text-success" : "text-danger"
                  }`}
                >
                  {difference}
                </span>
              </div>
            )}
            <div className={classes.value}>{value}</div>
            <div className={classes.title}>{title}</div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(OverviewItem);
