import HttpUtils from "./httpUtils";
import config from "../config/config";

const fetchUserSetting = async()=>{
  const data = await HttpUtils.Get(
    `${config.apiUrl}/Settings/userSettings`
  );
  return data?.data;
}

const putUserSetting = async(settingSection, settingName, settingValue)=>{
  const data = await HttpUtils.Put(
    `${config.apiUrl}/Settings/userSettings`,{
      Section: settingSection,
      Name: settingName,
      Value: settingValue,
    });
  return data?.data;
}

const SettingsUtils = {
  FetchUserSetting : fetchUserSetting,
  PutUserSetting : putUserSetting
};

export default SettingsUtils;
