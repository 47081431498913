import React, { useEffect, useState } from "react";
import { Button, Grid, Tooltip, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import TriggersTab from "../components/common/TriggersTab";
import ConfigurationUtils from "../utils/configurationUtils";
import ISelect from "../components/common/ISelect";
import HelperUtils from "../utils/helperUtils";
import MaterialTable from "material-table";
import FormatUtils from "../utils/formatUtils";

let styles = (theme) => ({
  root: {},
  alignLeft: {
    textAlign: "left",
  },
  dropdown: {
    marginTop: theme.spacing(1),
  },
  red: {
    backgroundColor: `red`,
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: theme.spacing(3),
    borderRadius: `50%`,
    display: `inline-block`,
    verticalAlign: `bottom`,
  },
  amber: {
    background: `#FFC200`,
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: theme.spacing(3),
    borderRadius: `50%`,
    display: `inline-block`,
    verticalAlign: `bottom`,
  },
  green: {
    background: `green`,
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: theme.spacing(3),
    borderRadius: `50%`,
    display: `inline-block`,
    verticalAlign: `bottom`,
  },
});

const Configuration = (props) => {
  const columns = [
    {
      title: "Color",
      field: "Color",
      editable: "never",
      render: (rowData) => {
        const color = rowData["Color"]?.toLowerCase();
        if (color) {
          return (
            <Tooltip title={`${FormatUtils.ToTitleCase(color)}`} aria-label={`${color}`}>
              <Button className={classes[color]} />
            </Tooltip>
          );
        }
        return "";
      },
    },
    {
      title: "Lower Limit",
      field: "LowerLimit",
      type: "numeric",
    },
    {
      title: "Upper Limit",
      field: "UpperLimit",
      type: "numeric",
    },
  ];

  const { classes } = props;
  const [triggerOptions, setTriggerOptions] = useState("");
  const [selectedTrigger, setSelectedTrigger] = useState("");
  const [triggerConfiguration, setTriggerConfiguration] = useState("");

  const onLoad = () => {
    const triggerType = props.selectedTriggerType?.key;
    fetchTriggers(triggerType);
  };

  const fetchTriggers = (triggerType) => {
    setTriggerOptions("");
    setTriggerConfiguration("");
    ConfigurationUtils.FetchTriggers(triggerType).then((data) => {
      const triggers = data?.map((item) => {
        return {
          Label: `${item["Dimension"] ? `${item["Dimension"]} - ` : ""}${item["TriggerName"]}`,
          ...item,
        };
      });
      const options = HelperUtils.MapOptions(triggers, "Label", "TriggerId");
      setTriggerOptions(options);
    });
  };

  const fetchTriggerConfiguration = (triggerId) => {
    ConfigurationUtils.FetchTriggerConfiguration(triggerId).then((data) => {
      setTriggerConfiguration(data);
    });
  };

  useEffect(onLoad, [props.selectedTriggerType?.id]);

  const handleTriggerSelect = (event, value) => {
    const triggerId = value.value;
    setSelectedTrigger(value);
    setTriggerConfiguration("");
    fetchTriggerConfiguration(triggerId);
  };

  const handleRowUpdate = async (newData) => {
    const triggerId = selectedTrigger.value;

    const newLimits = ConfigurationUtils.RearrangeTriggerLimits(newData.TriggerConfigurationId, triggerConfiguration, newData);

    let data = await ConfigurationUtils.UpdateTriggerConfiguration(triggerId, newLimits);

    return data;
  };

  return (
    <div>
      <Grid item lg={9} md={9} sm={6} xs={12} className={classes.alignLeft}>
        <TriggersTab />
      </Grid>
      <Grid container spacing={3}>
        <Grid item lg={3} md={4} sm={12} xs={12}>
          <div className={classes.dropdown}>
            {triggerOptions?.length > 0 && (
              <ISelect options={triggerOptions} label={"Select Trigger"} onChange={handleTriggerSelect} disableClear={true}></ISelect>
            )}
          </div>
        </Grid>
      </Grid>
      {triggerConfiguration && (
        <Grid container spacing={3}>
          <Grid item lg={6} md={9} sm={12} xs={12}>
            <div style={{ maxWidth: "100%" }}>
              <MaterialTable
                columns={columns}
                data={triggerConfiguration}
                title={"Trigger Configuration"}
                editable={{
                  onRowUpdate: (newData, oldData) => {
                    return new Promise(async (resolve, reject) => {
                      try {
                        let data = await handleRowUpdate(newData);

                        setTriggerConfiguration(data);
                        resolve();
                      } catch (err) {
                        reject(err);
                      }
                    });
                  },
                }}
              />
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedTriggerType: state.triggerReducer.selectedTriggerType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Configuration));
