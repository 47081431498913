import React from "react";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import IChart from "../common/IChart";
import UIUtils from "../../utils/uiUtils";

let styles = (theme) => ({
  root: {},
});

const ILineChart = (props) => {
  const { series, categories } = props;

  const options = {
    colors: [UIUtils.GetSelectedColor("red"), UIUtils.GetSelectedColor("amber"), UIUtils.GetSelectedColor("green"), "black"],
    chart: {
      type: "column",
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      height: 480,
      style: {
        fontFamily: "11px Montserrat, OpenSans, Helvetica, Arial, sans-serif",
      },
    },
    title: {
      text: "Monthly Risk Movement",
    },

    xAxis: {
      categories: categories,
    },

    yAxis: {
      allowDecimals: false,
      // min: 0,
      visible: false,
      title: {
        text: "Risk movement",
      },
    },
    credits: false,

    tooltip: {
      formatter: function () {
        return `<b>${this.point.alertcount} alerts</b><br/>Structured - ${this.point.structured}<br/>Unstructured - ${this.point.unstructured}<br/>LLMS-${this.point.llms}`;
      },
      style: {
        font: "11px Montserrat, OpenSans, Helvetica, Arial, sans-serif",
        color: "#5a5a5a",
        verticalalign: "top",
      },
    },

    plotOptions: {
      column: {
        stacking: "normal",
      },
      series: {
        dataLabels: {
          enabled: true,
          borderRadius: 2,
          y: -10,
          shape: "callout",
          formatter: function () {
            return this.point.alertcount > 0 ? `${this.point.alertcount} triggers` : "";
          },
        },
      },
    },

    series: [],
  };

  const mapOptions = () => {
    const mappedOptions = { ...options };
    mappedOptions.series = series;

    return mappedOptions;
  };

  return <IChart options={mapOptions()}></IChart>;
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ILineChart));
