import React from "react";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import ISelect from "../common/ISelect";
import { setSelectedAccount } from "../../actions/accountActions";

let styles = (theme) => ({
  dropdown: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

const AccountSelect = (props) => {
  const { classes, accounts, onChange } = props;

  const handleAccountChange = (event, value) => {
    onChange(value);
  };

  return (
    <div className={classes.dropdown}>
      {accounts?.length > 0 && <ISelect options={accounts} label={"Select Account"} onChange={handleAccountChange} disableClear={true}></ISelect>}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    accounts: state.dashboardReducer.filterDetails.Account,
    selectedAccount: state.accountReducer.selectedAccount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //setSelectedAccount: (payload) => dispatch(setSelectedAccount(payload)),
    // setAccountTriggers: (payload) => dispatch(setAccountTriggers(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountSelect));
