import _ from "lodash";
import { isValid } from "date-fns";
import DataUtils from "./dataUtils";

const findDifferenceBy = (firstArray, secondArray, comparer) => {
  return _.differenceBy(firstArray, secondArray, comparer);
};

const extractProperty = (array, property) => {
  return _.map(array, property);
};

const pickProperties = (object, properties) => {
  return _.pick(object, properties);
};

const splitString = (string, delimiter) => {
  return _.split(string, delimiter);
};

const getIntersectionWith = (firstArray, secondArray, comparer) => {
  return _.intersectionWith(firstArray, secondArray, comparer);
};

const isEqual = (a, b) => {
  return _.isEqual(a, b);
};

const isValidDate = (date) => {
  return isValid(date);
};

const sortArrayBy = (array, property, order) => {
  const sortedArr = _.sortBy(array, property);
  return order.toLowerCase() === "desc" ? sortedArr.reverse() : sortedArr;
};

const formatDataToTriggerSeries = (filterId, data, color, filterHierarchy) => {
  const filterItem = filterHierarchy?.find((item) => item.value === filterId);

  const mappedItems = data
    .filter((item) => item.Color?.toLowerCase() === color?.toLowerCase())
    .map((item) => {
      return {
        filterId: filterId,
        color: item.Color,
        name: item[filterItem.apiKey],
        y: 1,
      };
    });

  const final = _(mappedItems)
    .groupBy("name")
    .map((objs, key) => ({
      filterId: filterId,
      y: _.sumBy(objs, "y"),
      name: key,
    }))
    .value();

  return final;
};

const isObjectEmpty = (obj) => {
  return _.isEmpty(obj);
};

const mapOptions = (array, labelProperty, valueProperty) => {
  return array.map((item) => {
    return {
      label: item[labelProperty],
      value: item[valueProperty],
      name: item[labelProperty],
    };
  });
};

const groupBy = (collection, iteratee) => {
  return _.groupBy(collection, iteratee);
};

const intersectionBy = (firstArray, secondArray, comparer) => {
  return _.intersectionBy(firstArray, secondArray, comparer);
};

const isEmpty = (obj) => {
  return _.isEmpty(obj);
};

const sortObject = (o) => {
  return Object.keys(o)
    .sort()
    .reduce((r, k) => ((r[k] = o[k]), r), {});
};

const HelperUtils = {
  FindDifferenceBy: findDifferenceBy,
  ExtractProperty: extractProperty,
  PickProperties: pickProperties,
  SplitString: splitString,
  IntersectionWith: getIntersectionWith,
  IsEqual: isEqual,
  IsValidDate: isValidDate,
  SortArrayBy: sortArrayBy,
  FormatDataToTriggerSeries: formatDataToTriggerSeries,
  IsObjectEmpty: isObjectEmpty,
  MapOptions: mapOptions,
  GroupBy: groupBy,
  IntersectionBy: intersectionBy,
  IsEmpty: isEmpty,
  SortObject: sortObject,
};

export default HelperUtils;
