import React from "react";
import { connect } from "react-redux";
import { Button, Tooltip, withStyles } from "@material-ui/core";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

import FormatUtils from "../../utils/formatUtils";
import { setSelectedDimensionColor } from "../../actions/analyticsActions";

let styles = (theme) => ({
  root: {},
  group: {
    // marginTop: theme.spacing(1),
  },
  red: {
    backgroundColor: `red`,
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: theme.spacing(3),
    borderRadius: `50%`,
    display: `inline-block`,
    verticalAlign: `bottom`,
  },
  amber: {
    background: `#FFC200`,
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: theme.spacing(3),
    borderRadius: `50%`,
    display: `inline-block`,
    verticalAlign: `bottom`,
  },
  green: {
    background: `green`,
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: theme.spacing(3),
    borderRadius: `50%`,
    display: `inline-block`,
    verticalAlign: `bottom`,
  },
});

const ColorToggle = (props) => {
  const { classes, onChange } = props;

  const buttons = [
    {
      color: "red",
      label: "Red",
    },
    {
      color: "amber",
      label: "Amber",
    },
    {
      color: "green",
      label: "Green",
    },
  ];

  const defaultDimensionColor = "red";

  const handleChange = (event, value) => {
    props.setSelectedDimensionColor(value);
    onChange(value);
  };

  return (
    <ToggleButtonGroup
      orientation="horizontal"
      value={props.selectedDimensionColor || defaultDimensionColor}
      exclusive
      onChange={handleChange}
      className={classes.group}
    >
      {buttons.map((item) => {
        return (
          <ToggleButton
            key={item.color}
            value={item.color}
            aria-label={item.label}
          >
            <Tooltip
              title={`${FormatUtils.ToTitleCase(item.color)}`}
              aria-label={`${item.color}`}
            >
              <Button className={classes[item.color]} />
            </Tooltip>
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedDimensionColor: state.analyticsReducer.selectedDimensionColor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedDimensionColor: (payload) =>
      dispatch(setSelectedDimensionColor(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ColorToggle));
