import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, useMediaQuery, useTheme, withStyles } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let styles = (theme) => ({
  root: {
    minHeight: "75vh",
    width: `-webkit-fill-available`,
  },
  dialogTitle: {
    textAlign: "left",
    borderBottom: `1px solid #ccc`,
    background: "#6290ab",
    color: "#FFF",
    "& span": {
      fontWeight: "bold",
      fontSize: theme.spacing(2),
    },
    padding: `8px 16px`,
  },
  closeIcon: {
    float: "right",
  },
  title: {
    paddingTop: theme.spacing(1),
  },
});
const IDialog = (props) => {
  const { classes, onClose, open, title, content, fullScreen } = props;

  return (
    <Dialog
      classes={{ paper: classes.root }}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      TransitionComponent={Transition}
      keepMounted
      open={open}
      fullScreen={fullScreen === false ? false : true}
    >
      {title && (
        <DialogTitle id="simple-dialog-title" className={classes.dialogTitle}>
          <IconButton className={classes.closeIcon} edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <div className={classes.title}>{title}</div>
        </DialogTitle>
      )}
      <DialogContent>{content}</DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(IDialog);
