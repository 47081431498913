import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const IAccordion = (props) => {
  const { tabs } = props;
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }

  return (
    <React.Fragment>
      {tabs.map((tab) => {
        return (
          <Accordion defaultExpanded={false} expanded={expanded === tab.index} onChange={handleChange(tab.index)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography variant="h6">{tab.label}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ overflow: "auto" }}>
              {tab.content}
            </AccordionDetails>
          </Accordion>
        )
      })
      }
    </React.Fragment >
  )
}

export default IAccordion;