import React from "react";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import { Tooltip, withStyles } from "@material-ui/core";

let styles = (theme) => ({
  custom: {
    cursor: "pointer",
  },
});

const ITooltip = (props) => {
  const { classes } = props;
  return (
    <Tooltip className={classes.custom} title={props.title} placement="left-start">
      <LiveHelpIcon color="primary" fontSize="small"></LiveHelpIcon>
    </Tooltip>
  );
};

export default withStyles(styles)(ITooltip);
