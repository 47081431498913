import React from "react";
import { withStyles, Snackbar, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

let styles = (theme) => ({});

const ISnackBar = (props) => {
  const { classes, open, onClose, message, duration } = props;

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={duration || 3000}
        onClose={onClose}
        message={message || "This is default message"}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
};

export default withStyles(styles)(ISnackBar);
