import {
  ROW_CLONE_MODE,
  SET_ALL_USER_ROLES,
  SET_LOAN_ACCOUNTS,
  SET_ORGANIZATION_ROLES,
  SET_RELATIONSHIP_MANAGERS,
  SET_UNIT_CONTROLLERS,
  SET_USERS,
  SET_USER_ROLES,
} from "../actions/adminActions";

const initialState = {
  userRoles: [],
  loanAccounts: [],
  users: [],
  organizationRoles: [],
  relationshipManagers: [],
  rowCloneMode: false,
  allUserRoles: [],
  unitControllers: [],
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_ROLES:
      return {
        ...state,
        userRoles: [...action.payload],
      };
    case SET_LOAN_ACCOUNTS:
      return {
        ...state,
        loanAccounts: [...action.payload],
      };
    case SET_USERS:
      return {
        ...state,
        users: [...action.payload],
      };
    case SET_ORGANIZATION_ROLES:
      return {
        ...state,
        organizationRoles: [...action.payload],
      };
    case SET_RELATIONSHIP_MANAGERS:
      return {
        ...state,
        relationshipManagers: [...action.payload],
      };
    case ROW_CLONE_MODE:
      return {
        ...state,
        rowCloneMode: action.payload,
      };
    case SET_ALL_USER_ROLES:
      return {
        ...state,
        allUserRoles: [...action.payload],
      };

    case SET_UNIT_CONTROLLERS:
      return {
        ...state,
        unitControllers: [...action.payload],
      };

    default:
      return state;
  }
};

export default adminReducer;
