import React from "react";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IBreadCrumb from "../common/IBreadCrumb";
import { clearSelectedTriggers } from "../../actions/triggerActions";

let styles = (theme) => ({
  root: {},
});

const BreadCrumbs = (props) => {
  const handleBreadCrumbClick = (event) => {
    props.clearSelectedTriggers();
  };

  const items = [
    {
      link: "/structuredtriggers",
      onClick: handleBreadCrumbClick,
      title: "View All Structured Triggers",
      icon: <ArrowBackIcon />,
      routed: false,
      triggerType: 1,
    },
    {
      link: "/unstructuredtriggers",
      onClick: handleBreadCrumbClick,
      title: "View All Unstructured Triggers",
      icon: <ArrowBackIcon />,
      routed: false,
      triggerType: 2,
    },
    {
      link: "/llmstriggers",
      onClick: handleBreadCrumbClick,
      title: "View All LLMS Triggers",
      icon: <ArrowBackIcon />,
      routed: false,
      triggerType: 3,
    },
  ];

  const getItems = () => {
    return items.filter((item) => item.triggerType === props.selectedTriggerType?.id);
  };

  return <IBreadCrumb items={getItems()}></IBreadCrumb>;
};

const mapStateToProps = (state) => {
  return {
    selectedTriggerType: state.triggerReducer.selectedTriggerType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearSelectedTriggers: (payload) => dispatch(clearSelectedTriggers()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BreadCrumbs));
