import React, { useState } from "react";
import { Button, Card, Grid, TextField, Tooltip } from "@material-ui/core";
import { CardHeader } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import Criticality from "./../dashboard/Criticality";
import ISelect from "../common/ISelect";
import IKeyValuePair from "../common/IKeyValuePair";
import FormatUtils from "./../../utils/formatUtils";
import HelperUtils from "./../../utils/helperUtils";
import ITextField from "../common/ITextField";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import DoneAll from "@material-ui/icons/DoneAll";
import RedoIcon from "@material-ui/icons/Redo";

let styles = (theme) => ({
  root: {},
  cardHeaderText: {
    fontSize: theme.spacing(1.5),
    textAlign: "left",
  },
  cardHeader: {
    paddingBottom: theme.spacing(0),
  },
  card: {
    minHeight: theme.spacing(25),
  },
  criticality: {
    maxWidth: theme.spacing(20),
  },
  historyTitle: {
    fontSize: theme.spacing(1.5),
    fontWeight: "bolder",
    textAlign: "left",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: "#bbb",
  },
  completeAction: {
    cursor: `pointer`,
  },

  actionsContainer: {
    marginBottom: theme.spacing(2),
    float: `right`,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

const ReviewCard = (props) => {
  const {
    data,
    classes,
    actionOptions,
    onChange,
    onRemarksChange,
    selectedActions,
    reviewHistory,
    remarks,
    remarksHistory,
    closureHistory,
    onActionComplete,
    canCloseAlert,
    onClose,
    canReopen,
    handleReopen,
    enableSelect,
  } = props;

  const handleChange = (event, option) => {
    const newValues = formatReviewActions(option);
    onChange(newValues);
  };

  const handleRemarksChange = (e) => {
    onRemarksChange(data, e.currentTarget.value);
  };

  const formatReviewActions = (value) => {
    return {
      TriggerId: data?.TriggerId,
      SuggestiveActions: value,
    };
  };

  const handleCloseAlert = () => {
    onClose(data);
  };

  const renderCompleteAction = (item) => {
    if (item.IsComplete) {
      return (
        <Tooltip title="Action is complete" aria-label="action-complete">
          <VerifiedUser color="success"></VerifiedUser>
        </Tooltip>
      );
    }

    return (
      <Tooltip title="Click to mark complete" aria-label="mark-complete">
        <CheckCircleOutline className={classes.completeAction} onClick={() => onActionComplete(item)}></CheckCircleOutline>
      </Tooltip>
    );
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.cardHeader}
        title={
          <Tooltip title={`${data?.TriggerName}`} aria-label={`${data?.TriggerId}`}>
            <div className={classes.cardHeaderText}>
              {data?.TriggerName}
              <span className={classes.criticality}>
                <Criticality value={data?.Criticality} />
              </span>
            </div>
          </Tooltip>
        }
      ></CardHeader>
      <CardContent>
        <ISelect
          options={actionOptions || []}
          multiple={true}
          label={"Suggestive Action"}
          value={HelperUtils.MapOptions(selectedActions || [], "label", "value")}
          onChange={handleChange}
          disabled={!enableSelect}
        ></ISelect>

        {reviewHistory?.length > 0 && (
          <React.Fragment>
            <div className={classes.historyTitle}>Previous actions</div>
            <div>
              {reviewHistory.map((item) => {
                return (
                  <React.Fragment key={item.UserReviewId}>
                    <Grid container spacing={3}>
                      <Grid item xs={9}>
                        <IKeyValuePair
                          key={item.UserReviewId}
                          data={{
                            key: item.SuggestiveAction,
                            value: FormatUtils.FormatToPrettyDate(item.ReviewDate),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        {renderCompleteAction(item)}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                );
              })}
            </div>
          </React.Fragment>
        )}

        {remarksHistory?.length > 0 && (
          <React.Fragment>
            <div className={classes.historyTitle}>Previous remarks</div>
            <div>
              {remarksHistory.map((item) => {
                return (
                  <IKeyValuePair
                    key={item.UserReviewRemarksId}
                    data={{
                      key: item.Remarks,
                      value: FormatUtils.FormatToPrettyDate(item.ModifiedDate),
                    }}
                  />
                );
              })}
            </div>
          </React.Fragment>
        )}
        {closureHistory?.length > 0 && (
          <React.Fragment>
            <div className={classes.historyTitle}>Previous Closures</div>
            <div>
              {closureHistory.map((item) => {
                return (
                  <IKeyValuePair
                    key={item.UserReviewClosureId}
                    data={{
                      key: item.Remarks,
                      value: FormatUtils.FormatToPrettyDate(item.ModifiedDate),
                    }}
                  />
                );
              })}
            </div>
          </React.Fragment>
        )}
        <ITextField
          label={"Remarks(Optional)"}
          aria-label="Remarks"
          id={`remarks-${data?.TriggerId}`}
          onChange={handleRemarksChange}
          value={remarks}
        ></ITextField>
        { /*canCloseAlert && (
          <div className={classes.actionsContainer}>
            <Tooltip title="Click to close alert" aria-label="mark-closed">
              <DoneAll className={classes.completeAction} onClick={handleCloseAlert}></DoneAll>
            </Tooltip>
          </div>
        )}

        {canReopen && (
          <div className={classes.actionsContainer}>
            <Tooltip title="Click to reassign alert" aria-label="reassign-alert">
              <RedoIcon className={classes.completeAction} onClick={handleReopen}></RedoIcon>
            </Tooltip>
          </div>
        ) */}
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(ReviewCard);
