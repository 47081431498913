import React from "react";

const Home = (props) => {
  return (
    <header className="App-header">
      <h1>Welcome to CREDEWS!</h1>
      <div>This place is being developed. Please check back later!</div>
    </header>
  );
};

export default Home;
