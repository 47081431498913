import HttpUtils from "./httpUtils";
import config from "./../config/config";

const saveBookmark = async (userId, bookmarkId, bookmarkName, filters, filterItems, filterValues) => {
  const data = await HttpUtils.Post(`${config.apiUrl}/search/savebookmark`, {
    BookmarkId: bookmarkId,
    BookmarkName: bookmarkName,
    UserId: userId,
    Filters: JSON.stringify(filters),
    FilterItems: JSON.stringify(filterItems),
    FilterValue: JSON.stringify(filterValues),
  });
  return data?.data;
};

const getUserBookmarks = async (userId) => {
  const data = await HttpUtils.Get(`${config.apiUrl}/search/userbookmarks/${userId}`);
  return data?.data;
};

const getBookmark = async (bookmarkId) => {
  const data = await HttpUtils.Get(`${config.apiUrl}/search/bookmark/${bookmarkId}`);
  return data?.data;
};

const removeBookmark = async (bookmarkId) => {
  const data = await HttpUtils.Get(`${config.apiUrl}/search/removebookmark/${bookmarkId}`);
  return data?.data;
};

const BookmarkUtils = {
  SaveBookmark: saveBookmark,
  GetBookmark: getBookmark,
  GetUserBookmarks: getUserBookmarks,
  RemoveBookmark: removeBookmark,
};

export default BookmarkUtils;
