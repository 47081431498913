import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Card, CardContent, CardHeader, Grid, withStyles } from "@material-ui/core";
import HelperUtils from "./../../utils/helperUtils";
import IKeyValuePair from "../common/IKeyValuePair";
import FormatUtils from "../../utils/formatUtils";
import ISelect from "../common/ISelect";
import AdminUtils from "../../utils/adminUtils";
import SubmitIcon from "@material-ui/icons/FileCopySharp";
import ITextField from "../common/ITextField";
import ReviewUtils from "../../utils/reviewUtils";

let styles = (theme) => ({
  root: {},
  tableHeading: {
    fontSize: theme.spacing(3),
    float: `left`,
  },
  reassign: {
    marginBottom: theme.spacing(1.5),
  },
  cardHeader: {
    textAlign: "left",
    borderBottom: `1px solid #ccc`,
    background: "#f7f7f7",
    "& span": {
      fontWeight: "bold",
      fontSize: theme.spacing(2),
    },
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    textAlign: "right",
    marginBottom: theme.spacing(1.5),
  },
});

const AlertRework = (props) => {
  const { classes, onSubmit, accountLevel } = props;

  const [role, setRole] = useState(null);
  const [users, setUsers] = useState(null);
  const [userId, setUserId] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [defaultUser, setDefaultUser] = useState(null);

  const getAlertClosureItem = () => {
    const userReviewClosureId = props?.selectedAlertRework?.[0];

    if (!userReviewClosureId) {
      return null;
    }

    const item = props.alertClosureHistory?.find((i) => i.UserReviewClosureId === userReviewClosureId);
    if (!item) {
      return null;
    }

    const alertDetails = {
      TriggerName: item.Triggers?.TriggerName || item.TriggerName,
      TriggerDate: FormatUtils.FormatToPrettyDate(item.TriggerDate),
      ClosedBy: item.ModifiedBy,
      ModifiedDate: FormatUtils.FormatToPrettyDate(item.ModifiedDate),
      Remarks: item.Remarks,
    };
    return alertDetails;
  };

  const getRolesOptions = () => {
    const allowedRoles = ["BranchManager", "RelationshipManager", "Controller"];
    const roles = props.organizationRoles?.filter((i) => allowedRoles.indexOf(i.Name) !== -1);
    return HelperUtils.MapOptions(roles, "Name", "OrganizationRoleId");
  };

  const handleRoleChange = (event, option) => {
    const organizationRoleId = option.value;
    AdminUtils.FetchUsersBelongingRole(organizationRoleId).then((data) => {
      setUsers(data);
      setRole(organizationRoleId);
      const defaultUser = getDefaultValue(organizationRoleId);
      setDefaultUser(defaultUser);
      setUserId(defaultUser.value);
    });
  };

  const getUserOptions = () => {
    return HelperUtils.MapOptions(users || [], "Username", "UserId");
  };

  const handleUserChange = (event, option) => {
    const userId = option.value;
    setUserId(userId);
  };

  const handleRemarksChange = (e) => {
    setRemarks(e.currentTarget.value);
  };

  const handleReassign = () => {
    const timestamp = new Date();
    const currentUser = props.user?.User?.EmailAddress || props.user?.EmailAddress;

    const payload = {
      UserReviewClosureId: accountLevel ? "" : props.selectedAlertRework[0],
      UserId: userId,
      Remarks: remarks,
      CreatedBy: currentUser,
      CreatedDate: timestamp,
      ModifiedBy: currentUser,
      ModifiedDate: timestamp,
    };

    onSubmit(payload);
  };

  const getDefaultValue = (roleId) => {
    const user = props.accountControllers?.find((i) => i.UserRole?.OrganizationRoleId === roleId)?.UserRole?.User;
    if (!user) {
      return null;
    }
    const userOption = {
      name: user.Username,
      label: user.Username,
      value: user.UserId,
    };
    return userOption;
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.card}>
          {!accountLevel && (
            <Card>
              <CardHeader title="Alert Details"></CardHeader>
              <CardContent>
                {getAlertClosureItem() &&
                  Object.keys(getAlertClosureItem())?.map((i) => {
                    return (
                      <IKeyValuePair
                        key={i}
                        data={{
                          key: i,
                          value: getAlertClosureItem()[i],
                        }}
                      ></IKeyValuePair>
                    );
                  })}
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={12} className={classes.card}>
          <Card>
            <CardHeader title="Re-assign"></CardHeader>
            <CardContent>
              <div className={classes.reassign}>
                <ISelect options={getRolesOptions() || []} multiple={false} label={"Select Role"} onChange={handleRoleChange}></ISelect>
              </div>
              <div className={classes.reassign}>
                {role && (
                  <ISelect
                    options={getUserOptions() || []}
                    multiple={false}
                    label={"Select User"}
                    value={defaultUser}
                    onChange={handleUserChange}
                  ></ISelect>
                )}
              </div>
              <div className={classes.reassign}>
                <ITextField label={"Remarks"} id={`remarks`} onChange={handleRemarksChange} value={remarks}></ITextField>
              </div>
              <div className={classes.button}>
                <Button variant="contained" color="primary" className={classes.button} onClick={handleReassign} startIcon={<SubmitIcon />}>
                  Reassign
                </Button>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    alertClosureHistory: state.reviewReducer.alertClosureHistory,
    selectedAlertRework: state.reviewReducer.selectedAlertRework,
    organizationRoles: state.adminReducer.organizationRoles,
    user: state.authReducer.user,
    accountControllers: state.accountReducer.accountControllers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AlertRework));
