import React from "react";
import { withStyles, Card, CardContent, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import DashboardUtils from "./../../utils/dashboardUtils";
import DataUtils from "./../../utils/dataUtils";
import { useState } from "react";
import { setNPALoans } from "../../actions/dashboardActions";
import IDialog from "./../common/IDialog";
import NPATable from "./NPATable";

let styles = (theme) => ({
  header: {},
  body: {
    textAlign: `right`,
    fontSize: theme.spacing(1.5),
    padding: theme.spacing(1.5),
  },
  value: {
    textAlign: `right`,
    fontSize: theme.spacing(2),
    fontWeight: theme.spacing(80),
    cursor: "pointer",
  },
  icon: {
    float: `left`,
    padding: theme.spacing(3),
    marginTop: theme.spacing(-2.5),
    marginRight: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
  },
  difference: {
    fontWeight: theme.spacing(80),
  },
  title: {
    fontSize: theme.spacing(1.3),
  },
});

const items = [
  {
    name: "FinancialNPA",
    title: "Financial NPA",
    bgClass: "bg-secondary",
    percentageField: false,
    type: "integer",
  },
  {
    name: "NonFinancialNPA",
    title: "Non-Financial NPA",
    bgClass: "bg-secondary",
    percentageField: false,
    type: "integer",
  },
  {
    name: "PotentialNPA",
    title: "Potential NPA",
    bgClass: "bg-secondary",
    percentageField: false,
    type: "integer",
  },
  {
    name: "MovingNPA",
    title: "Moving NPA",
    bgClass: "bg-secondary",
    percentageField: false,
    type: "integer",
  },
];

const NPACard = (props) => {
  const { classes, data, bgClass, icon } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");

  const getItemValue = (name) => {
    const value = data?.find((el) => el.MeasureName === name)?.Value;
    return value;
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleNPAClick = (type) => {
    if (!getItemValue(type)) {
      return;
    }

    const selectedNPATitle = items?.find((el) => el.name === type)?.title;
    setSelectedType(selectedNPATitle);

    DashboardUtils.FetchNPALoans(props.currentDate || DataUtils.GetDefaultCurrentDate(), props.selectedFilterItems, type).then((data) => {
      props.setNPALoans(data);
      setDialogOpen(true);
    });
  };

  return (
    <React.Fragment>
      <Card>
        <CardContent className={classes.body}>
          <Grid container>
            <Grid item xs={3}>
              <div className={`${classes.icon} ${bgClass}`}>{icon}</div>
            </Grid>
            <Grid item xs={9}>
              {items.map((item) => {
                return (
                  <React.Fragment key={item.name}>
                    <div className={classes.value} onClick={() => handleNPAClick(item.name)}>
                      {getItemValue(item.name) || "-"}
                    </div>
                    <div className={classes.title}>{item.title}</div>
                  </React.Fragment>
                );
              })}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <IDialog
        title={<div>{selectedType}</div>}
        open={dialogOpen}
        content={<NPATable onClose={handleDialogClose} selectedType={selectedType} />}
        onClose={handleDialogClose}
      ></IDialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    currentDate: state.dashboardReducer.currentDate,
    selectedFilterItems: state.dashboardReducer.selectedFilterItems,
    npaLoans: state.dashboardReducer.npaLoans,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNPALoans: (payload) => dispatch(setNPALoans(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NPACard));
