import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button, withStyles } from "@material-ui/core";

import DashboardUtils from "./../../utils/dashboardUtils";
import MUIDataTable from "mui-datatables";
import Spinner from "./../common/Spinner";
import UIUtils from "./../../utils/uiUtils";
import FormatUtils from "./../../utils/formatUtils";
import { setSelectedAccount } from "../../actions/accountActions";
import { withRouter } from "react-router-dom";
import { setTriggeredLoans } from "../../actions/triggerActions";

let styles = (theme) => ({
  fixedPopover: {},
  container: {
    padding: theme.spacing(2),
  },
  alignCenter: {
    textAlign: `center`,
  },
  tableHeading: {
    fontSize: theme.spacing(3),
    float: `left`,
  },
  actionItemsRight: {
    "& .MUIDataTableToolbar-actions-36": {
      textAlign: `right`,
    },
  },
  borrowerName: {
    fontWeight: theme.spacing(80),
    textDecorationColor: `gray`,
    cursor: `pointer`,
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
});

const TriggeredLoans = (props) => {
  const { classes, selectedColor, triggeredLoans, selectedTrigger, currentDate } = props;

  const fetchTriggeredLoans = () => {
    props.setTriggeredLoans([]);
    DashboardUtils.FetchTriggeredLoans(
      props.selectedTriggerType?.key,
      currentDate || new Date(),
      selectedTrigger?.id,
      selectedColor,
      props.selectedFilterItems
    ).then((data) => {
      props.setTriggeredLoans(data);
    });
  };

  const onLoad = () => {
    fetchTriggeredLoans();
  };

  useEffect(onLoad, []);

  const options = {
    selectableRows: "none",
    responsive: "vertical",
    search: true,
    viewColumns: true,
    rowsPerPage: 5,
    rowsPerPageOptions: [10, 15, 100, 1000, 10000],
    onRowClick: (rowData) => {
      const id = rowData[2];
      const name = rowData[3];
      const account = {
        id,
        name,
      };
      props.setSelectedAccount(account);
      props.history.push(`account/${id}`);
    },
    downloadOptions: {
      filename: FormatUtils.GetCSVFileName("Triggered-Loans"),
      separator: ",",
    },
  };

  const columns = [
    {
      name: "TriggerId",
      label: "TriggerId",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "LoanId",
      label: "LoanId",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "BorrowerId",
      label: "BorrowerId",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "BorrowerName",
      label: "Borrower Name",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return <Button className={classes.borrowerName}>{value}</Button>;
        },
      },
    },
    {
      name: "Circle",
      label: "Circle",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Segment",
      label: "Segment",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "AmountDisbursed",
      label: "Amount Disbursed",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return FormatUtils.FormatNumberToCurrency(value);
        },
      },
    },
  ];

  return (
    <div className={classes.container}>
      {triggeredLoans.length === 0 && selectedColor && (
        <div className={classes.alignCenter}>
          <Spinner color={UIUtils.GetSelectedColor(selectedColor)}></Spinner>
        </div>
      )}
      {triggeredLoans.length > 0 && (
        <div className={classes.fixedPopover}>
          <MUIDataTable
            columns={columns}
            title={
              <span className={classes.tableHeading} style={{ color: UIUtils.GetSelectedColor(selectedColor) }}>
                {selectedTrigger.name}
              </span>
            }
            data={triggeredLoans}
            options={options}
            className={classes.actionItemsRight}
          ></MUIDataTable>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedTrigger: state.triggerReducer.selectedTrigger,
    selectedTriggerType: state.triggerReducer.selectedTriggerType,
    currentDate: state.dashboardReducer.currentDate,
    selectedColor: state.triggerReducer.selectedTriggerColor,
    triggeredLoans: state.triggerReducer.triggeredLoans,
    selectedFilterItems: state.dashboardReducer.selectedFilterItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTriggeredLoans: (payload) => dispatch(setTriggeredLoans(payload)),
    setSelectedAccount: (payload) => dispatch(setSelectedAccount(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(TriggeredLoans)));
