export const SET_USER = "SET_USER";
export const setUser = (payload) => ({
  type: SET_USER,
  payload: payload,
});

export const SET_ROLES = "SET_ROLES";
export const setRoles = (payload) => ({
  type: SET_ROLES,
  payload: payload,
});

export const SET_CAN_ADD_REVIEWS = "SET_CAN_ADD_REVIEWS";
export const setCanAddReviews = (payload) => ({
  type: SET_CAN_ADD_REVIEWS,
  payload: payload,
});
