import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { clearSelectedTriggers, setSelectedTriggerType } from "../../actions/triggerActions";
import DataUtils from "../../utils/dataUtils";

import AccountBalanceRoundedIcon from "@material-ui/icons/AccountBalanceRounded";
import BusinessRoundedIcon from "@material-ui/icons/BusinessRounded";
import AcUnitIcon from "@material-ui/icons/AcUnit";

let styles = (theme) => ({
  root: {},
  toggleButton: {
    fontWeight: theme.spacing(70),
    border: "none",
    textTransform: "none",
    color: `rgba(0, 0, 0, 0.54)`,
    backgroundColor: `rgba(0, 0, 0, 0.12)`,
  },
  toggleButtonGroup: {
    background: `#FFFFFF`,
    padding: theme.spacing(1),
  },
  activeToggleButton: {
    color: `#FFFFFF !important`,
    backgroundColor: `#3293a2 !important`,
  },
});

const triggerTypes = DataUtils.GetAllTriggerTypes();

const TriggersTab = (props) => {
  const { classes, selectedTriggerType } = props;
  const handleTriggerTypeChange = (event, newValue) => {
    props.setSelectedTriggerType(DataUtils.GetTrigger(newValue));
    props.clearSelectedTriggers();
  };

  const getIcon = (triggerTypeId) => {
    if (triggerTypeId === 1) return <AccountBalanceRoundedIcon />;
    if (triggerTypeId === 2) return <BusinessRoundedIcon />;
    if (triggerTypeId === 3) return <AcUnitIcon />;
  };

  const mapIconsToTriggers = () => {
    return triggerTypes.map((item) => {
      return {
        ...item,
        icon: getIcon(item?.id),
      };
    });
  };

  return (
    <ToggleButtonGroup
      value={selectedTriggerType}
      exclusive
      onChange={handleTriggerTypeChange}
      aria-label="trigger type"
      className={`shadow-sm ${classes.toggleButtonGroup}`}
    >
      {mapIconsToTriggers()?.map((item) => {
        return (
          <ToggleButton
            key={item.id}
            value={item.id}
            aria-label="left aligned"
            className={`${classes.toggleButton} ${selectedTriggerType?.id === item.id ? classes.activeToggleButton : ""}`}
            alt={item.name}
          >
            <div>
              <span className={classes.menuIcon}>{item.icon}</span>
              {item.name}
            </div>
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedTriggerType: state.triggerReducer.selectedTriggerType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedTriggerType: (payload) => dispatch(setSelectedTriggerType(payload)),
    clearSelectedTriggers: (payload) => dispatch(clearSelectedTriggers()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TriggersTab));
