import { Grid, withStyles } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { setOverallComments } from "../../actions/reviewActions";
import FormatUtils from "../../utils/formatUtils";
import IKeyValuePair from "../common/IKeyValuePair";
import ITextField from "../common/ITextField";

let styles = (theme) => ({
  root: {},
  historyTitle: {
    fontSize: theme.spacing(1.5),
    fontWeight: "bolder",
    textAlign: "left",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: "#bbb",
  },
  alignLeft: {
    textAlign: "left",
  },
  alignRight: { textAlign: "right" },
});

const ReviewSummary = (props) => {
  const { classes, overallCommentsHistory, hidden } = props;
  const handleChange = (e) => {
    props.setOverallComments(e.currentTarget.value);
  };

  return (
    <React.Fragment>
      {!hidden && <ITextField label={"Overall Comments"} onChange={handleChange}></ITextField>}
      {overallCommentsHistory?.length > 0 && (
        <React.Fragment>
          <div className={classes.historyTitle}>Previous actions</div>
          <div>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                Trigger Date
              </Grid>
              <Grid item xs={3} className={classes.alignLeft}>
                Comment By
              </Grid>
              <Grid item xs={3}>
                Commented On
              </Grid>
              <Grid item xs={3} className={classes.alignLeft}>
                Comments
              </Grid>
              {overallCommentsHistory.map((item) => {
                return (
                  <React.Fragment key={item.Comments}>
                    <Grid item xs={6}>
                      <IKeyValuePair
                        key={item.TriggerDate}
                        data={{
                          value: item.CreatedBy,
                          key: `${FormatUtils.FormatToPrettyDate(item.TriggerDate)}`,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <IKeyValuePair
                        key={item.Comments}
                        data={{
                          value: item.Comments,
                          key: `${FormatUtils.FormatToPrettyDate(item.ModifiedDate)}`,
                        }}
                      />
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    overallCommentsHistory: state.reviewReducer.overallCommentsHistory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOverallComments: (payload) => dispatch(setOverallComments(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReviewSummary));
