import React, { useEffect } from "react";
import { setAllUserRoles, setOrganizationRoles, setRelationshipManagers } from "../actions/adminActions";
import AdminUtils from "../utils/adminUtils";
import Accounts from "./../components/admin/Accounts";
import Roles from "./../components/admin/Roles";
import Users from "./../components/admin/Users";
import ITab from "./../components/common/ITab";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";

let styles = (theme) => ({
  root: {},
});

const Admin = (props) => {
  const onLoad = () => {
    AdminUtils.FetchOrganizationRoles().then((data) => {
      props.setOrganizationRoles(data);
    });
    AdminUtils.FetchAllUserRoles().then((data) => {
      props.setAllUserRoles(data);
    });
  };

  useEffect(onLoad, []);

  const tabs = [
    {
      index: 0,
      label: "Accounts",
      content: <Accounts />,
    },
    {
      index: 1,
      label: "Roles",
      content: <Roles />,
    },
    {
      index: 2,
      label: "Users",
      content: <Users />,
    },
  ];
  return <ITab tabs={tabs} />;
};

const mapStateToProps = (state) => {
  return {
    organizationRoles: state.adminReducer.organizationRoles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOrganizationRoles: (payload) => dispatch(setOrganizationRoles(payload)),
    setAllUserRoles: (payload) => dispatch(setAllUserRoles(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Admin));
