import { SET_SNACKBAR } from "../actions/commonActions";
import { SET_LOADER } from "./../actions/commonActions";

const initialState = {
  snackBar: "",
  showLoader: false,
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SNACKBAR:
      return {
        ...state,
        snackBar: action.payload,
      };
    case SET_LOADER:
      return {
        ...state,
        showLoader: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
