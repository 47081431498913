import React from "react";
import { withStyles, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Profile from "../components/account/Profile";
import AccountStructuredTriggers from "../components/account/AccountStructuredTriggers";
import { useEffect } from "react";
import AccountUtils from "../utils/accountUtils";
import { setAccountData } from "../actions/accountActions";
import DashboardUtils from "./../utils/dashboardUtils";
import { setStructuredTriggers, setUnstructuredTriggers, setLLMSTriggers } from "../actions/dashboardActions";
import TriggersTab from "../components/common/TriggersTab";
import AccountUnstructuredTriggers from "../components/account/AccountUnstructuredTriggers";
import AccountLLMSTriggers from "../components/account/AccountLLMSTriggers";
import RiskUtils from "../utils/riskUtils";
import { setRiskScores } from "../actions/riskActions";
import RiskScores from "../components/account/RiskScores";

let styles = (theme) => ({
  root: {},
  alignLeft: {
    textAlign: "left",
  },
});

const Account = (props) => {
  const { classes } = props;
  const fetchAccountData = (id) => {
    AccountUtils.FetchAccountData(id).then((data) => {
      if (!data) return;
      props.setAccountData(data);
      fetchTriggers(data?.AccountNumber);
      fetchRiskScores(data?.AccountNumber, new Date());
    });
  };

  const fetchTriggers = (accountNumber) => {
    DashboardUtils.FetchTriggers(props.selectedTriggerType?.key, new Date(), {
      Account: [accountNumber],
    }).then((data) => {
      if (props.selectedTriggerType?.id === 1) props.setStructuredTriggers(data);
      if (props.selectedTriggerType?.id === 2) props.setUnstructuredTriggers(data);
      if (props.selectedTriggerType?.id === 3) props.setLLMSTriggers(data);
    });
  };

  const fetchRiskScores = (accountNumber, currentDate) => {
    RiskUtils.FetchRiskScores(accountNumber, currentDate).then((data) => {
      props.setRiskScores(data);
    });
  };

  const onLoad = () => {
    const id = props.match?.params?.id;
    if (!id) return;
    props.setStructuredTriggers([]);
    props.setUnstructuredTriggers([]);
    props.setLLMSTriggers([]);
    fetchAccountData(id);
  };

  useEffect(onLoad, [props.selectedTriggerType?.id]);

  return (
    <Grid container spacing={3}>
      <Grid item xl={2} lg={4} md={5} sm={12} xs={12}>
        <Profile></Profile>
        <RiskScores></RiskScores>
      </Grid>
      <Grid item xl={10} lg={8} md={7} sm={12} xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.alignLeft}>
            <TriggersTab />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.alignLeft}>
            {props.selectedTriggerType?.id === 1 && <AccountStructuredTriggers />}
            {props.selectedTriggerType?.id === 2 && <AccountUnstructuredTriggers />}
            {props.selectedTriggerType?.id === 3 && <AccountLLMSTriggers />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedAccount: state.accountReducer.selectedAccount,
    structuredTriggers: state.dashboardReducer.structuredTriggers,
    selectedTriggerType: state.triggerReducer.selectedTriggerType,
    riskScores: state.riskReducer.riskScores,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAccountData: (payload) => dispatch(setAccountData(payload)),
    setStructuredTriggers: (payload) => dispatch(setStructuredTriggers(payload)),
    setUnstructuredTriggers: (payload) => dispatch(setUnstructuredTriggers(payload)),
    setLLMSTriggers: (payload) => dispatch(setLLMSTriggers(payload)),
    setRiskScores: (payload) => dispatch(setRiskScores(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(Account)));
