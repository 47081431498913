import React from "react";
import { withStyles, Grid, Tooltip } from "@material-ui/core";

let styles = (theme) => ({
  root: {
    fontSize: theme.spacing(1.5),
  },
  keySpan: {
    fontWeight: "bold",
    overflow: `hidden`,
    textAlign: "start",
  },
  valueSpan: {
    textAlign: "left",
  },
});

const IKeyValuePair = (props) => {
  const { classes, data } = props;
  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={6}>
        <Tooltip title={`${data?.key}`} aria-label={`${data?.key}`}>
          <div className={classes.keySpan}>{data?.key}</div>
        </Tooltip>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.valueSpan}>{data?.value}</div>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(IKeyValuePair);
