import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import FormatUtils from "./../../utils/formatUtils";
import { connect } from "react-redux";
import { withStyles, Box, Typography, Button, Toolbar, Paper, Grid, TextField, FormHelperText } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import MISReportsUtils from "../../utils/misReportsUtils";
import IDatePicker from "../common/IDatePicker";
import HelperUtils from "../../utils/helperUtils";
import { saveAs } from "file-saver";
import { ClearAll } from "@material-ui/icons";
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { Autocomplete } from "@material-ui/lab";
import DataUtils from "../../utils/dataUtils";

let styles = (theme) => ({
  table: {
    "& .MuiTableCell-root": {
      textAlign: "center",
    },
  },
  tableHeading: {
    fontSize: theme.spacing(3),
    float: `left`,
  },
  borrowerName: {
    fontWeight: theme.spacing(80),
    textDecorationColor: `gray`,
    cursor: `pointer`,
  },
  triggerDate: {
    fontWeight: theme.spacing(80),
    textDecorationColor: `gray`,
    cursor: `pointer`,
  },
  alertStatusReportContainer: {
    backgroundColor: 'white',
  },
  formLabel: {
    display: "flex",
    textAlign: "center"
  },
  misReportFilterToolbar: {
    '& .MuiTypography-body1': {
      fontWeight: 400,
      fontSize: "24px",
      float: "left",
      color: "black"
    }
  },
  errorText: {
    '& p': {
      color: "red"
    }
  },
});

const RFAStatusReport = (props) => {
  const { classes } = props;
  const [rfaStatusSummary, setRFAStatusSummary] = useState([]);
  const [filterDropdownsInited, setFilterDropdownsInited] = useState(false);
  const [triggerFromDate, setTriggerFromDate] = useState(DataUtils.GetDefaultCompareDate());
  const [triggerToDate, setTriggerToDate] = useState(new Date());
  const [triggerFromDateError, setTriggerFromDateError] = useState(false);
  const [triggerToDateError, setTriggerToDateError] = useState(false);
  const [rfaStatusSummaryColumns, setRFAStatusSummaryColumns] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    triggerFromDate: DataUtils.GetDefaultCompareDate(),
    triggerToDate: new Date()
  });

  const [selectedFilterValues, setSelectedFilterValues] = useState({
    "BusinessUnit": [],
    "Circle": [],
    "Network": [],
    "Module": [],
    "Region": [],
    "Branch": [],
    "alertCreatedFromDate": dateFilter.triggerFromDate,
    "alertCreatedToDate": dateFilter.triggerToDate
  });

  const [filterDropdowns, setFilterDropdowns] = useState({
    "BusinessUnit": [],
    "Circle": [],
    "Network": [],
    "Module": [],
    "Region": [],
    "Branch": [],
  });

  const [filterData, setFilterData] = useState([]);

  const onLoad = () => {
  };

  const onDateChange = () => {
    MISReportsUtils.FetchFilterValues({
      "FilterRequest": "AlertStatusReportFilters",
      "alertCreatedFromDate": FormatUtils.FormatToISODateString(dateFilter.triggerFromDate),
      "alertCreatedToDate": FormatUtils.FormatToISODateString(dateFilter.triggerToDate)
    }).then((data) => {
      setFilterData(data);
      setFilterDropdowns(
        {
          "Branch": Array.from(new Set(data.map(a => a.Branch))),
          "BusinessUnit": Array.from(new Set(data.map(a => a.BusinessUnit))),
          "Circle": Array.from(new Set(data.map(a => a.Circle))),
          "Network": Array.from(new Set(data.map(a => a.Network))),
          "Module": Array.from(new Set(data.map(a => a.Module))),
          "Region": Array.from(new Set(data.map(a => a.Region))),
        });
    });
    setFilterDropdownsInited(true);
  };

  useEffect(onLoad, []);
  useEffect(onDateChange, [dateFilter]);

  const options = {
    selectableRows: "none",
    responsive: "vertical",
    search: true,
    viewColumns: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 15, 100, 1000, 10000],
    onRowClick: (rowData) => { },
    downloadOptions: {
      filename: FormatUtils.GetCSVFileName(`RFAStatusSummary`),
      separator: ",",
    },
    draggableColumns: true
  };

  const getRFAStatusSummary = () => {
    MISReportsUtils.FetchRFAStatusSummary({
      ...selectedFilterValues,
      "alertCreatedFromDate": FormatUtils.FormatToISODateString(dateFilter.triggerFromDate),
      "alertCreatedToDate": FormatUtils.FormatToISODateString(dateFilter.triggerToDate)
    }).then((data) => {
      setRFAStatusSummary(data);
    });
  };

  const updateRFAStatusSummaryColumns = () => {
    if (rfaStatusSummary[0] !== undefined) {
      let columns = [];
      Object.keys(rfaStatusSummary[0]).map((item) => {
        columns.push({
          name: item,
          label: item,
          options: {
            filter: false,
            display: true,
            sort: false,
          },
        });
      });
      setRFAStatusSummaryColumns(columns);
    }
  };

  useEffect(updateRFAStatusSummaryColumns, [rfaStatusSummary]);

  const handleDateChange = (date, name) => {
    if (!HelperUtils.IsValidDate(date) || date > new Date()) {
      if (name === "triggerFromDate") {
        setTriggerFromDateError(true);
        return
      }
      else if (name === "triggerToDate") {
        setTriggerToDateError(true);
        return
      }
    }
    if (name === "triggerFromDate") {
      if (date > triggerToDate) {
        setTriggerFromDateError(true);
        return;
      }
      if (date < triggerToDate) {
        setTriggerToDateError(false);
      }
      setTriggerFromDateError(false);
      setDateFilter({ ...dateFilter, triggerFromDate: date });
    }
    if (name === "triggerToDate") {
      if (date < triggerFromDate) {
        setTriggerToDateError(true);
        return;
      }
      if (date > triggerFromDate) {
        setTriggerFromDateError(false);
      }
      setTriggerToDateError(false);
      setDateFilter({ ...dateFilter, triggerToDate: date });
    }
  };

  const resetFilters = () => {
    setSelectedFilterValues({
      ...selectedFilterValues,
      "BusinessUnit": [],
      "Circle": [],
      "Network": [],
      "Module": [],
      "Region": [],
      "Branch": [],
      "alertCreatedFromDate": dateFilter.triggerFromDate,
      "alertCreatedToDate": dateFilter.triggerToDate
    });
    setFilterDropdowns(
      {
        ...filterDropdowns,
        "Circle": Array.from(new Set(filterData.map(a => a.Circle))),
        "Network": Array.from(new Set(filterData.map(a => a.Network))),
        "Module": Array.from(new Set(filterData.map(a => a.Module))),
        "Region": Array.from(new Set(filterData.map(a => a.Region))),
        "Branch": Array.from(new Set(filterData.map(a => a.Branch))),
      });
  }

  const submitForm = () => {
    var xlsxFileName = FormatUtils.GetDownloadFileName("RFAStatusReport", "xlsx");

    var responseJson = {
      "BusinessUnit": selectedFilterValues.BusinessUnit,
      "Circle": selectedFilterValues.Circle,
      "Network": selectedFilterValues.Network,
      "Module": selectedFilterValues.Module,
      "Region": selectedFilterValues.Region,
      "Branch": selectedFilterValues.Branch,
      "OtherFilters": {
        "TriggerNames": selectedFilterValues.TriggerName
      },
      "alertCreatedFromDate": FormatUtils.FormatToISODateString(dateFilter.triggerFromDate),
      "alertCreatedToDate": FormatUtils.FormatToISODateString(dateFilter.triggerToDate),
    };
    MISReportsUtils.FetchXLSXReport("rfaStatusReport", responseJson, { responseType: 'arraybuffer' }
    ).then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.ms-excel"
      })
      saveAs(blob, xlsxFileName)
    });
  }

  const rectifySelectedValues = () => {
    setSelectedFilterValues({
      "BusinessUnit": selectedFilterValues.BusinessUnit?.filter((i) => filterDropdowns.BusinessUnit.some((j) => i === j)) ?? filterData.BusinessUnit,
      "Circle": selectedFilterValues.Circle?.filter((i) => filterDropdowns.Circle.some((j) => i === j)) ?? filterData.Circle,
      "Network": selectedFilterValues.Network?.filter((i) => filterDropdowns.Network.some((j) => i === j)) ?? filterData.Network,
      "Module": selectedFilterValues.Module?.filter((i) => filterDropdowns.Module.some((j) => i === j)) ?? filterData.Module,
      "Region": selectedFilterValues.Region?.filter((i) => filterDropdowns.Region.some((j) => i === j)) ?? filterData.Region,
      "Branch": selectedFilterValues.Branch?.filter((i) => filterDropdowns.Branch.some((j) => i === j)) ?? filterData.Branch,
    });
    if (filterDropdownsInited) getRFAStatusSummary();
  }

  useEffect(rectifySelectedValues, [filterDropdowns]);

  const handleBusinessUnitChange = (event, newValue) => {
    var selectedFilters = filterData.filter((i) => newValue.some((j) => i.BusinessUnit === j)) ?? [];
    if (newValue.length === 0) {
      selectedFilters = filterData;
      setSelectedFilterValues({
        ...selectedFilterValues,
        "BusinessUnit": [],
        "Circle": [],
        "Network": [],
        "Region": [],
        "Module": [],
        "Branch": []
      });
    }
    else {
      setSelectedFilterValues({
        ...selectedFilterValues,
        "BusinessUnit": newValue,
      });
    }
    setFilterDropdowns({
      ...filterDropdowns,
      "Circle": Array.from(new Set(selectedFilters.map(a => a.Circle))),
      "Network": Array.from(new Set(selectedFilters.map(a => a.Network))),
      "Module": Array.from(new Set(selectedFilters.map(a => a.Module))),
      "Region": Array.from(new Set(selectedFilters.map(a => a.Region))),
      "Branch": Array.from(new Set(selectedFilters.map(a => a.Branch))),
    });
  }

  const handleCircleChange = (event, newValue) => {
    var selectedFilters = filterData.filter((i) => newValue.some((j) => i.Circle === j)) ?? [];
    if (newValue.length === 0) {
      selectedFilters = filterData;
      setSelectedFilterValues({
        ...selectedFilterValues,
        "Circle": [],
        "Network": [],
        "Region": [],
        "Module": [],
        "Branch": []
      });
    }
    else {
      setSelectedFilterValues({
        ...selectedFilterValues,
        "Circle": newValue,
      });
    }
    setFilterDropdowns({
      ...filterDropdowns,
      "Network": Array.from(new Set(selectedFilters.map(a => a.Network))),
      "Module": Array.from(new Set(selectedFilters.map(a => a.Module))),
      "Region": Array.from(new Set(selectedFilters.map(a => a.Region))),
      "Branch": Array.from(new Set(selectedFilters.map(a => a.Branch))),
    });
  }

  const handleNetworkChange = (event, newValue) => {
    var selectedFilters = filterData.filter((i) => newValue.some((j) => i.Network === j)) ?? [];
    if (newValue.length === 0) {
      selectedFilters = filterData;
      setSelectedFilterValues({
        ...selectedFilterValues,
        "Network": [],
        "Region": [],
        "Module": [],
        "Branch": []
      });
    }
    else {
      setSelectedFilterValues({
        ...selectedFilterValues,
        "Network": newValue,
      });
    }
    setFilterDropdowns({
      ...filterDropdowns,
      "Module": Array.from(new Set(selectedFilters.map(a => a.Module))),
      "Region": Array.from(new Set(selectedFilters.map(a => a.Region))),
      "Branch": Array.from(new Set(selectedFilters.map(a => a.Branch))),
    });
  }

  const handleModuleChange = (event, newValue) => {
    var selectedFilters = filterData.filter((i) => newValue.some((j) => i.Module === j)) ?? [];
    if (newValue.length === 0) {
      selectedFilters = filterData;
      setSelectedFilterValues({
        ...selectedFilterValues,
        "Module": [],
        "Region": [],
        "Branch": []
      });
    }
    else {
      setSelectedFilterValues({
        ...selectedFilterValues,
        "Module": newValue,
      });
    }
    setFilterDropdowns({
      ...filterDropdowns,
      "Region": Array.from(new Set(selectedFilters.map(a => a.Region))),
      "Branch": Array.from(new Set(selectedFilters.map(a => a.Branch))),
    });
  }

  const handleRegionChange = (event, newValue) => {
    var selectedFilters = filterData.filter((i) => newValue.some((j) => i.Region === j)) ?? [];
    if (newValue.length === 0) {
      selectedFilters = filterData;
      setSelectedFilterValues({
        ...selectedFilterValues,
        "Region": [],
        "Branch": []
      });
    }
    else {
      setSelectedFilterValues({
        ...selectedFilterValues,
        "Region": newValue,
      });
    }
    setFilterDropdowns({
      ...filterDropdowns,
      "Branch": Array.from(new Set(selectedFilters.map(a => a.Branch))),
    });
  }

  const handleBranchChange = (event, newValue) => {
    var selectedFilters = filterData.filter((i) => newValue.some((j) => i.Branch === j)) ?? [];
    if (newValue.length === 0) {
      selectedFilters = filterData;
      setSelectedFilterValues({
        ...selectedFilterValues,
        "Branch": []
      });
    }
    else {
      setSelectedFilterValues({
        ...selectedFilterValues,
        "Branch": newValue,
      });
    }
    //Update filterDropdowns as RFA status summary depends on it
    setFilterDropdowns({
      ...filterDropdowns
    });
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <IDatePicker label="RFA Created - From Date" value={dateFilter.triggerFromDate}
                maxDate={dateFilter.triggerToDate}
                onChange={(date) => handleDateChange(date, "triggerFromDate")}
                error={triggerFromDateError}
              >
              </IDatePicker>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <IDatePicker label="RFA Created - To Date" value={dateFilter.triggerToDate}
                maxDate={new Date()}
                onChange={(date) => handleDateChange(date, "triggerToDate")}
                error={triggerToDateError}
              >
              </IDatePicker>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={4}>
            <Toolbar className={classes.misReportFilterToolbar}>
              <Typography>RFA Status Report</Typography>
            </Toolbar>
            <Box p={3}>
              <Grid container spacing={2} justifyContent="center">

                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    disablePortal
                    multiple
                    filterSelectedOptions
                    disableCloseOnSelect
                    clearOnEscape
                    id="businessUnit"
                    options={filterDropdowns.BusinessUnit}
                    value={selectedFilterValues.BusinessUnit}
                    sx={{ width: 300 }}
                    renderInput={(params) =>
                      <TextField {...params} label="Business Unit" variant="outlined" />}
                    onChange={(event, newValue) => handleBusinessUnitChange(event, newValue)}>
                  </Autocomplete>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    disablePortal
                    multiple
                    filterSelectedOptions
                    disableCloseOnSelect
                    clearOnEscape
                    id="circle"
                    options={filterDropdowns.Circle}
                    value={selectedFilterValues.Circle}
                    sx={{ width: 300 }}
                    renderInput={(params) =>
                      <TextField {...params} label="Circle" variant="outlined" />}
                    onChange={(event, newValue) => handleCircleChange(event, newValue)}>
                  </Autocomplete>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    disablePortal
                    multiple
                    filterSelectedOptions
                    disableCloseOnSelect
                    clearOnEscape
                    id="network"
                    options={filterDropdowns.Network}
                    value={selectedFilterValues.Network}
                    sx={{ width: 300 }}
                    renderInput={(params) =>
                      <TextField {...params} label="Network" variant="outlined" />}
                    onChange={(event, newValue) => handleNetworkChange(event, newValue)}>
                  </Autocomplete>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    disablePortal
                    multiple
                    filterSelectedOptions
                    disableCloseOnSelect
                    clearOnEscape
                    id="module"
                    options={filterDropdowns.Module}
                    value={selectedFilterValues.Module}
                    sx={{ width: 300 }}
                    renderInput={(params) =>
                      <TextField {...params} label="Module" variant="outlined" />}
                    onChange={(event, newValue) => handleModuleChange(event, newValue)}>
                  </Autocomplete>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    disablePortal
                    multiple
                    filterSelectedOptions
                    disableCloseOnSelect
                    clearOnEscape
                    id="region"
                    options={filterDropdowns.Region}
                    value={selectedFilterValues.Region}
                    sx={{ width: 300 }}
                    renderInput={(params) =>
                      <TextField {...params} label="Region" variant="outlined" />}
                    onChange={(event, newValue) => handleRegionChange(event, newValue)}>
                  </Autocomplete>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    disablePortal
                    multiple
                    filterSelectedOptions
                    disableCloseOnSelect
                    clearOnEscape
                    id="branch"
                    options={filterDropdowns.Branch}
                    value={selectedFilterValues.Branch}
                    sx={{ width: 300 }}
                    renderInput={(params) =>
                      <TextField {...params} label="Branch Name" variant="outlined" />}
                    onChange={(event, newValue) => handleBranchChange(event, newValue)}>
                  </Autocomplete>
                </Grid>

                <Grid container spacing={2} justifyContent="center">
                  <Grid item>
                    <Button variant="outlined" size="medium"
                      onClick={resetFilters} startIcon={<ClearAll />}>Reset Filters</Button>
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" size="medium"
                      disabled={(triggerToDateError || triggerFromDateError)}
                      onClick={submitForm} startIcon={<GetAppRoundedIcon />}>Download XLSX Report</Button>
                  </Grid>
                </Grid>
                {(triggerToDateError || triggerFromDateError) && (
                  <Box className={classes.errorText}>
                    <FormHelperText>Please correct all invalid selections to proceed with download</FormHelperText>
                  </Box>
                )}
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <MUIDataTable
            columns={rfaStatusSummaryColumns}
            title={<div className={classes.tableHeading}>{`RFA Status Summary`}</div>}
            data={rfaStatusSummary}
            options={options}
            className={classes.table}>
          </MUIDataTable>
        </Grid>
      </Grid>
    </Box >
  );
};

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(RFAStatusReport)));