import {
  SET_MIS_REPORTS,
  SET_IS_DATA_LOADED,
  SET_PIVOTTABLE_FILTERS,
} from "../actions/misReportsActions";

const initialState = {
  misReports: [],
  selectedFilters: [],
  filterDetails: "",
  selectedFilterItems: {},
  selectedFilterValues: {},
  isDataLoaded: false,
  pivottableFilters: {}
};

const misReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MIS_REPORTS:
      return {
        ...state,
        misReports: [...action.payload],
      };
    case SET_IS_DATA_LOADED:
      return {
        ...state,
        isDataLoaded: action.payload,
      };
    case SET_PIVOTTABLE_FILTERS:
      return {
        ...state,
        pivottableFilters: action.payload,
      };
    default:
      return state;
  }
};

export default misReportsReducer;