import { combineReducers } from "redux";
import dashboardReducer from "./dashboardReducer";
import authReducer from "./authReducer";
import accountReducer from "./accountReducer";
import commonReducer from "./commonReducer";
import analyticsReducer from "./analyticsReducer";
import triggerReducer from "./triggerReducer";
import bookmarkReducer from "./bookmarkReducer";
import reviewReducer from "./reviewReducer";
import adminReducer from "./adminReducer";
import riskReducer from "./riskReducer";
import settingReducer from "./settingsReducer";
import misReportsReducer from "./misReportsReducer";
import rfaReducer from "./rfaReducer";
import { CLEAR_STORE } from "../actions/commonActions";

const pcaReducer = combineReducers({
  authReducer,
  dashboardReducer,
  accountReducer,
  commonReducer,
  analyticsReducer,
  triggerReducer,
  bookmarkReducer,
  reviewReducer,
  adminReducer,
  riskReducer,
  settingReducer,
  misReportsReducer,
  rfaReducer
});

const rootReducer = (state, action) => {
  if (action.type === CLEAR_STORE) {
    state = undefined; // Reset the entire state to undefined
  }

  return pcaReducer(state, action);
};

export default rootReducer;
