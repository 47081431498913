import React, { useEffect } from "react";
import { withStyles, ThemeProvider } from "@material-ui/core";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import theme from "./themes/theme";
import { connect } from "react-redux";
import { setCanAddReviews, setRoles, setUser } from "./actions/authActions";
import { setSnackBar } from "./actions/commonActions";
import AuthUtils from "./utils/authUtils";
import "./App.css";

import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import Account from "./pages/Account";
import ReviewHistory from "./pages/ReviewHistory";
import Configuration from "./pages/Configuration";
import Settings from "./pages/Settings";
import Review from "./pages/Review";
import RFA from "./pages/RFA";
import Analytics from "./pages/Analytics";
import Admin from "./pages/Admin";

import PrivateRoute from "./components/common/PrivateRoute";
import NavBar from "./components/common/NavBar";
import ISnackBar from "./components/common/ISnackBar";
import ILoader from "./components/common/ILoader";
import { setFilterDetails, setFilterHierarchy } from "./actions/dashboardActions";
import DataUtils from "./utils/dataUtils";
import { setSuggestiveActions } from "./actions/reviewActions";
import Escalations from "./pages/Escalations";
import MISReports from "./pages/MISReports";

let styles = (theme) => ({});

const App = (props) => {
  const initialize = () => {
    if (AuthUtils.IsAuthenticated()) {
      const user = AuthUtils.GetUser();
      props.setUser(user?.User);
      props.setRoles(user?.UserRoles);
      const canAddReviews = user?.UserRoles?.some((i) => i?.OrganizationRoles?.CanAddReviews);
      props.setCanAddReviews(canAddReviews);

      if (!props.filterDetails) {
        if (!props.filterHierarchy) {
          DataUtils.FetchFilterOptions().then((options) => {
            props.setFilterHierarchy(options);

            DataUtils.FetchFilterDetails().then((data) => {
              const filterDetails = DataUtils.FormatFilterDetails(data, options);
              props.setFilterDetails(filterDetails);
            });
          });
        }
      }

      if (!(props.suggestiveActions?.length > 0)) {
        DataUtils.FetchSuggestiveActions().then((data) => {
          props.setSuggestiveActions(data);
        });
      }
    }
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.setSnackBar("");
  };

  useEffect(initialize, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          {props.showLoader && <ILoader />}
          {props.snackBar && (
            <ISnackBar open={props.setSnackBar ? true : false} onClose={handleSnackBarClose} message={props.snackBar} duration={3000}></ISnackBar>
          )}
          <NavBar />
          <main className="container-fluid">
            <Switch>
              <PrivateRoute path="/dashboard" component={Dashboard} isAuthenticated={props.user} />
              <Route path="/home" component={Home} />
              <Route path="/account/:id" component={Account} isAuthenticated={props.user} />
              <Route path="/analytics" component={Analytics} isAuthenticated={props.user} />
              <Route path="/configuration" component={Configuration} isAuthenticated={props.user} />
              <Route path="/settings" component={Settings} isAuthenticated={props.user} />
              <Route path="/review/history" component={ReviewHistory} isAuthenticated={props.user} />
              <Route path="/review" component={Review} isAuthenticated={props.user} />
              <Route path="/rfa" component={RFA} isAuthenticated={props.user} />
              <Route path="/admin" component={Admin} isAuthenticated={props.user} />
              <Route path="/escalations" component={Escalations} isAuthenticated={props.user} />
              <Route path="/misreports" component={MISReports} isAuthenticated={props.user} />
              <Route path="/login" component={Login} />
              <PrivateRoute path="/logout" component={Logout} isAuthenticated={props.user} />
              <Redirect from="/" exact to="/home" />
            </Switch>
          </main>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  showLoader: state.commonReducer.showLoader,
  snackBar: state.commonReducer.snackBar,
  filterDetails: state.dashboardReducer.filterDetails,
  suggestiveActions: state.reviewReducer.suggestiveActions,
  filterHierarchy: state.dashboardReducer.filterHierarchy,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (payload) => dispatch(setUser(payload)),
    setSnackBar: (payload) => dispatch(setSnackBar(payload)),
    setFilterDetails: (payload) => dispatch(setFilterDetails(payload)),
    setSuggestiveActions: (payload) => dispatch(setSuggestiveActions(payload)),
    setFilterHierarchy: (payload) => dispatch(setFilterHierarchy(payload)),
    setRoles: (payload) => dispatch(setRoles(payload)),
    setCanAddReviews: (payload) => dispatch(setCanAddReviews(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App));
