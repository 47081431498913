import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import FormatUtils from "../../utils/formatUtils";
import RFAUtils from "../../utils/rfaUtils";
import RFAForm from "./RFAForm"
import { setSnackBar } from "../../actions/commonActions";
import { connect } from "react-redux";
import { setToFormOpen, setToFormClose, setFormDetails, setToFormReview, setClosedRFA, setBorrowerName } from "../../actions/rfaActions";

let styles = (theme) => ({
  root: {},
  tableHeading: {
    fontSize: theme.spacing(3),
    float: `left`,
  },
  borrowerName: {
    fontWeight: theme.spacing(80),
    textDecorationColor: `gray`,
    cursor: `pointer`,
  },
  requireAttention: {
    textAlign: "center",
    cursor: "pointer",
  },
});

const PendingRFA = (props) => {
  const { classes } = props;
  const [pendingRFAData, setPendingRFAData] = useState([]);

  const onload = () => {
    RFAUtils.FetchPendingRFA().then((data) => {
      setPendingRFAData(data);
      props.setClosedRFA(false);
      props.setToFormOpen(false);
    });
  };

  useEffect(onload, []);

  const columns = [
    {
      name: "BorrowerId",
      label: "BorrowerId",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "AccountNumber",
      label: "AccountNumber",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "BorrowerName",
      label: "Borrower Name",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return <span className={classes.borrowerName}>{value}</span>;
        },
      },
    },
    {
      name: "Branch",
      label: "Branch Name",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "BranchCode",
      label: "Branch Code",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "CIF",
      label: "CIF/CIN",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "AmountDisbursed",
      label: "Exposure",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "IRACStatus",
      label: "IRAC Status",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "LoanType",
      label: "LoanType",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "FormIdentifier",
      label: "Form Identifier",
      options: {
        filter: false,
        sort: false,
        display: true,
        viewColumns: false,
      },
    },
    {
      name: "TriggerDate",
      label: "Trigger Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value !== null ? FormatUtils.FormatToPrettyDate(value) : null,
      },
    },
    {
      name: "CreatedDate",
      label: "Created Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => FormatUtils.FormatToPrettyDate(value),
      },
    },
    {
      name: "CreatedBy",
      label: "Created By",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
  ];

  const options = {
    selectableRows: "none",
    responsive: "vertical",
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 15, 100, 1000, 10000],
    downloadOptions: {
      filename: FormatUtils.GetCSVFileName("Pending-RFA"),
      separator: ",",
    },
    onRowClick: (rowData, rowState) => {
      setBorrowerName(rowData[2].props.children);
      props.setFormDetails({
        "FormIdentifier": rowData[9],
        "FormId": 1,
        "BorrowerId": rowData[0],
      });
      props.setToFormOpen(true);
    },
  };

  return (
    <React.Fragment>
      <MUIDataTable
        columns={columns}
        title={<span className={classes.tableHeading}>{`Pending RFA`}</span>}
        data={pendingRFAData}
        options={options}
        className={classes.root}
      >
      </MUIDataTable>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    toFormOpen: state.rfaReducer.toFormOpen,
    toFormClose: state.rfaReducer.toFormClose,
    formDetails: state.rfaReducer.formDetails,
    toFormReview: state.rfaReducer.toFormReview,
    closedRFA: state.rfaReducer.closedRFA,
    borrowerName: state.rfaReducer.borrowerName
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackBar: (payload) => dispatch(setSnackBar(payload)),
    setToFormOpen: (payload) => dispatch(setToFormOpen(payload)),
    setToFormClose: (payload) => dispatch(setToFormClose(payload)),
    setFormDetails: (payload) => dispatch(setFormDetails(payload)),
    setToFormReview: (payload) => dispatch(setToFormReview(payload)),
    setClosedRFA: (payload) => dispatch(setClosedRFA(payload)),
    setBorrowerName: (payload) => dispatch(setBorrowerName(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(PendingRFA)));
