import MUIDataTable from "mui-datatables";
import React from "react";
import FormatUtils from "../../utils/formatUtils";
import { connect } from "react-redux";
import { IconButton, Tooltip, withStyles } from "@material-ui/core";
import { setBookmark, setUserBookmarks } from "../../actions/bookmarkActions";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { clearSelectedFilters, setSelectedFilterItems, setSelectedFilters, setSelectedFilterValues } from "../../actions/dashboardActions";
import BookmarkUtils from "../../utils/bookmarkUtils";
import { setSnackBar } from "../../actions/commonActions";

let styles = (theme) => ({
  bookmark: {
    float: "left",
    margin: `0px ${theme.spacing(2)}px 0px ${theme.spacing(2)}px`,
  },
  bookmarkName: {
    cursor: "pointer",
  },
  bookmarkTable: {
    ".MuiTableRow-root": {
      cursor: "pointer",
    },
  },
  actionIcon: {
    padding: 0,
  },
});

const BookmarksTable = (props) => {
  const { classes } = props;

  const columns = [
    {
      name: "BookmarkId",
      label: "Bookmark Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "BookmarkName",
      label: "Bookmark Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Tooltip title="Select bookmark" aria-label="bookmark-select">
              <span className={classes.bookmarkName}>{value}</span>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "ModifiedTime",
      label: "Saved On",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => FormatUtils.FormatToPrettyDate(value),
      },
    },
    {
      name: "Filters",
      label: "Filters",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "FilterItems",
      label: "FilterItems",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "FilterValue",
      label: "FilterValue",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "Actions",
      label: false,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title="Remove bookmark" aria-label="bookmark-remove">
              <IconButton
                className={classes.actionIcon}
                onClick={(e) => {
                  const bookmarkId = tableMeta?.rowData[0];
                  removeBookmark(bookmarkId);
                }}
              >
                <RemoveCircleIcon color="secondary" />
              </IconButton>
            </Tooltip>
          );
        },
      },
    },
  ];

  const removeBookmark = (bookmarkId) => {
    BookmarkUtils.RemoveBookmark(bookmarkId).then((data) => {
      BookmarkUtils.GetUserBookmarks(props.userId).then((data) => {
        props.setUserBookmarks(data);
        props.setBookmark("");
        props.setSnackBar("Removed bookmark");
        props.onClose();
      });
    });
  };

  const options = {
    selectableRows: "none",
    filterType: "dropdown",
    responsive: false,
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    onRowClick: (rowData) => selectBookmark(rowData),
  };

  const selectBookmark = (rowData) => {
    const filters = JSON.parse(rowData[3]);
    const filterItems = JSON.parse(rowData[4]);
    const filterValues = JSON.parse(rowData[5]);

    props.clearSelectedFilters();
    props.setSelectedFilterValues({});

    props.setSelectedFilters(filters);
    props.setSelectedFilterItems(filterItems);
    props.setSelectedFilterValues(filterValues);
    props.setBookmark({ BookmarkId: rowData[0] });

    console.log("Props", props);
    props.onClose();
  };

  return <MUIDataTable columns={columns} data={props.userBookmarks} options={options} className={classes.bookmarkTable}></MUIDataTable>;
};

const mapStateToProps = (state) => {
  return {
    userBookmarks: state.bookmarkReducer.userBookmarks,
    userId: state.authReducer.user.UserId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBookmark: (payload) => dispatch(setBookmark(payload)),
    setUserBookmarks: (payload) => dispatch(setUserBookmarks(payload)),
    setSelectedFilters: (payload) => dispatch(setSelectedFilters(payload)),
    setSelectedFilterItems: (payload) => dispatch(setSelectedFilterItems(payload)),
    setSelectedFilterValues: (payload) => dispatch(setSelectedFilterValues(payload)),
    clearSelectedFilters: (payload) => dispatch(clearSelectedFilters(payload)),
    setSnackBar: (payload) => dispatch(setSnackBar(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BookmarksTable));
