import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import AccountReviewHistory from "./AccountReviewHistory";
import AlertClosureHistory from "./AlertClosureHistory";
import ITab from "./../common/ITab";
import RiskMovement from "./RiskMovement";
import OpenAlerts from "./OpenAlerts";
import Review from "./../../pages/Review";
import SuggestiveActions from "../review/SuggestiveActions";

let styles = (theme) => ({
  root: {},
});

const History = (props) => {
  const onLoad = () => {};

  useEffect(onLoad, []);

  const tabs = [
    {
      index: 0,
      label: "Account Review",
      content: <SuggestiveActions />,
    },
    {
      index: 1,
      label: "Account Review History",
      content: <AccountReviewHistory />,
    },
    {
      index: 2,
      label: "Closed Alerts",
      content: <AlertClosureHistory />,
    },
    {
      index: 3,
      label: "Open Alerts",
      content: <OpenAlerts />,
    },
    {
      index: 4,
      label: "Risk Movement",
      content: <RiskMovement />,
    },
  ];
  return <ITab tabs={tabs} />;
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(History));
