import HttpUtils from "./httpUtils";
import config from "./../config/config";
import { format } from "date-fns";

const fetchOverview = async (compareDate, currentDate, filter) => {
  const fromDate = format(compareDate, "yyyy-MM-dd");
  const toDate = format(currentDate, "yyyy-MM-dd");
  const data = await HttpUtils.Post(`${config.apiUrl}/dashboard/overview`, {
    CompareDate: fromDate,
    CurrentDate: toDate,
    Filter: filter,
  });
  return data?.data;
};

const fetchTriggers = async (triggerType, currentDate, filter) => {
  const toDate = format(currentDate, "yyyy-MM-dd");
  const data = await HttpUtils.Post(`${config.apiUrl}/dashboard/triggers`, {
    TriggerType: triggerType,
    CurrentDate: toDate,
    Filter: filter,
  });
  return data?.data;
};

const fetchTriggeredLoans = async (triggerType, currentDate, triggerId, color, filter) => {
  const toDate = format(currentDate, "yyyy-MM-dd");
  const data = await HttpUtils.Post(`${config.apiUrl}/dashboard/triggeredloans`, {
    TriggerType: triggerType,
    CurrentDate: toDate,
    TriggerId: triggerId,
    Color: color,
    Filter: filter,
  });
  return data?.data;
};

const fetchNPALoans = async (currentDate, filter, type) => {
  const toDate = format(currentDate, "yyyy-MM-dd");
  const data = await HttpUtils.Post(`${config.apiUrl}/dashboard/npaloans`, {
    CurrentDate: toDate,
    Filter: filter,
    TriggerType: type,
  });
  return data?.data;
};

const DashboardUtils = {
  FetchOverview: fetchOverview,
  FetchTriggers: fetchTriggers,
  FetchTriggeredLoans: fetchTriggeredLoans,
  FetchNPALoans: fetchNPALoans,
};

export default DashboardUtils;
