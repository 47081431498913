import React from "react";
import { Grid, Card, withStyles, Button, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import PlaylistAddSharpIcon from "@material-ui/icons/PlaylistAddSharp";
import ISelect from "../common/ISelect";
import { useState } from "react";
import { setSelectedFilters, setSelectedFilterItems, setSelectedFilterValues, clearSelectedFilters } from "../../actions/dashboardActions";
import HelperUtils from "../../utils/helperUtils";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import DataUtils from "../../utils/dataUtils";

let styles = (theme) => ({
  root: {},
  filterContainer: {
    minHeight: theme.spacing(8),
    padding: theme.spacing(1),
  },
  addButton: {
    float: `right`,
    margin: theme.spacing(1),
  },
  filterSelect: {},
  filterItem: {
    padding: theme.spacing(1),
  },
});

const Filters = (props) => {
  const { classes } = props;

  const [addFilter, setAddFilter] = useState(false);
  const handleAddFilter = () => {
    setAddFilter(true);
  };

  const handleAddFilterChange = (event, value) => {
    if (value) {
      props.setSelectedFilters(value);
    }
    setAddFilter(false);
  };

  const handleFilterItemChange = (name) => (event, value) => {
    const values = HelperUtils.ExtractProperty(value, "value");
    const selectedFilterItems = {
      ...props.selectedFilterItems,
      [name]: values,
    };
    props.setSelectedFilterItems(selectedFilterItems);

    const selectedFilterValues = {
      ...props.selectedFilterValues,
      [name]: value,
    };
    props.setSelectedFilterValues(selectedFilterValues);
  };

  const handlePaste = (name) => (event) => {
    let clipboardText = event.clipboardData.getData("Text");
    let arrayValues = HelperUtils.SplitString(clipboardText, "\n");
    arrayValues = arrayValues.map((i) => i.trim());

    //take into array only if values are valid and present in the array
    const validValues = HelperUtils.IntersectionWith(props.filterDetails[name] || [], arrayValues, (item, y) => item.value.trim() === y);

    const selectedFilterItems = {
      ...props.selectedFilterItems,
      [name]: HelperUtils.ExtractProperty(validValues, "value"),
    };
    props.setSelectedFilterItems(selectedFilterItems);

    const selectedFilterValues = {
      ...props.selectedFilterValues,
      [name]: validValues,
    };
    props.setSelectedFilterValues(selectedFilterValues);
    event.preventDefault();
  };

  const handleClearFilter = () => {
    props.clearSelectedFilters();
    props.setSelectedFilterValues({});
  };
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card className={classes.filterContainer} alignContent="center">
          <Grid container spacing={3}>
            <Grid item lg={9} md={9} sm={8} xs={12}>
              <Grid container spacing={1}>
                {props.selectedFilters?.map((item) => {
                  return (
                    <Grid item lg={3} md={3} sm={4} xs={12} key={item.value} className={classes.filterItem}>
                      <ISelect
                        multiple={true}
                        options={HelperUtils.FindDifferenceBy(props.filterDetails[item.apiKey], props.selectedFilterValues[item.apiKey], "value")}
                        label={item.name}
                        onChange={handleFilterItemChange(item.apiKey)}
                        onPaste={handlePaste(item.name)}
                        value={props.selectedFilterValues[item.name] && [...props.selectedFilterValues[item.name]]}
                      ></ISelect>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item lg={3} md={3} sm={4} xs={12}>
              {addFilter ? (
                <div className={classes.filterSelect}>
                  <ISelect
                    options={HelperUtils.FindDifferenceBy(props.filterHierarchy, props.selectedFilters, "value")}
                    label={"Select Filter"}
                    onChange={handleAddFilterChange}
                  ></ISelect>
                </div>
              ) : (
                <Tooltip title="Add Filter" aria-label="add-filter">
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.addButton}
                    startIcon={<PlaylistAddSharpIcon />}
                    onClick={handleAddFilter}
                  >
                    Add Filter
                  </Button>
                </Tooltip>
              )}
              {props.selectedFilters.length > 0 && (
                <Tooltip title="Reset Filters" aria-label="reset-filter">
                  <Button variant="contained" color="default" className={classes.addButton} startIcon={<ClearAllIcon />} onClick={handleClearFilter}>
                    Reset
                  </Button>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedFilters: state.dashboardReducer.selectedFilters,
    filterDetails: state.dashboardReducer.filterDetails,
    selectedFilterItems: state.dashboardReducer.selectedFilterItems,
    selectedFilterValues: state.dashboardReducer.selectedFilterValues,
    filterHierarchy: state.dashboardReducer.filterHierarchy,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedFilters: (payload) => dispatch(setSelectedFilters(payload)),
    setSelectedFilterItems: (payload) => dispatch(setSelectedFilterItems(payload)),
    setSelectedFilterValues: (payload) => dispatch(setSelectedFilterValues(payload)),
    clearSelectedFilters: (payload) => dispatch(clearSelectedFilters()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Filters));
