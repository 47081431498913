import React, { useEffect } from "react";
import { setUsers } from "../../actions/adminActions";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import IEditable from "../common/IEditable";
import AdminUtils from "../../utils/adminUtils";
import FormatUtils from "./../../utils/formatUtils";

let styles = (theme) => ({
  root: {},
});

const columns = [
  {
    title: "Username",
    field: "Username",
    editable: "never",
  },
  {
    title: "EmailAddress",
    field: "EmailAddress",
    editable: "never",
  },
  {
    title: "PhoneNumber",
    field: "PhoneNumber",
    editable: "never",
  },
  {
    title: "FirstName",
    field: "FirstName",
    editable: "never",
  },
  {
    title: "MiddleName",
    field: "MiddleName",
    editable: "never",
  },
  {
    title: "LastName",
    field: "LastName",
    editable: "never",
  },
];

const options = {
  downloadOptions: {
    filename: FormatUtils.GetCSVFileName("Users"),
    separator: ",",
  },
};

const Users = (props) => {
  const { users } = props;
  const onLoad = () => {
    AdminUtils.FetchUsers().then((data) => {
      props.setUsers(mapLoanAccounts(data));
    });
  };

  const mapLoanAccounts = (data) => {
    const mapped = data?.map((i) => ({
      UserId: i.UserId,
      Username: i.Username,
      FirstName: i.FirstName,
      MiddleName: i.MiddleName,
      LastName: i.LastName,
      PhoneNumber: i.PhoneNumber,
      EmailAddress: i.EmailAddress,
    }));
    return mapped;
  };

  useEffect(onLoad, []);

  const onUpdate = (newValue) => {
    console.log(newValue);
  };

  return (
    <React.Fragment>
      {users.length > 0 && <IEditable options={options} columns={columns} data={users} title={`User management`} onUpdate={onUpdate}
        onFilterChange={() => { }} //this is required for filters to work
      />}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.adminReducer.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (payload) => dispatch(setUsers(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Users));
