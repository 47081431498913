import HttpUtils from "./httpUtils";
import config from "./../config/config";
import { format } from "date-fns";
import DataUtils from "./dataUtils";

const fetchFullView = async (triggerType, currentDate, filter) => {
  const toDate = format(currentDate, "yyyy-MM-dd");
  const data = await HttpUtils.Post(`${config.apiUrl}/analytics`, {
    TriggerType: triggerType,
    CurrentDate: toDate,
    Filter: filter,
  });
  return data?.data;
};

const getLoansByDimension = (dimensionId, value, data, color, filterHierarchy) => {
  const filterItem = filterHierarchy?.find((item) => item.value === dimensionId);

  if (value) {
    return data.filter((item) => item[filterItem?.apiKey] === value && item.Color?.toLowerCase() === color?.toLowerCase());
  }

  return data.filter((item) => item.Color?.toLowerCase() === color?.toLowerCase());
};

const AnalyticsUtils = {
  FetchFullView: fetchFullView,
  GetLoansByDimension: getLoansByDimension,
};

export default AnalyticsUtils;
