import { setLoader } from "../actions/commonActions";
import { store } from "../store/store";
const { default: Axios } = require("axios");

const get = (url, config) => {
  return Axios.get(url);
};

const post = (url, data, config) => {
  return Axios.post(url, data, config);
};

const put = (url, data, config) => {
  return Axios.put(url, data, config);
};

let { dispatch } = store;
// global interceptors
let pendingRequests = 0;
Axios.interceptors.request.use(
  (config) => {
    config.headers["useremail"] = JSON.parse(localStorage.getItem("user"))?.User?.EmailAddress;
    config.headers["userid"] = JSON.parse(localStorage.getItem("user"))?.User?.UserId;
    config.headers["organizationroleid"] = JSON.parse(localStorage.getItem("user"))?.UserRoles?.[0]?.OrganizationRoleId;
    pendingRequests++;
    dispatch(setLoader(true));
    return config;
  },
  (error) => Promise.reject(error)
);

Axios.interceptors.response.use(
  (response) => {
    if (--pendingRequests === 0) {
      dispatch(setLoader(false));
    }
    return response;
  },
  (error) => {
    if (--pendingRequests === 0) {
      dispatch(setLoader(false));
    }
    return Promise.reject(error);
  }
);

const HttpUtils = {
  Get: get,
  Post: post,
  Put: put,
};

export default HttpUtils;
